import * as React from "react";
import $ from 'jquery';
import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
 import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Tooltip from "@mui/material/Tooltip";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { fallbackRender } from "../../../API/commonFunctions";
import { motion, 
} from "framer-motion";
import { useEffect } from "react";
import {baseApiCall, 
 } from "../../../API/apiCalls";


/*******************  FOR MODAL   ****************************/
import CommonVariables from "../../../variables";
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import APIConnector from "../../../apiconnector";
import Notifications from "../../../Notifications/Messages";
import CommonLoading from "../../../Layout/CommonLoading";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { IconButton, Zoom } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import CrudModal from "../Modals/crudModal";
import { handleRowsOnDelete } from "../Modals/modalFunctions";
import { red, teal } from "@mui/material/colors";

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});
// const roles = ["Market", "Finance", "Development"];


export default function Files() {

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [modalInOpen, setModalInOpen] = useState(false)
  const [inDelete , setInDelete] = useState(false)
  const [currentRowId, setCurrentRowId] = useState()
  const [isFile, setIsFile] = useState(true)
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadFiles();
  }, []);

  const loadFiles = async () => {
    setLoading(true);
    const result = await baseApiCall(CommonVariables.urls.getAllFilesUrl,{tenantId:localStorage.getItem("tenantId")});
    if (!result?.isFailure && result?.isFailure === false && result.data) {
      setRows(result.data);
      setLoading(false);
    }
    handleClose();
    setLoading(false);
  };

  /********************** FRAMER ANIMATION, APPLIES ON CHANGING ROUTE TO THIS COMPONENT **********************/

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  }); 

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  
  const CustomCheckBox = ({ value }) => (
    <Tooltip title={value?"Yes":"No"}>
      <IconButton style={{ color: value ? 'green' : 'red' }}>
        {value ? <CheckOutlinedIcon /> : <CloseOutlinedIcon />}
      </IconButton>
    </Tooltip>
  );

  const columns = [
    {
      field: "isActive",
      type: "boolean",
      headerName: "Is Active",
      width: 150,
      renderCell: (params) => <CustomCheckBox value={params.row.isActive} />,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Manage",
      width: 200,
      cellClassName: "actions",
      getActions: ({ row}) => { 
          return [
           <Tooltip title="Delete" arrow  TransitionComponent={Zoom} placement="top" className='text-secondary'>
          
          <GridActionsCellItem
          icon={<DeleteIcon sx={{fill:red[800]}} />}
          label="Delete"
          className="opacity-75"
          onClick={handleDeleteClick(row)}
          sx={{backgroundColor:teal[50]}}
          />
           </Tooltip>
        ];
      },
    },
    { field: "name",
     headerName: "Files",
      width: 500,
       editable: false 
    },
  ]; 
    
  /********************** FUNCTION EXECUTABLE ON DELETE MODAL OPEN **********************/
  const handleDeleteClick = (row) => async() => {
    setCurrentRowId(row.id)
     setModalInOpen(true);
     setInDelete(true)
  };

  const handleDeleteFileFromTenant = async(data) =>{
    setLoading(true)
    const result = await baseApiCall(CommonVariables.urls.deleteFileUrl,{id:currentRowId});
    if (!result?.isFailure && result?.isFailure === false && result.message === "document deleted successfully") {
      setRows(handleRowsOnDelete(rows,[currentRowId]));
      setLoading(false);
    }else{
      Notifications.ShowErrorMessage(result.message || "Problem Deleting Document")
    }
    setModalInOpen(false)
    // handleClose();
    setLoading(false);
  }

/********************** MANAGING USER CRUDS **********************/


  /********************** FUNCTION EXECUTABLE TO UPLOAD FILE **********************/
  const handleFileUpload = async ()=>{
    setLoading(true);
    const filesSelected = $("#input-file");
    const filesToUpload = new FormData();
    
    for(let file of filesSelected[0].files){
      filesToUpload.append("files", file);
    }
    const result =  await APIConnector.FileUpload(CommonVariables.urls.fileUploadUrl,{files:filesToUpload});
    if(!result?.isFailure && result?.isFailure === false){
      await loadFiles();
      Notifications.ShowSuccessMessage(result.message || "File uploaded");
      return;
    }
    setLoading(false);
    Notifications.ShowWarningMessage(result.message || "Error uploading File")
  }

/********************** FUNCTION EXECUTABLE ON MODAL CLOSE **********************/
  const handleClose = () => {
    setModalInOpen(false);
    setInDelete(false)
  }

/********************** FUNCTION EXECUTABLE WHILE ADDING NEW RECORD **********************/

  return (
    <ErrorBoundary
      fallbackRender={fallbackRender}
      onReset={() => {
      }}
    >
        <motion.div
          sx={{ transition: "none !important" }}
          animate="final"
          className="for-transition p-4 grids text-secondary overflow-auto h-100"
        >
            <div
              className="for-transition w-100 card border h-100 border-1 border-color-css rounded-4 shadow  overflow-hidden"
            >
              { loading? <CommonLoading /> :
                <>
            <div className="fs-3 py-3 px-3 mb-2 fw-semibold main-text text-center rounded-bottom-0 card border border-bottom bg-body-tertiary">
                    Manage Files
                  </div>
                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />} 
                    sx={{width:180,
                      margin:2,
                      paddingY:1
                    }}
                    className="button-color p-2 m-3 ms-4 mb-0 "
                  >
                    Upload file
                    <VisuallyHiddenInput id="input-file" type="file" accept="application/pdf" multiple onChange={handleFileUpload} />
                  </Button>
                  <Box
                    className=""
                    sx={{
                      height: "auto",
                      margin: "",
                      fontWeight: "bold",
                      padding: "26px",
                      paddingTop: "16px",
                      transition: "none !important",
                      "& .actions": {
                        color: "text.secondary",
                      },
                      "& .textPrimary": {
                        color: "text.primary",
                      },
                    }}
                  >
                    <DataGrid
                      className="text-body-secondary rounded-4 overflow-hidden"
                      rows={rows}
                      style={{ transition: "none !important" }}
                      sx={{ transition: "none !important",maxHeight:"68vh",minHeight:"55vh" }}
                      columns={columns}
                      editMode="row"
                      rowModesModel={rowModesModel}
                      onRowModesModelChange={handleRowModesModelChange}
                      onRowEditStop={handleRowEditStop}
                      slotProps={{
                        toolbar: { setRows, setRowModesModel },
                      }}
                      disableRowSelectionOnClick
                      // slots={{ footer: Footer }}
                    />           
                  </Box>
                  {modalInOpen && (
                    <ThemeProvider theme={$(".lower-div").attr("data-bs-theme") === "light"? lightTheme : darkTheme}>
                      <CssBaseline />
                      <CrudModal
                        modalInOpen={modalInOpen}
                        inDelete={inDelete}
                        isFile = {isFile}
                        handleDeleteFileFromTenant={handleDeleteFileFromTenant}
                        handleClose={handleClose}
                      />    
                    </ThemeProvider>
                  )}
                </>
              }
            </div>
        </motion.div>
    </ErrorBoundary>
  );
}

