import * as React from "react";
import $, { data } from 'jquery';
import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Zoom from "@mui/material/Zoom";
import Tooltip from "@mui/material/Tooltip";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Notifications from "../../../Notifications/Messages";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { IconButton } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { baseApiCall } from "../../../API/apiCalls";

/*******************  FOR MODAL   ****************************/
import CommonVariables from "../../../variables";
import CrudModal from "../Modals/crudModal";
import CommonLoading from "../../../Layout/CommonLoading";
import { blueGrey, grey, red, teal } from "@mui/material/colors";
import { useCallback } from "react";

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

export default function Users(props) {
  const [userGroups, setUserGroups] = useState([]);
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [currentRowId, setCurrentRowId] = useState();
  const [loadCallbacks, setLoadCallbacks] = useState(false)
  
  const [modalInOpen, setModalInOpen] = useState(false)
  const [inEdit, setInEdit] = useState(false)
  const [inAdd, setInAdd] = useState(false)
  const [inDelete, setInDelete] = useState(false)
  const [isUser, setIsUser] = useState(true)
  const [isOrganisation, setIsOrganisation] = useState(false)
  const [isUserGroup, setIsUserGroup] = useState(false)
  
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [email ,setEmail] = useState()
  const [isActive, setIsActive] = useState()
  const [password,setPassword] = useState();
  const [selectedUserGroups, setSelectedUserGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sessions, setSessions] = useState();
  
  function ShowAssignedGroups(props){
    const [data , setData] = useState([])
    const [loadData, setLoadData] = useState(false)
    useEffect(() => {    
      setData(props.userGroups.filter((record)=>{setLoadData(true); return props.row.userGroupIds.some((data) => data === record.id)}))  
    }, [props.loadCallbacks,props.userGroups,props.row]);
    return (
      <>
      {
        loadData?
     <label>
      {
        data.length === 0 ?"No group assigned":data.map((item,index)=> {
          if(index!==data.length -1){
          return (<span>{item.groupName + ", "}</span>)
        }
        else{
          return (<span>{item.groupName}</span>)
        }
      }
    ) 
    }
     </label> 
     :"Loading..."
  }
  </>
    )  
  }

  useEffect(() => {
    loadUsers();
    setTimeout(() => {
      loadUserGroups()
    },3000);
  }, []);
  

  const loadUsers = async () => {
    setLoading(true);
    const result = await baseApiCall(CommonVariables.urls.getAllUsersUrl,{tenantId:localStorage.getItem("tenantId")});
    if (!result?.isFailure && result?.isFailure === false && result.data) {
      setRows(result.data);
      setLoading(false);
    }
    else{
      Notifications.ShowErrorMessage("Problem getting users, Try again")
    }
    handleClose();
    setLoading(false);
  };

  const loadUserGroups = async () => {
    const result = await baseApiCall(CommonVariables.urls.getAllUserGroupsUrl,{});
    if (!result?.isFailure && result?.isFailure === false && result.data) {
      setUserGroups(result.data)
      setLoadCallbacks(true)
    }
  };

  /********************** FRAMER ANIMATION, APPLIES ON CHANGING ROUTE TO THIS COMPONENT **********************/

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const CustomCheckBox = ({ value }) => (
    <Tooltip title={value?"Yes":"No"}>
      <IconButton style={{ color: value ? 'green' : 'red' }}>
        {value ? <CheckOutlinedIcon /> : <CloseOutlinedIcon />}
      </IconButton>
    </Tooltip>
  );
  
  const columns = [
    {
      field: "isActive",
      type: "boolean",
      style:{color:"#999"},
      headerName: "Is Active",
      renderCell: (params) => <CustomCheckBox value={params.row.isActive} />,
      width: 80,

      // getActions: (field) => {
      //   return [
      //     <CheckBox  className="textPrimary text-body-secondary fw-bold"
      //     />
      //   ];
      // },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Manage",
      width: 300,
      cellClassName: "actions",
      getActions: ({ row}) => {    
        return [
          <>
            <Tooltip title="Edit" arrow  TransitionComponent={Zoom} placement="top" className='ms-5 me-3 text-secondary'>
              <GridActionsCellItem
              icon={<EditIcon sx={{fill:blueGrey[600]}} />}
              label="Edit"
              className="ms-5 me-3  opacity-75"
              onClick={handleEditClick(row)}
              sx={{backgroundColor:teal[50]}}
              />
            </Tooltip>
            <Tooltip title="Delete" arrow  TransitionComponent={Zoom} placement="top" className='mx-5 text-secondary'>       
              <GridActionsCellItem
              icon={<DeleteIcon sx={{fill:red[800]}} />}
              label="Delete"
              className="mx-5  opacity-75"
              onClick={handleDeleteClick(row)}
              sx={{backgroundColor:teal[50]}}
              />
            </Tooltip>
          </>
        ];
      },
    },
    { field: "firstName",
     headerName: "First Name",
      width: 200,
       editable: false 
    },
    { field: "lastName",
    headerName: "Last Name",
    width: 200,
       editable: false 
    },
    { renderCell: ({row})=> <ShowAssignedGroups loadCallbacks={loadCallbacks} row={row} userGroups={userGroups} />,
    headerName: "Assiged User Groups",
    width: 200,
       editable: false 
    },
    // {
    //   field: "createdDate",
    //   headerName: "Created On",
    //   type: "date",
    //   width: 200,
    //   valueGetter: ({ value }) => value && new Date(value),
    //   align: "left",
    //   headerAlign: "left",
    //   editable: false,
    // },
  ];
  
  /********************** FUNCTION EXECUTABLE ON EDIT MODAL OPEN **********************/
  const handleEditClick = (row) => () => {
    // console.log(row);
    setCurrentRowId(row.id);
    setFirstName(row.firstName);
    setLastName(row.lastName);
    setEmail(row.userEmail);
    setPassword(row.password);
    setIsActive(row.isActive);
    setSelectedUserGroups(row.userGroupIds);
    setSessions(row.userSessions);
    setModalInOpen(true);
    setInEdit(true);
  };
  
  /********************** FUNCTION EXECUTABLE ON DELETE MODAL OPEN **********************/
  const handleDeleteClick = (row) => async() => {
    setCurrentRowId(row.id);
    setModalInOpen(true);
    setInDelete(true);
  };

/********************** MANAGING USER CRUDS **********************/
  const addUser = async (data)=>{
    setLoading(true);
    const result = await baseApiCall(CommonVariables.urls.addUsersUrl,{...data,tenantId:localStorage.getItem("tenantId")})
    if(!result?.isFailure && result?.isFailure === false){
      setLoading(false);
      handleClose();
      Notifications.ShowSuccessMessage(result.message);
      loadUsers();

    }
    setLoading(false);
  }

  const updateUser = async (data)=>{
    setLoading(true);
    const result = await baseApiCall(CommonVariables.urls.updateUserUrl,data)
    if(!result?.isFailure && result?.isFailure === false){
      setLoading(false);
      handleClose();
      Notifications.ShowSuccessMessage(result.message);
      loadUsers();
    }
    setLoading(false);
  }

  const deleteUser =async (data)=>{
    setLoading(true);
    const result = await baseApiCall(CommonVariables.urls.deleteUserUrl,[{id:data.id}]);
    if(!result?.isFailure && result?.isFailure === false){
      setLoading(false);
      handleClose();
      Notifications.ShowSuccessMessage(result.message);
      loadUsers();
      // setRows(rows.filter((row) => row.id !== data.id));
    }
    setLoading(false);
  }

/********************** FUNCTION EXECUTABLE ON MODAL CLOSE **********************/
  const handleClose = () => {
    setModalInOpen(false);
    setFirstName("")
    setLastName("")
    setEmail("")
    setIsActive(false)
    setInAdd(false)
    setInEdit(false)
    setInDelete(false)
    setSelectedUserGroups([]);
  }

/********************** FUNCTION EXECUTABLE WHILE ADDING NEW RECORD **********************/
  const handleInAdd = () => {
    setInAdd(true);
    setModalInOpen(true);
  }

  return (
    <motion.div
      sx={{ transition: "none !important" }}
      animate="final"
      className="for-transition p-4 grids text-secondary overflow-auto h-100"
    >
      <div
        className="for-transition w-100 card border h-100 border-1 border-color-css rounded-4 shadow  overflow-hidden"
        style={{}}
      >
        { loading ? <CommonLoading /> :
          <>
            <div className="fs-3 py-3 px-3 mb-2 fw-semibold main-text text-center rounded-bottom-0 card border border-bottom bg-body-tertiary">
              Manage Users
            </div>
            <Button
              // color="primary"
              variant="contained"
              style={{minWidth:"9rem",maxWidth:"12rem"}}
              className="p-2 m-3 ms-4 mb-0 button-color"
              startIcon={<AddIcon />}
              onClick={handleInAdd}     
              >
              Add record
            </Button>
            <Box
              className=""
              sx={{
                minHeight:520,
                height: "70vh",
                margin: "",
                fontWeight: "bold",
                padding: "26px",
                paddingTop: "16px",
                transition: "none !important",
                "& .actions": {
                  color: "text.secondary",
                },
                "& .textPrimary": {
                  color: "text.primary",
                },
              }}
            >
              <DataGrid
                className="text-body-secondary rounded-4 overflow-hidden"
                rows={rows}
                style={{ transition: "none !important" }}
                sx={{ transition: "none !important",maxHeight:"68vh",minHeight:"55vh" }}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                slotProps={{
                  toolbar: { setRows, setRowModesModel },
                }}
                disableRowSelectionOnClick
                // slots={{ footer: Footer }}
              />        
            </Box>
            { loading ? <CommonLoading /> :
              modalInOpen && (
                <ThemeProvider theme={$(".lower-div").attr("data-bs-theme") === "light"? lightTheme : darkTheme}>
                  <CssBaseline />
                  <CrudModal
                    modalInOpen={modalInOpen}
                    currentRowId={currentRowId}
                    inEdit={inEdit}
                    isUser={isUser}
                    isOrganisation={isOrganisation}
                    isUserGroup={isUserGroup}
                    inAdd={inAdd}
                    inDelete={inDelete}
                    handleClose={handleClose}
                    addUser={addUser}
                    updateUser={updateUser}
                    deleteUser = {deleteUser}
                    firstName={firstName}
                    lastName={lastName}
                    password={password}
                    setFirstName={setFirstName}
                    setLastName={setLastName}
                    setPassword={setPassword}
                    isActive={isActive}
                    setIsActive={setIsActive}
                    selectedUserGroups={selectedUserGroups}
                    setSelectedUserGroups={setSelectedUserGroups}
                    email={email}
                    setEmail={setEmail}
                    chatSessions={sessions}
                    userGroups={userGroups}
                  />    
                </ThemeProvider>
            )}
          </>
        }
      </div>
    </motion.div>  
  );
}
