import React  from 'react';
// import {Link} from 'react-router-dom';
import "./Home.css";
import { SidebarLink } from '../customComps';
// import { useSelector } from 'react-redux';
// import { MakeLinkActive } from '../../../API/commonFunctions';
import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom/dist';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Sessions from '../Chat/sessions';
// import { useParams } from 'react-router-dom';
import { getDomain } from '../../../domains';
import CommonVariables from '../../../variables';
import $ from 'jquery';
import { MaterialUISwitch, toggleTheme } from '../../../API/commonFunctions';
import logo from '../../../images/AskHrlogo.png'
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
// import PersonAdd from '@mui/icons-material/PersonAdd';
// import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import NightlightOutlinedIcon from '@mui/icons-material/NightlightOutlined';
import { createTheme, FormControlLabel } from '@mui/material';
import { LightMode } from '@mui/icons-material';
import { ThemeProvider } from 'styled-components';
import { blueGrey, grey } from '@mui/material/colors';




function Sidebar(props) {
  const isSuperAdmin = localStorage.getItem('userRole').toLocaleLowerCase()==='superadmin'? true:false;
  const isOrgAdmin = localStorage.getItem('userRole').toLocaleLowerCase()==='tenantadmin'? true:false;
  const isOrgUser = localStorage.getItem('userRole').toLocaleLowerCase()==='tenantuser'? true:false;
  let storageKey = 'sideMenuItemKey';
  let location = useLocation();
  const [menuToggle, setMenuToggle] = useState(false);
  const [isActive, setIsActive] = useState();
  const [theme, setTheme] = useState(true);
  const domain = getDomain(window.location.hostname);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigation = useNavigate();
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
});
const lightTheme = createTheme({
    palette: {
      mode: 'light',
    },
});

  function logout() {
    let redirectUrl = `http://${domain}`;
    if(domain === 'localhost') {
        redirectUrl = `http://${domain}:${CommonVariables.port2}`;
    }
    localStorage.clear();
    window.location.href = redirectUrl;
    // navigation("/");
  }
  const themeToggle = ()=>{
    props.setTheme(!props.theme)
    setTheme(!theme)
  }
  //   }
  // },[location.pathname]);
  function handleProfile() {
    navigation(`/dashboard/profile`);
  }
   const access = localStorage.getItem('dbInitated');
   useEffect(()=>{
    const urlPath = location.pathname;
    const parts = urlPath.split('/');
    const endingPart = parts[parts.length - 1];
    localStorage.setItem(storageKey, endingPart);
    let storedItem = localStorage.getItem(storageKey).toLocaleLowerCase();
    if(storedItem.includes(endingPart)){
      setIsActive(endingPart);
    }
  },[location.pathname,theme]);
  return (
    <>
      <div data-bs-theme={props.theme?"light":"dark"} className={`sidebar-div border-0 border-end card rounded-0 d-flex flex-column justify-content-between align-items-center h-100 position-absolute position-lg-static col-sm-2 sidebar-m-hide flex-grow overflow-auto ${props.leftNavToggle?"sidebar-div-hide position-absolute":"position-absolute"} ${props.theme?"main-background":"main-background-dark"}`}>
        <div  className='w-100'>
          <div className='d-flex justify-content-center align-items-start'> 
            <div
              className="hamburger position-relative pt-3"
              style={{ "maxWidth": "50px" }}
            >
              <button
                className="material-symbols-outlined d-lg-none bg-transparent border-0 text-light d-flex align-items-center"
                onClick={() => {
                  props.sideMenuToggle();
                  setMenuToggle(!menuToggle);
                }}
              >
                menu
                {/* {menuToggle?"menu":"close"} */}
                {/* DoubleArrowLeftIcon */}
              </button>
            </div>
            <div className="d-flex align-items-start justify-content-center">
              <img type='submit' onClick={()=>navigation('/dashboard')} src={logo} width="200px" height="200px" style={{borderRadius:"25px"}} className="mb-0 ms-0 p-2 mt-0" alt="app logo" />
            </div>          
          </div>  
          <div className="w-100 mt-4 p-2" data-bs-theme={props.theme?"light":"dark"}>
            {isSuperAdmin && <SidebarLink linkTo = {"organisations"} active={isActive==="organisations"? true: false} icon={"store"} />}
            {isSuperAdmin && <SidebarLink linkTo = {"tiers"} active={isActive==="tiers"? true: false} icon={"store"} />}
            {isOrgAdmin && access === 'true' && <SidebarLink linkTo = {"users"}  active={isActive==="users"? true: false} icon={"group"} />}
            {isOrgAdmin && access === 'true' && <SidebarLink linkTo = {"files"}  active={isActive==="files"? true: false} icon={"folder"} />}
            {isOrgAdmin && access === 'true' && <SidebarLink linkTo = {"groups"}  active={isActive==="groups"? true: false} icon={"groups"} />}
            {isOrgAdmin && <SidebarLink access={localStorage.getItem('dbInitated')} linkTo = {"database"}  active={isActive==="database"? true: false} icon={"storage"} />}
            {/* {(!isSuperAdmin && !isOrgAdmin) && <SidebarLink linkTo = {"chat"}  active={isActive==="chat"? true: false} icon={"chat"}/>} */}
            {isOrgUser && <Sessions theme={theme} active={isActive} loadSession={props.loadSession}/>}
          </div> 
        </div>       
        <div className="w-100 d-flex flex-column align-items-start justify-content-between p-2 mb-5">
          <div className='w-100 fs-5 d-flex align-items-start justify-content-start'>
            <Box className="w-100" sx={{ display: 'flex', alignItems: 'start', textAlign: 'start', justifyContent: 'start !important' }}>
              <Tooltip title="Account settings" placement='right' className='d-flex justify-content-start'>
                <IconButton
                  className='w-100 fs-5 left-nav-button'
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 0, borderRadius: '10px !important', color: '#212529BF' }}
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  <Avatar className="p-2" sx={{ width: 35, height: 35, mr: 1 }}>{localStorage.getItem('firstName')?.split('')[0]}</Avatar>
                  <span className='ms-3 left-nav-button'>Account</span>
                </IconButton>
              </Tooltip>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              class={`${theme?"main-background-dark":"main-background"}`}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
            
                sx: {
                  overflow: 'visible',
                  '& .MuiAvatar-root': {
                    width: 25,
                    height: 25,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                    
                  },
                  backgroundColor:grey[800]
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
            <ThemeProvider theme={$(".lower-div").attr("data-bs-theme") === "light"? lightTheme : darkTheme}>
              <MenuItem onClick={handleProfile} className="text-white"  
              sx={{backgroundColor:grey[800]}}
              >
                <ListItemIcon>
                  <Avatar fontSize="small"/> 
                </ListItemIcon>
                Profile
              </MenuItem>
              <MenuItem onClick={(e) => logout()} className="text-white"
               sx={{backgroundColor:grey[800]}}
              >
                <ListItemIcon>
                  <Logout fontSize="small text-secondary"/>
                </ListItemIcon>
                Logout
              </MenuItem>
            </ThemeProvider>  
            </Menu>
          </div>
          <div className='d-flex align-items-center w-100 fs-5 left-nav-button justify-content-start' style={{color: '#212529BF'}}>
            <FormControlLabel 
                control={<MaterialUISwitch role='switch' onClick={()=>{themeToggle()}} sx={{ m: 1,mr:0,mt:0.5,mb:0.5 }}  />}
              />
            <span className='px-2 pb-1 left-nav-button'>
             {/* { theme?<LightMode/>:<NightlightOutlinedIcon className='m-0 p-0' sx={{ width: 25, height: 24, mr: 1 }}/>} */}
            Theme
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;   