import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { validateDate } from '@mui/x-date-pickers/internals';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadious:2,
  boxShadow: 24,
};



export default function LicenceModal({openmodal,startDate,setStartDate,endDate,setEndDate,setOpenModal,updateLicense,selectLicense,deleteLicense,inAdd,inDelete,inUpdate,handleClose}) {


  const validateForm= async ()=>{
    handleClose();  
    if (inAdd || inUpdate){
          if (startDate<endDate)
          {
            if(inUpdate){
            updateLicense();
            }
            else if(inAdd){
              selectLicense();
            }     
          }
        }
        else if(inDelete){
          deleteLicense();
        }
         
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={true}
        onClose={()=>setOpenModal(false)}
      >


          <Box sx={{
            position:"absolute",
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius:2,
            boxShadow: 24,
          }} >
            {inDelete?

            <>
            <div className='bg-warning fc-white rounded-top-3 text-white py-2 fs-4 ps-4'>Delete Licence</div>
            <div className='d-flex flex-column p-4'>
              <div className='text-warning'> Are you sure want to remove licence?</div>
              <div className='mt-5 d-flex justify-content-end'>
            <Button sx={{marginRight:2}} onClick={()=>validateForm()} variant='contained'  >Submit</Button>
            <Button onClick={()=>handleClose()} color='error' variant='outlined' autoFocus>Cancel</Button>
            </div>
            </div></>
            :
           <>
           <div className='bg-black fc-white rounded-top-3 text-white py-2 fs-4 ps-4'>Select Duration</div>
            <div className='d-flex flex-column p-4'>
          <DatePicker sx={{
            marginBottom:2
            }}
                label="Start Date"
                value={dayjs(startDate)}
                onChange={(date) => setStartDate(new Date(date).toISOString())}
            />
            <DatePicker sx={{
                marginBottom:3
                }}
                label="End Date"
                value={dayjs(endDate)}
                onChange={(date) => setEndDate(new Date(date).toISOString())}
            />
             <div className='mt-3 d-flex justify-content-end'>
            <Button sx={{marginRight:2}} onClick={()=>{validateForm()}} variant='contained'>Submit</Button>
            <Button onClick={()=>handleClose()} color='error' variant='outlined'>Cancel</Button>
            </div>
            </div></>}
          </Box>
      </Modal>
    </div>
  );
}
