"use client";
import React, { useState, useEffect } from 'react';
import $ from "jquery";
import { 
    motion, 
    // AnimatePresence 
} from "framer-motion";
import { ErrorBoundary } from "react-error-boundary";
import TextField from '@mui/material/TextField';
import { baseApiCall } from '../../../API/apiCalls';
import CommonVariables from '../../../variables';
import Notifications from '../../../Notifications/Messages';
import { 
    ThemeProvider, 
    // createTheme 
    } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { fallbackRender, materialThemes } from '../../../API/commonFunctions';
import EditIcon from "@mui/icons-material/Edit";
// import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
// import Commonloader from '../../../Layout/Commonloader';
import CrudModal from '../Modals/crudModal';
// import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import { useNavigate } from 'react-router-dom/dist';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { useParams } from 'react-router-dom/dist';

/*****************************************IMPORTS FOR MUI GRID**********************************************************/
import { 
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

/*******************************************IMPORTS TAB STRIP****************************************************************/
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import CommonLoading from '../../../Layout/CommonLoading';
import License from './license';
// import { useSelector } from 'react-redux';

function EditOrganisation(props) {
    console.log(props);
    const [rows, setRows] = useState([]);
    // const [rowModesModel, setRowModesModel] = useState({});
    const [organisation, setOrganisation] = useState(props.editOrganisation);
    // const [organisationName, setOrganisationName] = useState(props.editOrganisation.tenant)
    const [inEdit, setInEdit] = useState(false);
    const [currentRowId, setCurrentRowId] = useState();
    const [modalInOpen, setModalInOpen] = useState(false);
    const [inDelete, setInDelete] = useState(false);
    const [inCreateAdmin, setInCreateAdmin] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [inAdd, setInAdd] = useState(false);
    const [isOrganisation, setIsOrganisation] = useState(true);
    const [isUserGroup, setIsUserGroup] = useState(false);
    const [isUser, setIsUser] = useState(false);
    const [orgName, setOrgName] = useState();
    const [organisationById, setOrganisationById] = useState();
    let navigate = useNavigate();
    // console.log(props.editOrganisation);
    let { organisationId } = useParams();
    // console.log(organisationById);

    const [tabValue, setTabValue] = useState(0);
   
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    /****************************************TAB STRIP*************************************************/
    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
    }
      
    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };
    
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    
    /********************** FUNCTION EXECUTABLE ON CREATE ADMIN MODAL OPEN **********************/  
    const handleCreateAdmin = () => {
        setInAdd(true);
        setModalInOpen(true);
        setInCreateAdmin(true);
    };

    const createAdmin =async (data)=>{
        console.log(data);
        setLoading(true);
        const result = await baseApiCall(CommonVariables.urls.addOrganisationAdminUrl,data);
        if(!result?.isFailure && result?.isFailure === false){
          setLoading(false);
          Notifications.ShowSuccessMessage(result?.message);
          getOrganisationById();
          handleClose();
          // setRows(rows.filter((row) => row.id !== data.id));
        }
        console.log(result);
        handleClose();
        setLoading(false);
    };

    const handleClose = () => {
        setModalInOpen(false);
        setOrgName("")
        setIsActive(false)
        setInAdd(false)
        setInEdit(false)
        setInDelete(false)
        setInCreateAdmin(false)
    };

    const updateOrganisation = async ()=>{
        if(inEdit){
            setInEdit(false);
            setLoading(true);
            const result = await baseApiCall(CommonVariables.urls.updateOrganisationUrl,{id:organisationId,tenantName:organisationById.tenantName,isActive:organisationById.isActive})
            if(!result?.isFailure && result?.isFailure === false){
                Notifications.ShowSuccessMessage(result?.message);
                setLoading(false);
                return;
            }
            Notifications.ShowErrorMessage("Problem Updating Organisation, Try Again");
            // setOrganisation(props.editOrganisation);
            setOrganisationById(result?.data);
            setLoading(false);
        }
        setInEdit(true);
    };

    const getOrganisationById = async () => {
        setLoading(true);
        const result = await baseApiCall(CommonVariables.urls.getOrganisationByIdUrl,{id: organisationId}); //fetching organization by id for Admin details.
        if (!result?.isFailure && result?.isFailure === false && result.data) {
            // console.log(result.data);
            setOrganisationById(result?.data);
            setLoading(false);
        }
        setLoading(false);
    }

    /**********************FUNCTION EXECUTABLE ON EDIT ORGANISATION ADMIN**********************/  
    const updateOrganisationAdmin = async() => {
        setLoading(true);
        const result = await baseApiCall(CommonVariables.urls.getUserByIdUrl, {id: organisationById?.adminDetails?.id});
        if(!result.isFailure && result?.isFailure === false ){
            // console.log(result?.data);
            props.setDataToPassToEditAdmin(result?.data);
            setLoading(false);
        }
        setLoading(false);
        navigate(`/dashboard/organisations/edit/admin/${organisationById?.adminDetails?.id}`);
    }
    // console.log(organisationById);
    var tempDate=new Date(organisationById?.createdDate);

    /******************************************************FUNCTION EXECUTABLE ON CANCEL ORGANIZATION EDIT MODE*****************************************************/
    const handleCloseEditMode=()=>{
        setInEdit(false);
    }
    useEffect(()=>{
        getOrganisationById();
    },[])
    return (
        <ErrorBoundary
            fallbackRender={fallbackRender}
            onReset={(details) => {
                // Reset the state of your app so the error doesn't happen again
            }}
        >
            <ThemeProvider theme={materialThemes.lightTheme}>
                <CssBaseline />
                    <motion.div 
                        animate="final" 
                        className="for-transition text-center grids p-4 h-100"
                        sx={{ transition: "none !important" }}
                    >
                        <div className='for-transition card w-100 h-100 border border-1 border-color-css rounded-3 shadow position-relative overflow-auto'>
                            {loading? <CommonLoading /> :
                                <Box 
                                    className="text-body-secondary"
                                    style={{ transition: "none !important" }}
                                    sx={{ width: '100%', transition: "none !important",
                                        "& .actions": {
                                        color: "text.secondary",
                                        },
                                        "& .textPrimary": {
                                        color: "text.primary",
                                        },
                                    }}
                                >
                                    <Box 
                                        sx={{ borderBottom: 1, borderColor: 'divider',
                                            width: '100%', transition: "none !important",
                                            "& .actions": {
                                            color: "text.secondary",
                                            },
                                            "& .textPrimary": {
                                            color: "text.primary",
                                            },
                                        }} 
                                        className="text-body-secondary position-sticky top-0 z-1 bg-body-tertiary"
                                        style={{ transition: "none !important" }}
                                    >
                                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example" className='' >
                                            <Tab className="text-body-secondary" label="Organisation Details" {...a11yProps(0)} />
                                            <Tab className="text-body-secondary" label="Licences" {...a11yProps(1)} />
                                            <Tab className="text-body-secondary" label="Statistics" {...a11yProps(2)} />
                                        </Tabs>
                                    </Box>
                                    <CustomTabPanel value={tabValue} index={0}>
                                        {/* <h5 className="text-body-secondary p-2 d-inline fw-bold">Organisation Details</h5> */}
                                        <div className='d-flex flex-column'>                                     
                                            <div className='d-flex gap-5 justify-content-between mb-2'>
                                                <div className=''>
                                                    {!organisationById?.adminDetails?.name && <Button
                                                        // color="primary"
                                                        variant="contained"
                                                        style={{minWidth:"8rem",maxWidth:"10rem"}}
                                                        className="pt-1 pb-1 m-1 mb-0 button-color text-light my-auto"
                                                        onClick={()=>handleCreateAdmin()}        
                                                        >
                                                        Add Admin
                                                    </Button>}
                                                </div>
                                                <div className=''>                              
                                                    <Button 
                                                        // color="primary"
                                                        variant="contained"
                                                        style={{minWidth:"8rem",maxWidth:"10rem"}}
                                                        className="pt-1 pb-1 button-color m-1 mb-0 text-light my-auto"
                                                        startIcon={inEdit?<DoneAllOutlinedIcon />:<EditIcon />}
                                                        onClick={()=>{updateOrganisation()}}
                                                    >
                                                        {inEdit?"Update":"Edit"}
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className='d-flex flex-column flex-wrap gap-3'>                                  
                                                <div className='d-flex col-6'>
                                                    <label className='text-body-secondary fs-6 fw-bold p-1 col-5 text-start'>Name  </label>
                                                    <label className='text-body-secondary fs-6 fw-bold p-1 col-md-1'>: </label>
                                                    {inEdit ?  
                                                        <TextField 
                                                            className='ms-2 p-1 text-body-secondary' 
                                                            autoFocus 
                                                            variant="standard" 
                                                            value={organisationById?.tenantName}  
                                                            onChange={(event) => {setOrganisationById({...organisationById,tenantName:event.target.value});}} 
                                                            onPointerUpCancel ={console.log("asdfasd")} 
                                                            required /> :
                                                        <label className='text-body-secondary fs-6 p-1'>{organisationById?.tenantName} </label>
                                                    }
                                                </div>
                                                <div className='d-flex col-6'>
                                                    <label className='text-body-secondary fs-6 fw-bold p-1 col-5 text-start'>Is Active  </label>
                                                    <label className='text-body-secondary fs-6 fw-bold p-1 col-md-1'>: </label>
                                                    <div className="form-check form-switch ps-0 ms-5 ps-3 " disabled>
                                                        <input  disabled={!inEdit} bled className="is-active form-check-input border-2 border-primary-subtle fs-4" type="checkbox" onChange={() => {setOrganisationById({...organisationById,isActive:!organisationById.isActive});}} checked={organisationById?.isActive} role="switch"  />
                                                    </div>
                                                </div>
                                                <div className='d-flex col-6'>
                                                    <label className='text-body-secondary fs-6 fw-bold p-1 col-5 text-start'>Admin  </label>
                                                    <label className='text-body-secondary fs-6 fw-bold p-1 col-md-1'>: </label>
                                                    <label className={organisationById?.adminDetails?.name? `text-body-secondary fs-6 p-1` : `text-body-danger fs-6 p-1`}>{organisationById?.adminDetails?.name? organisationById?.adminDetails?.name : `No admin assigned` }</label>
                                                    {organisationById?.adminDetails?.name && <Button
                                                        title="Details"
                                                        variant="text"
                                                        style={{minWidth:"1rem",maxWidth:"2rem", borderRadius:"50px"}}
                                                        className=" text-body-secondary text-center p-2"
                                                        startIcon={<OpenInNewIcon />}
                                                        onClick={()=>{updateOrganisationAdmin()}}
                                                    >
                                                    </Button>}
                                                </div>    
                                                <div className='d-flex col-6'>
                                                    <label className='text-body-secondary fs-6 fw-bold p-1 col-5 text-start'>CreatedOn</label>
                                                    <label className='text-body-secondary fs-6 fw-bold p-1 col-md-1'>: </label>
                                                    <label className='text-body-secondary fs-6 p-1'
                                                        // style={{ width: "300px"}}
                                                    >
                                                        {String(((tempDate?.getMonth()+1)<10?("0"+(tempDate?.getMonth() + 1)):(tempDate?.getMonth() + 1)) + '-' +
                                                        ((tempDate?.getDate())<10?("0"+tempDate?.getDate()):(tempDate?.getDate())) + '-' +
                                                        (tempDate?.getFullYear()) + ' ' +
                                                        ((tempDate?.getHours())<10?("0"+tempDate?.getHours()):(tempDate?.getHours())) + ':' +
                                                        ((tempDate?.getMinutes())<10?("0"+tempDate?.getMinutes()): (tempDate?.getMinutes())) + ' ' +
                                                        (((tempDate?.getHours())<10?("0"+tempDate?.getHours()):(tempDate?.getHours()) )> 12 ? 'PM' : 'AM'))}
                                                    </label>
                                                </div>
                                                <div className='d-flex col-6'>
                                                    <label className='text-body-secondary fs-6 fw-bold p-1 col-5 text-start'>CreatedBy</label>
                                                    <label className='text-body-secondary fs-6 fw-bold p-1 col-md-1'>: </label>
                                                    <label className='text-body-secondary fs-6 p-1' disabled={inEdit}>{organisationById?.createdBy?.userName} </label>
                                                </div>
                                            </div> 
                                            {inEdit && 
                                            <div>
                                                <Button
                                                    color="error"
                                                    variant="contained"
                                                    style={{minWidth:"8rem",maxWidth:"10rem"}}
                                                    className="pt-1 pb-1 m-1 mb-0 text-light my-auto"
                                                    onClick={handleCloseEditMode}        
                                                    >
                                                    Cancel
                                                </Button>
                                            </div>
                                            }  
                                            <div className='text-end'>
                                                <Button
                                                    title="Go back"
                                                    variant="text"
                                                    style={{minWidth:"8rem",maxWidth:"10rem"}}
                                                    className=" text-body-secondary text-center p-1"
                                                    startIcon={<ArrowBackIosNewOutlinedIcon />}
                                                    onClick={()=>navigate('/dashboard/organisations')}
                                                >
                                                    Go back
                                                </Button>
                                            </div>                          
                                        </div>
                                    </CustomTabPanel>
                                    <CustomTabPanel value={tabValue} index={1}>
                                        <License
                                            tenantId={organisationId}    
                                            theme={props.theme}
                                        />
                                    </CustomTabPanel>
                                    <CustomTabPanel value={tabValue} index={2}>
                                        Statistics
                                    </CustomTabPanel>
                                    {loading ? <CommonLoading /> : modalInOpen && (
                                        <ThemeProvider theme={$(".lower-div").attr("data-bs-theme") === "light"? materialThemes.lightTheme : materialThemes.darkTheme}>
                                            <CssBaseline />
                                            <CrudModal
                                                rows={rows}
                                                modalInOpen={modalInOpen}
                                                currentRowId={currentRowId}
                                                inEdit={inEdit}
                                                isUser={isUser}
                                                isOrganisation={isOrganisation}
                                                isUserGroup={isUserGroup}
                                                inAdd={inAdd}
                                                inDelete={inDelete}
                                                handleClose={handleClose}
                                                // addOrganisation={addOrganisation}
                                                updateOrganisation={updateOrganisation}
                                                // deleteOrganisation = {deleteOrganisation}
                                                createAdmin={createAdmin}
                                                inCreateAdmin={inCreateAdmin}
                                                setInCreateAdmin={setInCreateAdmin}
                                                orgName={orgName}
                                                setOrgName={setOrgName}
                                                isActive={isActive}
                                                setIsActive={setIsActive}
                                                organisationById={organisationById}
                                                organisationDetails={organisation}
                                            />
                                        </ThemeProvider>
                                    )}
                                </Box>          
                            }
                        </div> 
                    </motion.div>         
            </ThemeProvider>
        </ErrorBoundary>
    );
}

export default EditOrganisation;