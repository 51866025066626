import React
// ,{ useState, useEffect } 
 from "react";
// import $ from 'jquery';
import { Routes
    ,Route
    // ,useLocation 
} from 'react-router-dom'
import Home from '../Dashboard/components/Home/Home'


function DashboardRouting(props) {
    
    return (
        <Routes>
            <Route exact path={`/dashboard`} element={<Home onLoaderRaise={props.onLoaderRaise} />} />
            {/* <Route exact path='/dashboard/profile/*' element={<Profile onLoaderRaise={props.onLoaderRaise} />} /> */}
        </Routes>
    );
}
export default DashboardRouting;    