import React, 
    { 
        useState, 
        // useEffect 
    } from "react";
// import { BrowserRouter, Routes, Route, useNavigate, Outlet } from "react-router-dom";
// import $ from "jquery";
// import CommonVariables from "./variables";
// import APIConnector from "./apiconnector";
import "@progress/kendo-theme-default/dist/all.css";
import Loginrouting from "./Routing/LoginRouting";
import DashboardRouting from "./Routing/DashboardRouting";
import Layout from "./Layout/Layout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
// import LoadingSpinner from "./Layout/Commonloader.js";
import { useCallback } from 'react';
// import Notifications from "./Notifications/Messages.js"
// import AllRoutes from "./Routing/AllRoutes";
import CommonAlert from "./Layout/CommonAlert";
import CommonLoading from "./Layout/CommonLoading.js";

/**
 * This is the main component
 * 
 */
function Routing() {
    const [isLoading, setIsLoading] = useState(false);
    const [LoaderMessage, setLoaderMessage] = useState("");
    const [LoaderStyles, setLoaderStyles] = useState({});

    const [isAlert, setisAlert] = useState(false)
    const [alertDataObj, setalertDataObj] = useState({});

    const onLoaderRaise = useCallback((flag, msg, LoaderStyles) => {
        if (flag) {
            setIsLoading(flag);
            if (!msg)
                msg = "Loading..."
            if (!LoaderStyles)
                LoaderStyles = { marginTop: "0px" };
            setLoaderStyles(LoaderStyles);
            setLoaderMessage(msg);
        } 
        // else if(props.loading){
        //     setIsLoading(flag);
        // }
        else {
            setIsLoading(flag);
        }
    }, [isLoading]);

    const onAlert = useCallback((flag,dataObj) => {
        if (flag) {
            if (!dataObj) {
                setisAlert(false);
                return;
            }
            setisAlert(flag);
            setalertDataObj(dataObj);
        }
        else {
            setalertDataObj({});
            setisAlert(flag);
        }
    }, [isAlert]);

    return (
        <div className="h-100 m-0" >
            <div>
                <ToastContainer style={{ zIndex: "1000005" }} autoClose={5000} />
            </div>
            {isAlert && <CommonAlert alertDataObj={alertDataObj} />}
            <Layout onLoaderRaise={onLoaderRaise} onAlert={onAlert}>
                <div className="primary" style={{ "height": "" }}>
                    {isLoading? <CommonLoading message={LoaderMessage} LoaderStyles={LoaderStyles} /> :
                        <DashboardRouting onLoaderRaise={onLoaderRaise} onAlert={onAlert} />
                    }
                </div>
                {/* <div style={{"height":"100vh" ,"background":" linear-gradient(90deg, rgba(246,224,244,1) 0%, rgba(238,227,252,1) 34%, rgba(199,239,254,1) 62%, rgba(205,234,245,1) 89%)"}}>
                    {isLoading? <CommonLoading message={LoaderMessage} LoaderStyles={LoaderStyles} /> :
                        <Loginrouting onLoaderRaise={onLoaderRaise} />
                    }
                </div> */}
            </Layout>
        </div>
    );
}

export default Routing; 