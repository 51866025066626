import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { fallbackRender, materialThemes } from '../../../API/commonFunctions';
import { ThemeProvider } from 'styled-components';
import { CssBaseline } from '@mui/material';
// import Commonloader from '../../../Layout/Commonloader';
import { useState } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import TextField from '@mui/material/TextField';
import { useEffect } from 'react';
import { baseApiCall } from '../../../API/apiCalls';
import CommonVariables from '../../../variables';
// import { useNavigate } from 'react-router-dom/dist';
import Notifications from '../../../Notifications/Messages';
import CommonLoading from '../../../Layout/CommonLoading';
// import { getDomain } from '../../../domains';



const Database = () => {
    const [loading, setLoading] = useState(false);
    const [inEdit, setInEdit] = useState(false);
    // const [databaseData, setDatabaseData] = useState();
    const [dataBaseName, setDataBaseName] = useState('');
    const [connectionString, setConnectionString] = useState('');
    const [domainName, setDomainName] = useState('');
    // const navigation = useNavigate();
    const getDomain = (location) => {
        // const location = window.location.hostname;
        const locationParts = location.split(".");
    
        let sliceTill = -2;
    
        const isLocalHost = locationParts.slice(-1)[0] === 'localhost';
        if(isLocalHost) sliceTill = -1;
    
        return locationParts.slice(sliceTill).join(".");
    }

    const domain = getDomain(window.location.hostname);
    function logout() {
        let redirectUrl = `http://${domain}`;
        if(domain === 'localhost') {
            redirectUrl = `http://${domain}:${CommonVariables.port2}`;
        }
        localStorage.clear();
        window.location.href = redirectUrl;
    //   window.location.href = "/";
    //   localStorage.clear();
    //   navigation("/");
    }
    // onClick={(e) => logout()}

    const loadDbDetails = async() => {
        setLoading(true);
        const result = await baseApiCall(CommonVariables.urls.getOrganisationDatabaseByIdUrl, {tenantId: localStorage.getItem('tenantId')});
        if (!result?.isFailure && result?.isFailure === false && result?.data) {
            console.log(result.data);
            // setDatabaseData(result?.data);
            setDataBaseName(result?.data?.dataBaseName);
            setConnectionString(result?.data?.connectionString);
            setDomainName(result?.data?.domainName);
            setLoading(false);
        }
        setLoading(false);
    } 

    const handleEdit = async() => {
        if(!inEdit){
            setInEdit(true);
        } else {
            console.log(localStorage.getItem('dbInitated'));
            if(localStorage.getItem('dbInitated') === 'false'){
                setLoading(true);
                const result = await baseApiCall(CommonVariables.urls.createOrganisationDatabaseUrl, {tenantId: localStorage.getItem('tenantId'), dataBaseName: dataBaseName,connectionString: connectionString,domainName: domainName});
                if (!result?.isFailure && result?.isFailure === false) {
                    // console.log(result.data);
                    // setDatabaseData(result?.data);
                    Notifications.ShowSuccessMessage(result?.message);   
                    setLoading(false);
                    loadDbDetails();
                    logout(); 
                }
                setLoading(false);
            } else{
                setLoading(true);
                const result = await baseApiCall(CommonVariables.urls.updateOrganisationDatabaseUrl, {tenantId: localStorage.getItem('tenantId'), dataBaseName: dataBaseName,connectionString: connectionString,domainName: domainName});
                if (!result?.isFailure && result?.isFailure === false) {
                    // console.log(result.data);
                    // setDatabaseData(result?.data);
                    Notifications.ShowSuccessMessage(result?.message);   
                    setLoading(false);
                    loadDbDetails();  
                    logout();
                }
                setLoading(false);
            }
            setInEdit(false);          
        }
    }

    useEffect(()=> {
        loadDbDetails();
    },[])
  return (
    <ErrorBoundary
        fallbackReder={fallbackRender}
        onReset={(details)=>{
            // Reset the state of your app so the error doesn't happen again
        }}
    >
        <ThemeProvider theme={materialThemes.lightTheme}>
            <CssBaseline />
                <motion.div
                    sx={{ tranistion: "none !important"}}
                    animate="final"
                    className="for-tranistion p-4 grids overflow-auto h-100"
                >
                    <div className="for-transition w-100 card h-100 border border-1 border-color-css rounded-4 shadow overflow-hidden">
                        { loading ? <CommonLoading /> :
                            <>
                               <div className="fs-3 py-3 px-3 mb-2 fw-semibold main-text text-center rounded-bottom-0 card border border-bottom bg-body-tertiary">
                                    Manage Database
                                </div>
                                <div className='d-flex gap-1 justify-content-end mb-2 p-4'>
                                    <Button 
                                        // color="primary"
                                        variant="contained"
                                        style={{minWidth:"8rem",maxWidth:"10rem"}}
                                        className="pt-1 pb-1 button-color m-1 mb-0 text-light my-auto"
                                        startIcon={inEdit?<DoneAllOutlinedIcon />:<EditIcon />}
                                        onClick={()=>{handleEdit()}}
                                    >
                                        {inEdit?"Update":"Edit"}
                                    </Button>
                                </div>
                                <div className='d-flex flex-column gap-3 px-sm-4'>                                  
                                        <div className='d-flex col-10'>
                                            <label className='text-body-secondary fs-6 fw-bold p-1 col-3 col-sm-4 text-start'>Database Name  </label>
                                            <label className='text-body-secondary fs-6 fw-bold p-1 col-md-1'>: </label>
                                            {inEdit ?  
                                                <TextField 
                                                    className='ms-2 p-1 text-body-secondary ' 
                                                    autoFocus 
                                                    variant="standard" 
                                                    value={dataBaseName}  
                                                    onChange={(event) => {setDataBaseName(event.target.value)}} 
                                                    required /> :
                                                <label className='text-body-secondary fs-6 p-1 col-11 col-sm-8 fw-semibold'>{dataBaseName? dataBaseName : ""}</label>
                                            }
                                        </div>
                                        <div className='d-flex col-10'>
                                            <label className='text-body-secondary fs-6 fw-bold p-1 col-3 col-sm-4 text-start'>Domain Name  </label>
                                            <label className='text-body-secondary fs-6 fw-bold p-1 col-md-1'>: </label>
                                            {inEdit ?  
                                                <TextField 
                                                    className='ms-2 p-1 text-body-secondary' 
                                                    // autoFocus 
                                                    variant="standard" 
                                                    value={domainName}  
                                                    onChange={(event) => {setDomainName(event.target.value)}} 
                                                    required /> :
                                                <label className='text-body-secondary fs-6 p-1 col-11 col-sm-8 fw-semibold'>{domainName? domainName: ""}</label>
                                            }
                                        </div>
                                        <div className='d-flex col-10'>
                                            <label className='text-body-secondary fs-6 fw-bold p-1 col-3 col-sm-4 text-start'>Connection String  </label>
                                            <label className='text-body-secondary fs-6 fw-bold p-1 col-md-1'>: </label>
                                            {inEdit?
                                                <TextField 
                                                    className='ms-2 p-1 text-body-secondary' 
                                                    // autoFocus 
                                                    variant="standard" 
                                                    value={connectionString}
                                                    onChange={(event)=>{setConnectionString(event.target.value)}}
                                                    required
                                                /> :
                                                <label className="text-body-secondary fs-6 p-1 col-11 col-sm-8 fw-semibold">{connectionString? connectionString: ""}</label> 
                                            }  
                                        </div>  
                                    </div> 
                                {inEdit && 
                                    <div className='d-flex align-items-center justify-content-center pt-2'>
                                        <Button
                                            color="error"
                                            variant="contained"
                                            style={{minWidth:"8rem",maxWidth:"10rem"}}
                                            className="pt-2 pb-1 m-1 mb-0 text-light my-auto"
                                            onClick={()=>setInEdit(false)}        
                                            >
                                            Cancel
                                        </Button>
                                    </div>
                                }
                            </>
                        }       
                    </div>
                </motion.div>
        </ThemeProvider>
    </ErrorBoundary>
  )
}

export default Database;