import { createSlice } from '@reduxjs/toolkit'

const initialState  = {
    isSuperAdmin:localStorage.getItem("userRole") === "SuperAdmin"?true:false,
    isOrgAdmin:localStorage.getItem("userRole") === "TenantAdmin"?true:false,
    isOrgUser:localStorage.getItem("userRole") === "TenantUser"?true:false
}
export const states = createSlice({
    name:'state',
    initialState,
    reducers : {
        setRoles : (state = initialState,action) =>{
            switch (action.payload.value[0]) {
                case "SuperAdmin":
                    localStorage.setItem("userRole","SuperAdmin")
                    return {
                        isSuperAdmin:true
                    }
                case "TenantAdmin":
                    localStorage.setItem("userRole","TenantAdmin")
                    return {
                        isOrgAdmin:true
                    }
                case "TenantUser":
                    localStorage.setItem("userRole","TenantUser")
                    return {
                        isOrgUser:true
                    }
                default:
                    break;
            }
        },
    }
}
)

export const {setSuperAdmin, setOrgAdmin,setRoles} = states.actions
export default states.reducer