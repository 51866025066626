import React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
// import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Zoom from "@mui/material/Zoom";
import Tooltip from "@mui/material/Tooltip";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Notifications from "../../../Notifications/Messages";
import "../css/dataGrid.css";
import {
  motion,
  //  AnimatePresence
} from "framer-motion";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { baseApiCall } from "../../../API/apiCalls";
import { useEffect } from "react";
import CommonVariables from "../../../variables";
import CrudModal from "../Modals/crudModal";
import CommonLoading from "../../../Layout/CommonLoading";
// import { useParams } from 'react-router';
import { useParams } from "react-router";
import $ from "jquery";
import {
  handleModalDropdown,
  handleRowsOnDelete,
} from "../Modals/modalFunctions";
import { red, teal } from "@mui/material/colors";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from 'react-router-dom';

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});
const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
});

const UserGroupFiles = () => {
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [tenantFiles, setTenantFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFileIds, setSelectedFileIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalInOpen, setModalInOpen] = useState(false);
  const [inAdd, setInAdd] = useState(false);
  const [inDelete, setInDelete] = useState(false);
  const [currentRowId, setCurrentRowId] = useState();
  const [isUser, setIsUser] = useState(false);
  const [isOrganisation, setIsOrganisation] = useState(false);
  const [isUserGroup, setIsUserGroup] = useState(false);
  const [isUserGroupFile, setIsUserGroupFile] = useState(true);
  const [selectedRows, setSelectedRows] = React.useState([]);
  let { userGroupId } = useParams();
  let groupName = localStorage.getItem(userGroupId + "_GroupName");
  const navigate = useNavigate();

  const selectedFilesInUserGroup = async (id) => {
    setLoading(true);
    const result = await baseApiCall(
      CommonVariables.urls.getAllUserGroupFilesUrl,
      { id: userGroupId }
    );
    if (!result?.isFailure && result?.isFailure === false && result.data) {
      setSelectedFiles(result.data?.length ? result.data : []);
      setRows(result.data?.length ? result.data : []);
      setSelectedFileIds(result.data.map((file) => (file?.id ? file.id : "")));
      setLoading(false);
      // console.log(selectedFileIds);
    }
    setLoading(false);
    // handleClose();
  };
  /********************** FUNCTION EXECUTABLE ON DELETE MODAL OPEN **********************/
  const handleDeleteClick =
    (row, isSingleRow = true) =>
    async () => {
      setModalInOpen(true);
      setInDelete(true);
      setInAdd(false);
      if (isSingleRow) {
        setCurrentRowId(row.id);
        return;
      }
      setCurrentRowId(undefined);
    };

  const updateUserGroup = async (data) => {
    setLoading(true);
    const result = await baseApiCall(
      CommonVariables.urls.updateUserGroupUrl,
      data
    );
    if (!result?.isFailure && result?.isFailure === false) {
      setLoading(false);
      Notifications.ShowSuccessMessage(result.message);
      selectedFilesInUserGroup();
      handleClose();
    }
    setLoading(false);
  };
  const getAllDocuments = async () => {
    setLoading(true);
    const result = await baseApiCall(CommonVariables.urls.getAllFilesUrl, {});
    if (!result?.isFailure && result?.isFailure === false && result.data) {
      setTenantFiles(result.data);
      setLoading(false);
      // console.log(result.data);
    }
    setLoading(false);
  };
  // const handleFilesDropdown = ()=>{
  //     let arr;
  //     rows.forEach(file => {
  //          arr = tenantFiles.filter((item)=>file.id !== item.id)
  //     });
  //     setTenantFiles(arr)
  // }
  const addFilesToUserGroup = async (data) => {
    setLoading(true);
    const result = await baseApiCall(
      CommonVariables.urls.assignDocumentsToUserGroupUrl,
      data
    );
    if (!result?.isFailure && result?.isFailure === false) {
      setLoading(false);
      Notifications.ShowSuccessMessage(result.message);
      selectedFilesInUserGroup();
      handleClose();
    }
    setLoading(false);
  };

  const handleBack = () => {
    navigate('/dashboard/groups'); // Go back to the previous page or a default page
  };

  const columns = [
    {
      field: "name",
      headerName: "Files",
      width: 250,
      editable: false,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Manage",
      width: 250,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <Tooltip
            title="Delete"
            arrow
            TransitionComponent={Zoom}
            placement="top"
            className="mx-5 text-secondary"
          >
            <GridActionsCellItem
              icon={<DeleteIcon sx={{fill:red[800]}} />}
              label="Delete"
              className="textPrimary text-body-secondary mx-5 "
              onClick={handleDeleteClick(row)}
              sx={{backgroundColor:teal[50]}}
            />
          </Tooltip>,
        ];
      },
    },
  ];
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  /********************** FUNCTION EXECUTABLE ON DELETE USERS **********************/
  const handleDeleteFiles = async () => {
    setLoading(true);
    const result = await baseApiCall(
      CommonVariables.urls.unassignDocumentsFromUserGroupUrl,
      {
        id: userGroupId,
        tenantId: CommonVariables.getlocalstorageData().tenantId,
        documentIds: currentRowId
          ? [currentRowId]
          : selectedRows.map((item) => item.id),
      }
    );
    if (
      !result?.isFailure &&
      result?.isFailure === false &&
      (result.data || result.data === null)
    ) {
      setRows(
        handleRowsOnDelete(
          rows,
          currentRowId ? [currentRowId] : selectedRows.map((item) => item.id)
        )
      );
      setLoading(false);
      // selectedFilesInUserGroup()
    }
    setModalInOpen(false);
    setInDelete(false);
    setLoading(false);
    setSelectedRows([]);
  };

  /********************** FUNCTION EXECUTABLE ON MODAL CLOSE **********************/
  const handleClose = () => {
    setModalInOpen(false);
    // setFileName("");
    setInAdd(false);
    setInDelete(false);
  };

  /********************** FUNCTION EXECUTABLE WHILE ADDING NEW RECORD **********************/
  const handleInAdd = () => {
    setInAdd(true);
    setModalInOpen(true);
  };
  useEffect(() => {
    selectedFilesInUserGroup();
    getAllDocuments();
  }, []);
  return (
    <motion.div
      sx={{ transition: "none !important" }}
      animate="final"
      className="for-transition p-4 grids overflow-auto h-100"
    >
      <div
        className="for-transition w-100 card border h-100 border-1 border-color-css rounded-4 shadow overflow-hidden"
        style={{}}
      >
        {loading ? (
          <CommonLoading />
        ) : (
          <>
            <div className="fs-3 py-3 px-3 mb-2 fw-semibold main-text text-center rounded-bottom-0 card border border-bottom bg-body-tertiary">
              <div style={{ display: "flex", alignItems: "center" }}>
                <ArrowBackIcon
                  style={{
                    cursor: "pointer",
                    margin: "0 1rem 0 2rem",
                    fontSize: "2rem",
                  }}
                  onClick={handleBack}
                />
                <div
                  style={{
                    flexGrow: 1,
                    textAlign: "center",
                    marginRight: "4rem",
                  }}
                >
                  {groupName} User Group Files
                </div>
              </div>
            </div>
            <div className="d-flex align-items-between justify-content-between">
              <Button
                // color="primary"
                variant="contained"
                style={{ minWidth: "9rem", maxWidth: "12rem" }}
                className="p-2 m-3 ms-4 mb-0 button-color"
                // startIcon={<AddIcon />}
                onClick={handleInAdd}
              >
                Update Files
              </Button>
              {selectedRows.length > 0 && (
                <Button
                  color="error"
                  variant="contained"
                  style={{ minWidth: "7rem", maxWidth: "10rem" }}
                  className="p-2 m-3 mb-0"
                  // startIcon={<AddIcon />}
                  onClick={handleDeleteClick(selectedRows, false)}
                >
                  Delete Files
                </Button>
              )}
            </div>
            <Box
              className=""
              sx={{
                minHeight: 520,
                height: "70vh",
                margin: "",
                fontWeight: "bold",
                padding: "26px",
                paddingTop: "16px",
                transition: "none !important",
                "& .actions": {
                  color: "text.secondary",
                },
                "& .textPrimary": {
                  color: "text.primary",
                },
              }}
            >
              <DataGrid
                className="text-body-secondary rounded-4 overflow-hidden"
                rows={rows}
                style={{ transition: "none !important" }}
                sx={{
                  transition: "none !important",
                  maxHeight: "68vh",
                  minHeight: "55vh",
                }}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                slotProps={{
                  toolbar: { setRows, setRowModesModel },
                }}
                disableRowSelectionOnClick
                checkboxSelection
                onRowSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  const selectedRows = rows.filter((row) =>
                    selectedIDs.has(row.id)
                  );

                  setSelectedRows(selectedRows);
                }}
                // GridFooter
              />
            </Box>
            {loading ? (
              <CommonLoading />
            ) : (
              modalInOpen && (
                <ThemeProvider
                  theme={
                    $(".lower-div").attr("data-bs-theme") === "light"
                      ? lightTheme
                      : darkTheme
                  }
                >
                  <CssBaseline />
                  <CrudModal
                    modalInOpen={modalInOpen}
                    handleDeleteFiles={handleDeleteFiles}
                    isUser={isUser}
                    isOrganisation={isOrganisation}
                    isUserGroup={isUserGroup}
                    isUserGroupFile={isUserGroupFile}
                    inAdd={inAdd}
                    inDelete={inDelete}
                    handleClose={handleClose}
                    rows={rows}
                    selectedFiles={selectedFiles}
                    tenantFiles={tenantFiles}
                    selectedFileIds={selectedFileIds}
                    updateUserGroup={updateUserGroup}
                    userGroupId={userGroupId}
                    addFilesToUserGroup={addFilesToUserGroup}
                  />
                </ThemeProvider>
              )
            )}
          </>
        )}
      </div>
    </motion.div>
  );
};

export default UserGroupFiles;