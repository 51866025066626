import { useEffect, useState } from "react";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import CommonVariables from "../../../variables";
// import { toggleTheme } from "../../../API/commonFunctions";
import logo from "../../../images/AskHrlogo.png";
// import { getDomain } from "../../../domains";
import * as React from 'react';
// import Box from '@mui/material/Box';
// import Avatar from '@mui/material/Avatar';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import Divider from '@mui/material/Divider';
// import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
// import Tooltip from '@mui/material/Tooltip';
// import PersonAdd from '@mui/icons-material/PersonAdd';
// import Settings from '@mui/icons-material/Settings';
// import Logout from '@mui/icons-material/Logout';


function Navbar(props) {
  const [menuToggle, setMenuToggle] = useState(false);
  // const [theme, setTheme] = useState(true);
  const navigation = useNavigate();
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  const getDomain = (location) => {
    // const location = window.location.hostname;
    const locationParts = location.split(".");

    let sliceTill = -2;

    const isLocalHost = locationParts.slice(-1)[0] === 'localhost';
    if(isLocalHost) sliceTill = -1;

    return locationParts.slice(sliceTill).join(".");
  }
  const domain = getDomain(window.location.hostname);

  function handleProfile() {
    navigation(`/dashboard/profile`);
  }
  function logout() {
    let redirectUrl = `http://${domain}`;
    if(domain === 'localhost') {
        redirectUrl = `http://${domain}:${CommonVariables.port2}`;
    }
    localStorage.clear();
    window.location.href = redirectUrl;
    // navigation("/");
  }
  // useEffect(()=>{
  //   // console.log($(".checkbox")[0].checked);
  //   props.setTheme($(".checkbox")[0].checked);
  // },[theme]);

  /*****************************************/
  return (
    <nav 
      className="navbar z-1 shadow-lg w-100 mx-auto d-lg-none"
      style={{minHeight: '60px'}}
    >
      <div className="navbar-container w-100 d-flex justify-content-between">
        <div className="d-flex align-items-center justify-content-center">
          
          {/* <div
            className="hamburger position-relative d-lg-none"
            style={{ "maxWidth": "50px" }}
          >
            <button
              className="material-symbols-outlined bg-transparent border-0 text-light d-flex align-items-center"
              onClick={() => {
                props.sideMenuToggle();
                setMenuToggle(!menuToggle);
              }}
            >
              menu
            </button>
          </div> */}
          
          <img src={logo} width="100px" height="80px" className="mb-0 ms-lg-5 ms-0 p-2" />
          {/* <h1 className="fs-2 mb-0 ms-lg-4 ms-0 text-start">askHR</h1> */}
        </div>
      </div>
    </nav>
  );
}
export default Navbar;
