import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { fallbackRender } from '../../API/commonFunctions';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import $ from 'jquery';
import CssBaseline from '@mui/material/CssBaseline';
import { motion } from "framer-motion";
import askHr from '../../images/askHr.png'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

const ErrorPage = () => {
  return (
    <ErrorBoundary
      fallbackRender={fallbackRender}
      onReset={() => {
        // Reset the state of your app so the error doesn't happen again
      }}
    >
      <ThemeProvider theme={$(".lower-div").attr("data-bs-theme") === "light"? lightTheme : darkTheme}>
        <CssBaseline />
        <motion.div
          sx={{ transition: "none !important" }}
          animate="final"
          className="for-transition p-4 grids text-secondary overflow-auto h-100"
        >
          <div 
            className="p-1 for-transition w-100 h-100 fs-2 d-flex align-items-center justify-content-center text-danger card border border-1 border-color-css rounded-3 shadow"
            style={{minHeight: '500px'}}
          >
            <img src={askHr} alt='askHr' style={{height: '300px', width: '300px'}}></img>
            <p>Uh oh! The page you're looking for does not exist.</p>
          </div>
        </motion.div>
      </ThemeProvider>
    </ErrorBoundary>
  )
}

export default ErrorPage;