import * as React from "react";
import Notifications from "../../../Notifications/Messages";
import $ from 'jquery';
import { useState } from "react";
import Box from "@mui/material/Box";
import { Button, createTheme, Divider, Grid,Paper, styled } from "@mui/material";
import { ThemeProvider, 
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { motion, 
} from "framer-motion";
import { useEffect } from "react";
import { 
  baseApiCall,
} from "../../../API/apiCalls";
import CommonVariables from "../../../variables";
import { fallbackRender, materialThemes } from "../../../API/commonFunctions";
import { ErrorBoundary } from "react-error-boundary";
import  "./tiers.css";
import { Add } from "@mui/icons-material";
import CommonLoading from "../../../Layout/CommonLoading";
import CrudModal from "../Modals/crudModal";
import { blueGrey, grey, red } from "@mui/material/colors";

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

export default function Tiers(props) {
  const [rows, setRows] = useState([]);
  const [modalInOpen, setModalInOpen] = useState(false);
  const [inEdit, setInEdit] = useState(false);
  const [inAdd, setInAdd] = useState(false);
  const [inDelete, setInDelete] = useState(false);
  const [tierName,setTierName] = useState();
  const [maxTierUsers,setMaxTierUsers] = useState();
  const [maxTierMessageCall,setMaxTierMessageCall] = useState();
  const [inCreateAdmin, setInCreateAdmin] = useState(false);
  const [orgName, setOrgName] = useState();
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentRowId, setCurrentRowId] = useState();
  useEffect(() => {
    loadTier();
  }, []);

  const loadTier = async () => {
    setLoading(true);
    const result = await baseApiCall(CommonVariables.urls.getAllTiersUrl,{});
    if (!result?.isFailure && result?.isFailure === false && result?.data) {
      setRows(result?.data);
      setLoading(false);
    }
    handleClose();
    setLoading(false);
  };

  const handleClose = () => {
    setModalInOpen(false);
    setOrgName("");
    setIsActive(false);
    setInAdd(false);
    setInEdit(false);
    setInDelete(false);
    setInCreateAdmin(false);
  }
  
  const addTier = async (data)=>{
    setLoading(true);
    console.log(data);
    const result = await baseApiCall(CommonVariables.urls.createTierUrl,data)
    if(!result?.isFailure && result?.isFailure === false){
      setLoading(false);
      handleClose();
      Notifications.ShowSuccessMessage(result?.message);
      loadTier();
    }
    handleClose();
    setLoading(false);
  }

  const updateTier = async (data)=>{
    setLoading(true);
    const result = await baseApiCall(CommonVariables.urls.updateTierUrl,data)
    if(!result?.isFailure && result?.isFailure === false){
      setLoading(false);
      handleClose();
      Notifications.ShowSuccessMessage(result?.message);
      loadTier();
    }
    handleClose();
    setLoading(false);
  }

  const deleteTier =async (data)=>{
    setLoading(true);
    const result = await baseApiCall(CommonVariables.urls.deleteTierUrl,data);
    if(!result?.isFailure && result?.isFailure === false){
      setLoading(false);
      Notifications.ShowSuccessMessage(result?.message);
      handleClose();
      loadTier();
      // setRows(rows.filter((row) => row.id !== data.id));
    }
    handleClose();
    setLoading(false);
  }

  const handleOnAddClick = () => {
    setInAdd(true);
    setModalInOpen(true);
  }

  const handleEditClick = (data) => ()=>{
    setCurrentRowId(data.id);
    setTierName(data.name);
    setMaxTierMessageCall(data.max_Message_Count);
    setMaxTierUsers(data.max_User_Count);
    setModalInOpen(true);
    setInEdit(true);
  };
 
  const handleDeleteClick = (row) => async() => {
    setCurrentRowId(row.id);
    setModalInOpen(true);
    setInDelete(true);
  };

  return (
    <ErrorBoundary
    fallbackRender={fallbackRender}
    onReset={() => {
    }}
    > 
        <CssBaseline />
          <motion.div   
            sx={{ transition: "none !important" }}
            animate="final"
            className="for-transition p-4 grids  overflow-auto h-100"
          >
            <div
              className="for-transition w-100 card bg-body-secondary border h-100 border-1 border-color-css rounded-3 shadow overflow-auto position-relative"
              style={{}}
            >
            { loading ? <CommonLoading /> : <>
              <div className="fs-3 pt-3 px-3 pb-3 mb-2 fw-semibold main-text rounded-0 text-center position-sticky top-0 card border- border-bottom- z-1">
              Manage Tiers
            </div>
          <Button 
            variant="contained"
            onClick={handleOnAddClick} 
            style={{minWidth:"9rem",maxWidth:"12rem",top:"1.2rem"}}
            className="p-2 m-3 ms-5 my-0 button-color position-sticky z-1"
            startIcon={<Add/>}
            >
              Add New Tier
              </Button> 
           <Grid container spacing={4}>
                <Box
                sx={{
                    p: 4,
                    width:"100%",
                    height:"80%",
                    borderRadius: 4,
                    bgcolor: 'transparent',
                    display: 'grid',
                    gridTemplateColumns: { md: '1fr 1fr 1fr' },
                    gap: 4,
                }}
                >
                {rows.map((value)=>{
                    return <Paper elevation={3} sx={{
                        width:"86%",
                        maxWidth:"22rem",
                        height:"510px",
                        margin:5,
                        paddingTop:2,
                        alignItems:"center",
                        textAlign: 'center',
                        borderRadius:5,
                    }} className={`${props.theme?"main-background":"main-background-dark"} cardStyle border border-3 border-primary`} >
                        <div className="text-white">
                            <div>Premium </div>
                            <div className="fs-4 fw-bold">{value?.name}</div>
                            <div>{"Maximum Users :"+ value?.max_User_Count}</div>
                            <div>{"Maximum Message Count :" +value?.max_Message_Count}</div>
                        </div>
                        <Divider className="mt-1" variant="middle" />
                        <div className="d-flex justify-content-center text-body-secondary">
                        <Box sx={{
                             width:"95%",
                             height:"250px",
                            borderRadius: 5,
                            textAlign:"left",
                            marginTop:1,
                            padding:1,
                            paddingTop:3,
                            paddingBottom:3,
                        }}
                        className="bg-body-tertiary"
                        >
                           <ul>
                                <li>isActive'is assigned a value but never used   </li>
                                <li>loading' is assigned a value but never used </li>
                                <li>data 11</li>
                            </ul> 
                            </Box></div>
                        <Button onClick={handleEditClick(value)} sx={{marginTop:2,borderColor:blueGrey[800],border:"2px", alignItems:"center",textAlign:"center", width:"250px",borderRadius:2,backgroundColor:blueGrey[900] ,color:grey[100]}} className="border border-2 border-primary-subtle">Update</Button>
                        <Button onClick={handleDeleteClick(value)} sx={{marginTop:1,borderColor:blueGrey[800],border:"2px", alignItems:"center",textAlign:"center", width:"250px",borderRadius:2,backgroundColor:red[600],color:red[50]}}  className="border border-2 border-danger-subtle">Delete</Button>
                    </Paper>
                })}
                </Box>
            </Grid>
            {
                    loading ? <CommonLoading /> : 
                    modalInOpen && (
                    <ThemeProvider theme={$(".lower-div").attr("data-bs-theme") === "light"? materialThemes.lightTheme : materialThemes.darkTheme}>
                      <CssBaseline />
                      <CrudModal
                        rows={rows}
                        modalInOpen={modalInOpen}
                        currentRowId={currentRowId}
                        tierName={tierName}
                        maxTierUsers={maxTierUsers}
                        maxTierMessageCall={maxTierMessageCall}
                        setTierName={setTierName}
                        setMaxTierUsers={setMaxTierUsers}
                        setMaxTierMessageCall={setMaxTierMessageCall}
                        inEdit={inEdit}
                        isTier={true}
                        isUserGroup={false}
                        inAdd={inAdd}
                        inDelete={inDelete}
                        handleClose={handleClose}
                        addTier={addTier}
                        updateTier={updateTier}
                        deleteTier={deleteTier}
                        inCreateAdmin={inCreateAdmin}
                        setInCreateAdmin={setInCreateAdmin}
                        orgName={orgName}
                        setOrgName={setOrgName}
                        isActive={isActive}
                        setIsActive={setIsActive}
                      />
                    </ThemeProvider>
                  )}
            </>}
            </div></motion.div> 
    </ErrorBoundary>
  );
}
