import * as React from "react";
// import { Routes, Route, useLocation, useNavigate} from 'react-router-dom';
import Notifications from "../../../Notifications/Messages";
import $ from 'jquery';
import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
// import EditIcon from "@mui/icons-material/Edit";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Zoom from "@mui/material/Zoom";
import Tooltip from "@mui/material/Tooltip";
// import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
// import SaveIcon from "@mui/icons-material/Save";
// import CancelIcon from "@mui/icons-material/Close";
// import Checkbox from "@mui/material/Checkbox";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { createTheme, IconButton } from "@mui/material";
import { ThemeProvider, 
  // createTheme 
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {
  // GridRowModes,
  DataGrid,
  // GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
// import {
//   randomCreatedDate,
//   randomTraderName,
//   randomId,
//   randomArrayItem,
// } from "@mui/x-data-grid-generator";
import { motion, 
  // AnimatePresence 
} from "framer-motion";
import { CheckBox } from "@mui/icons-material";
import { useEffect } from "react";
import { 
  // addOrganisation, 
  baseApiCall,
  // deleteOrganisation,
  // getAllOrganisations,
  // updateOrganisation 
} from "../../../API/apiCalls";
// import Commonloader from '../../../Layout/Commonloader';
/*******************  FOR MODAL   ****************************/
// import Typography from "@mui/material/Typography";
// import Modal from "@mui/material/Modal";
// import TextField from '@mui/material/TextField';
// import editOrganisationModal from "../Modals/crudModal";
// import EditOrganisationModal from "../Modals/crudModal";
import CommonVariables from "../../../variables";
import CrudModal from "../Modals/crudModal";
import { Link } from "react-router-dom";
// import EditOrganisation from "./editOrganisation";
import { fallbackRender, materialThemes } from "../../../API/commonFunctions";
import { ErrorBoundary } from "react-error-boundary";
import CommonLoading from "../../../Layout/CommonLoading";
import { blueGrey, red } from "@mui/material/colors";
// import { useDispatch } from "react-redux";
// import { setOrganisations } from '../redux/organisationSlice.js';


// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   height: 200,
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

// const roles = ["Market", "Finance", "Development"];

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

export default function Organisation(props) {
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [currentRowId, setCurrentRowId] = useState();
  // const [currentRow, setCurrentRow] = useState();   
  const [modalInOpen, setModalInOpen] = useState(false)
  const [inEdit, setInEdit] = useState(false)
  const [inAdd, setInAdd] = useState(false)
  const [inDelete, setInDelete] = useState(false)
  const [isUser, setIsUser] = useState(false)
  const [isOrganisation, setIsOrganisation] = useState(true)
  const [inCreateAdmin, setInCreateAdmin] = useState(false)
  const [isUserGroup, setIsUserGroup] = useState(false)
  const [orgName, setOrgName] = useState()
  const [isActive, setIsActive] = useState(false)
  const [loading, setLoading] = useState(false);
  // let dispatch = useDispatch();
  
  useEffect(() => {
    loadOrganisations();
  }, []);

  const loadOrganisations = async () => {
    setLoading(true);
    const result = await baseApiCall(CommonVariables.urls.getAllOrganisationsUrl,{});
    if (!result?.isFailure && result?.isFailure === false && result?.data) {
      // dispatch(setOrganisations(result?.data));
      setRows(result?.data);
      // console.log(rows);
      setLoading(false);
    }
    handleClose();
    setLoading(false);
  };
  // console.log(rows);

  /********************** FRAMER ANIMATION, APPLIES ON CHANGING ROUTE TO THIS COMPONENT **********************/
  // const routeVariants = {
  //   initial: {
  //     transform: "scale(0.3)",
  //     opacity: 0,
  //   },
  //   final: {
  //     transform: "scale(1)",
  //     opacity: 1,
  //   },
  // };
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  //  /********************** FUNCTION EXECUTABLE ON CREATE ADMIN MODAL OPEN **********************/  
  // const handleCreateAdmin = () => {
  //   setModalInOpen(true);
  //   setInCreateAdmin(true);
  // };

    /********************** FUNCTION EXECUTABLE ON EDIT MODAL OPEN **********************/  
  const handleEditClick = (row) => ()=>{
    // console.log(row);
    setCurrentRowId(row.id);
    // console.log(row.id);
    // setLoading(true);
    // const result = await baseApiCall(CommonVariables.urls.getOrganisationByIdUrl,{id: row.id}); //fetching organization by id for Admin details.
    // if (!result?.isFailure && result?.isFailure === false && result.data) {
    //   console.log(result.data);
    //   setLoading(false);
    // }
    handleClose();
    // setLoading(false);
    props.setDataToPassToEdit(row);
    // setOrgName(row.tenantName)
    // setIsActive(row.isActive)
    // setModalInOpen(true);
    // setInEdit(true)
  };

  /********************** FUNCTION EXECUTABLE ON DELETE MODAL OPEN **********************/
  const handleDeleteClick = (row) => async() => {
    setCurrentRowId(row.id);
    setModalInOpen(true);
    setInDelete(true);
  };

  /********************** MANAGING ORGANISATION CRUDS **********************/
  const addOrganisation = async (data)=>{
    setLoading(true);
    const result = await baseApiCall(CommonVariables.urls.addOrganisationUrl,data)
    if(!result?.isFailure && result?.isFailure === false){
      setLoading(false);
      handleClose();
      Notifications.ShowSuccessMessage(result?.message);
      loadOrganisations();
    }
    handleClose();
    setLoading(false);
  }

  const updateOrganisation = async (data)=>{
    const result = await baseApiCall(CommonVariables.urls.updateOrganisationUrl,data)
    if(!result?.isFailure && result?.isFailure === false){
      loadOrganisations();
    }
  }

  const deleteOrganisation =async (data)=>{
    setLoading(true);
    const result = await baseApiCall(CommonVariables.urls.deleteOrganisationUrl,data);
    if(!result?.isFailure && result?.isFailure === false){
      setLoading(false);
      Notifications.ShowSuccessMessage(result?.message);
      handleClose();
      loadOrganisations();
      // setRows(rows.filter((row) => row.id !== data.id));
    }
    handleClose();
    setLoading(false);
  }
  // const createAdmin =async (data)=>{
  //   setLoading(true);
  //   const result = await baseApiCall(CommonVariables.urls.addOrganisationAdminUrl,data);
  //   if(!result?.isFailure && result?.isFailure === false){
  //     setLoading(false);
  //     Notifications.ShowSuccessMessage(result?.message);
  //     handleClose();
  //     // setRows(rows.filter((row) => row.id !== data.id));
  //   }
  //   handleClose();
  //   setLoading(false);
  // }
  
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const CustomCheckBox = ({ value }) => (
    <Tooltip title={value?"Yes":"No"}>
      <IconButton style={{ color: value ? 'green' : 'red' }}>
        {value ? <CheckOutlinedIcon /> : <CloseOutlinedIcon />}
      </IconButton>
    </Tooltip>
  );
  
  const columns = [
    {
      field: "isActive",
      type: "boolean",
      headerName: "Is Active",
      renderCell: (params) => <CustomCheckBox value={params.row.isActive} />,
      width: 100,
      // getActions: (field) => {
      //   return [
      //     <CheckBox/>
      //   ];
      // },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Manage",
      width: 300,
      cellClassName: "actions",
      getActions: ({ row }) => {     
        <CheckBox />;
        return [
          <>
           <ThemeProvider theme={$(".lower-div").attr("data-bs-theme") === "light"? lightTheme : darkTheme}>
            <Tooltip title="Details" arrow  TransitionComponent={Zoom} placement="top" className='ms-5 me-3 text-secondary'>
              <Link to={`/dashboard/organisations/edit/${row.id}`}>
                <GridActionsCellItem
                icon={<OpenInNewIcon sx={{fill:blueGrey[600]}} />}
                label="Edit"
                className="textPrimary text-secondary "
                color="inherit"
                />
              </Link>
            </Tooltip>
            <Tooltip title="Delete" arrow  TransitionComponent={Zoom} placement="top" className='mx-5 text-secondary'>
              <GridActionsCellItem
              icon={<DeleteIcon sx={{fill:red[500]}} />}
              label="Delete"
              className="textPrimary text-secondary mx-5 "
              onClick={handleDeleteClick(row)}
              color="inherit"
              />
            </Tooltip>
            </ThemeProvider>
          </>
        ];
      },
    },
    { field: "tenantName", headerName: "Name", width: 250, editable: false },
    {
      field: "createdDate",
      headerName: "Created On",
      type: "date",
      valueGetter: ({ value }) => value && new Date(value),
      width: 250,
      align: "left",
      headerAlign: "left",
      editable: false,
    },
  ];
  
/********************** FUNCTION EXECUTABLE ON MODAL CLOSE **********************/
  const handleClose = () => {
    setModalInOpen(false);
    setOrgName("");
    setIsActive(false);
    setInAdd(false);
    setInEdit(false);
    setInDelete(false);
    setInCreateAdmin(false);
  }

/********************** FUNCTION EXECUTABLE WHILE ADDING NEW RECORD **********************/
  const handleInAdd = () => {
    setInAdd(true);
    setModalInOpen(true);
  }
  
  return (
    <ErrorBoundary
    fallbackRender={fallbackRender}
    onReset={() => {
      // Reset the state of your app so the error doesn't happen again
    }}
    >
        <CssBaseline />
          <motion.div   
            sx={{ transition: "none !important" }}
            animate="final"
            className="for-transition p-4 grids  overflow-auto h-100"
          >
            <div
              className="for-transition w-100 card border h-100 border-1 border-color-css rounded-3 shadow"
              style={{}}
            >
              { loading ? <CommonLoading /> :
                <>
                  <div className="fs-3 pt-4 px-3 mb-2 fw-semibold main-text text-center">
                    Manage Organisation
                  </div>
                  <div className="d-flex justify-content-between ">
                    <Button
                      // color="primary"xxxxxx
                      variant="contained"
                      style={{minWidth:"9rem",maxWidth:"12rem"}}
                      className="p-2 m-3 ms-4 mb-0 button-color"
                      startIcon={<AddIcon />}
                      onClick={handleInAdd}          
                      >
                      Add record
                    </Button>
                    {/* <Button 
                      color="primary"
                      variant="contained"
                      style={{minWidth:"8rem",maxWidth:"10rem"}}
                      className="p-2 border m-3 mb-0 border-1 border-primary text-light my-auto"
                      onClick={handleCreateAdmin}        
                      >
                      Create Admin
                    </Button> */}
                  </div>
                  <Box
                    className=""
                    sx={{
                      minHeight:520,
                      height: "68vh",
                      fontWeight: "bold",
                      padding: "15px",
                      transition: "none !important",
                      "& .actions": {
                        color: "text.secondary",
                      },
                      "& .textPrimary": {
                        color: "text.primary",
                      },
                    }}
                  >
                    <DataGrid
                      className="text-body-secondary"
                      rows={rows}
                      style={{ transition: "none !important" }}
                      sx={{ transition: "none !important" ,maxHeight:"68vh",minHeight:"52vh"  }}
                      columns={columns}
                      editMode="row"
                      rowModesModel={rowModesModel}
                      onRowModesModelChange={handleRowModesModelChange}
                      onRowEditStop={handleRowEditStop}
                      slotProps={{
                        toolbar: { setRows, setRowModesModel },
                      }}
                      disableRowSelectionOnClick
                    />  
                  </Box>
                  {
                    loading ? <CommonLoading /> : 
                    modalInOpen && (
                    <ThemeProvider theme={$(".lower-div").attr("data-bs-theme") === "light"? materialThemes.lightTheme : materialThemes.darkTheme}>
                      <CssBaseline />
                      <CrudModal
                        rows={rows}
                        modalInOpen={modalInOpen}
                        currentRowId={currentRowId}
                        inEdit={inEdit}
                        isUser={isUser}
                        isOrganisation={isOrganisation}
                        isUserGroup={isUserGroup}
                        inAdd={inAdd}
                        inDelete={inDelete}
                        handleClose={handleClose}
                        addOrganisation={addOrganisation}
                        updateOrganisation={updateOrganisation}
                        deleteOrganisation = {deleteOrganisation}
                        // createAdmin={createAdmin}
                        inCreateAdmin={inCreateAdmin}
                        setInCreateAdmin={setInCreateAdmin}
                        orgName={orgName}
                        setOrgName={setOrgName}
                        isActive={isActive}
                        setIsActive={setIsActive}
                      />
                    </ThemeProvider>
                  )}
                </>
              }
            </div>
          </motion.div> 
    </ErrorBoundary>
  );
}
