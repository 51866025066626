import React from 'react';
// import $ from 'jquery'
// import Navbar from '../Dashboard/components/Navbar/Navbar';
// import Notifications from '../Notifications/Messages';
import { useState } from 'react';
import CommonVariables from '../variables';
import Home from '../Dashboard/components/Home/Home';
import { getDomain } from '../domains';
// import CommonVariables from '../variables';
import askHr from '../images/AskHrlogo.png';


function Dashboardlayout(props) {
    const isAuthenticated = localStorage.getItem('token')? true:false;
    const [leftNavToggle, setLeftNavToggle] = useState(false);
    const [theme, setTheme] = useState(true);
    const domain = getDomain(window.location.hostname);
    // function sideMenuToggle(){
    //     setLeftNavToggle(!leftNavToggle)
    // }
    let redirectUrl = `http://${domain}`;
    if(domain === 'localhost') {
        redirectUrl = `http://${domain}:${CommonVariables.port2}`;
    }
    const handleClick = () => {
        window.location.href=redirectUrl;
    }
    // console.log(theme);
    return (
        
        <>
            {isAuthenticated?
                <div>
                    {/* <Navbar leftNavToggle={leftNavToggle} sideMenuToggle = {sideMenuToggle} setTheme={setTheme}/> */}
                    <Home  leftNavToggle = {leftNavToggle} onLoaderRaise={props.onLoaderRaise} theme={theme}/>
                </div> : 
                <div className='d-flex flex-column align-items-center justify-content-center h-100 w-100' style={{minHeight: '500px', "background":" linear-gradient(90deg, rgb(241, 172, 236) 0%, rgb(202, 173, 240) 34%, rgb(171, 220, 237) 62%, rgb(148, 207, 231) 89%)"}}>
                    <img src={askHr} alt='askHr' style={{height: '300px', width: '300px'}}></img>
                    <div className='text-danger fs-2'>Uh oh! You're not authorized.</div>
                    <div className='text-primary text-decoration-underline' onClick={handleClick}
                        style={{ cursor: 'pointer' }}
                    >
                        Go back to login
                    </div>
                </div>
            }

        </>
    );
}

export default Dashboardlayout;