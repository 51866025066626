import React, { useState } from 'react';
// import Routing from './Routing';
// import { store } from './Dashboard/components/redux/store'
// import { Provider } from 'react-redux'
// import { BrowserRouter } from 'react-router-dom';
import "./App.css";
import { getApp } from './domains';
import { useEffect } from 'react';
import { HandleSubdomain } from './domains';
import CommonLoading from './Layout/CommonLoading';
import { useNavigate } from 'react-router';
import { LocalizationProvider} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const App = () => {
  const [loading, setLoading] = useState(false);
  const navigate=useNavigate();
  const CurrentRoute = getApp();
  const changeSubdomain = async() => {
    setLoading(true);
    await HandleSubdomain();
    const path=window.location.pathname;
    navigate(path.split('?')[0]);
    setLoading(false);
  }
  useEffect(()=>{
    changeSubdomain();
  },[]);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div style={{"height":"100vh" ,"background":"linear-gradient(90deg, rgb(241, 172, 236) 0%, rgb(202, 173, 240) 34%, rgb(171, 220, 237) 62%, rgb(148, 207, 231) 89%)"}}>

          { loading? <CommonLoading /> :
            <CurrentRoute />
          }
      </div>
      </LocalizationProvider>
  )
}

export default App;