import APIConnector from "../apiconnector";
import CommonVariables from "../variables";
import $ from 'jquery';
import Notifications from "../Notifications/Messages.js";
// import image  from "../images/icons8-book.gif";


async function generateAnswer(question, sessionId) {
        return new Promise((resolve, reject) => {
        const data = { userId: localStorage.getItem("userId"), question: question, sessionId: sessionId};
        // var responseMessageDiv = $("<div>");
        // responseMessageDiv.addClass("response-message-space col-sm-12");        
        // responseMessageDiv.append("<img src='/static/media/icons8-book.0b641cebdf113a27e596.gif' alt='book icon' class='animated-book-icon ml-2' width='40' height='50'  style='background-color:transparent;margin-left:100px'/>")
        // $(".mesasages-div").append(responseMessageDiv)
        //  $("#div-to-scroll").scrollTop($("#div-to-scroll")[0].scrollHeight);
        APIConnector.Post(CommonVariables.urls.chatUrl, data, CommonVariables.getlocalstorageData().token).then(function (response) {
            //props.onLoaderRaise(false);
            if (!response || response.status == 500 || (response.isFailure && response.message)) {
                $(".animated-book-icon").css("display","none");
                Notifications.ShowErrorMessage(response.message ? response.message : "Server didn't respond, Please try again.");
                return;
            } else {
                $(".animated-book-icon").css("display","none");
                resolve(response);
            }
        })
        .catch((error) => {
            Notifications.ShowErrorMessage("Server Didn't respond, Please try again.");
            reject(error);
        });

    });
}

async function loadChat(sessionId) {
    return new Promise((resolve, reject) => {
        APIConnector.Post(CommonVariables.urls.getSessionById, {id: sessionId}, CommonVariables.getlocalstorageData().token).then(function (response) {
            //debugger;
            //props.onLoaderRaise(false);
            if (response.isFailure) {
                Notifications.ShowErrorMessage(response.message ? response.message : "Something went wrong.Please try again.");
                reject(response);
            }
            else if (!(response.isFailure) && response.data) {
                // Notifications.ShowSuccessMessage(response.message && response.message);
                resolve(response);
            }
            else {
                Notifications.ShowErrorMessage("Something went wrong.Please try again.");
                resolve();
            }
        })
        .catch((error) => {
            Notifications.ShowErrorMessage("Server Didn't respond, Please try again.");
            reject(error);
        });
        
    });
}

async function getSessions() {
    return new Promise((resolve, reject) => {
        const data = {
            id: localStorage.getItem("userId")
        };

        APIConnector.Post(CommonVariables.urls.getSessionsUrl, data, CommonVariables.getlocalstorageData().token)
            .then(function (response) {
                if (!response || !(response.isSuccess)) {
                    const errorMessage = response.message || "Something went wrong. Please try again.";
                    Notifications.ShowErrorMessage(errorMessage);
                    reject(errorMessage);
                } else {
                    resolve(response.sesions);
                }
            })
            .catch((error) => {
                Notifications.ShowErrorMessage(error);
                reject(error);
            });
    });

}

async function createSession(sessionName) {
    
    const data = { id: localStorage.getItem("userId"), sessionName: sessionName };
    var sessionsForThisUser = await getSessions();
    if (sessionsForThisUser.length >= 10) {
        Notifications.ShowWarningMessage("You have exceeded the limit of max 10 sessions.");
        return(false);
    }
    return new Promise((resolve, reject) => {
        APIConnector.Post(CommonVariables.urls.createSessionUrl, data, CommonVariables.getlocalstorageData().token).then(function (response) {
            //props.onLoaderRaise(false);
            if (!response || !(response.isSuccess)) {
                Notifications.ShowErrorMessage(response.message ? response.message : "Something went wrong.Please try again.");
                reject(false);
            }
            changeCurrentSession(sessionName, response.session_Id);
            resolve(true);
        });
    });
}

function changeCurrentSession(sessionName, sessionId) {
    localStorage.setItem("currSessionId", sessionId ? sessionId : "");
    localStorage.setItem("currSessionName", sessionName ? sessionName : "");
}

async function getFileNames() {
    return new Promise((resolve, reject) => {
        const data = {
            id: localStorage.getItem("userId"),
            sessionId: localStorage.getItem("currSessionId")
        };
        APIConnector.Post(CommonVariables.urls.getSessionFilesUrl, data, CommonVariables.getlocalstorageData().token)
            .then(function (response) {
                if (!response || !(response.isSuccess)) {
                    const errorMessage = response?.message || "Something went wrong. Please try again.";
                    Notifications.ShowErrorMessage(errorMessage);
                    reject(errorMessage);
                } else {
                    resolve(response.list_FileNames);
                }
            })
            .catch((error) => {
                Notifications.ShowErrorMessage(error);
                reject(error);
            });
    });
}

async function deleteSession(sessionId) {
    return new Promise((resolve, reject) => {
        const data = {
            id: localStorage.getItem("userId"),
            sessionId: sessionId
        };
        APIConnector.Post(CommonVariables.urls.deleteSessionUrl, data, CommonVariables.getlocalstorageData().token)
            .then(function (response) {
                if (!response || !(response.isSuccess)) {
                    const errorMessage = response?.message || "Something went wrong. Please try again.";
                    Notifications.ShowErrorMessage(errorMessage);
                    resolve(response.isSuccess);
                } else {
                    resolve(response.isSuccess);
                }
            })
            .catch((error) => {
                Notifications.ShowErrorMessage(error);
                reject(error);
            });
    });
}

async function renameSession(sessionId, sessionName) {
    return new Promise((resolve, reject) => {
        const data = {
            id: localStorage.getItem("userId"),
            sessionId: sessionId,
            sessionName: sessionName
        };
        APIConnector.Post(CommonVariables.urls.renameSessionUrl, data, CommonVariables.getlocalstorageData().token)
            .then(function (response) {
                if (!response || !(response.isSuccess)) {
                    const errorMessage = response?.message || "Something went wrong. Please try again.";
                    Notifications.ShowErrorMessage(errorMessage);
                    reject(response.isSuccess);
                } else {
                    resolve(response.isSuccess);
                }
            }).catch((error) => {
                Notifications.ShowErrorMessage(error);
                reject(error);
            });
    });
}

 function formatTimestamp(time) {
    const date = time ? new Date(time) : new Date();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;

    const formattedHoursStr = String(formattedHours).padStart(2, '0');
    const minutesStr = String(minutes).padStart(2, '0');

    const formattedTime = `${formattedHoursStr}:${minutesStr} ${ampm}`;
    return formattedTime;
}

function getFileNameFromPath(filePath) {
    // Use the lastIndexOf method to find the last occurrence of the backslash (\)
    // Path ex: "C:\\publish\\BookMate\\BookMateAPI\\64d1e5cad476d83cfb3f6829\\0a4697e1-685c-47e8-be01-fa5acc8492df\\PDFs\\Marvel.pdf"
    const lastBackslashIndex = filePath.lastIndexOf("\\");

    // Check if a backslash was found
    if (lastBackslashIndex !== -1) {
        // Use substring to extract the file name starting from the last backslash + 1
        const fileName = filePath.substring(lastBackslashIndex + 1);
        return fileName;
    } else {
        // If no backslash is found, return the original path
        return filePath;
    }
}

async function checkAuthentication(currentPath,role, organisation,token) {
    return new Promise((resolve, reject) => {
        // console.log(currentPath);
        currentPath.substr(currentPath.length - 1) === "/"?currentPath = currentPath.slice(0, -1):currentPath = currentPath;
        // console.log(localStorage.getItem("userId") && localStorage.getItem("token"));
        // console.log(currentPath);
        // console.log(role === 'TenantAdmin');
        //debugger;
        if(token){
                //debugger;
                if(role === 'SuperAdmin' && (currentPath === `/dashboard/organisations` || currentPath === `/dashboard/profile`  || currentPath === `/dashboard/organisations/edit/${organisation}` || currentPath === `/dashboard/organisations/edit/admin/${organisation}`)){
                    resolve(true)
                }
                else if(role === 'TenantAdmin'  && (currentPath === `/dashboard/users` || currentPath === `/dashboard/files` || currentPath === `/dashboard/profile` || currentPath === `/dashboard/groups` || currentPath === `/dashboard/database`)){
                    resolve(true)
                }
                else if(role === 'TenantUser' &&  (currentPath === `/dashboard` || currentPath === `/dashboard/chat/${organisation}` || currentPath === `/dashboard/profile`)){
                    resolve(true)
                }
                else{
                    resolve(false)
                }
        }else{
            resolve(false);
        }
    });
}

const baseApiCall= (url,data)=>{
    return new Promise((resolve,reject)=>{
        APIConnector.Post(url,data,CommonVariables.getlocalstorageData().token).then(function(response){
            response.isSuccess = false;
            if (response.customMessage) {
                Notifications.ShowErrorMessage(response.customMessage)
                resolve(response)
            }
            else if(response.isFailure){
                Notifications.ShowErrorMessage(response.message)
            }
            response.isSuccess = true;
            resolve(response)
        })
    })
}
// const getChatHistory  = "f";
export {
    generateAnswer,
    loadChat,
    createSession,
    getSessions,
    changeCurrentSession,
    getFileNames,
    deleteSession,
    renameSession,
    // getChatHistory,
    formatTimestamp,
    checkAuthentication,
    getFileNameFromPath,
    baseApiCall
};