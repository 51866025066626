import React, 
  { useState } from "react";
import "./Home.css";
import $ from 'jquery';
// import book from "../../../images/icons8-book.gif";
// import { Link, useLocation } from "react-router-dom";
// import APIConnector from "../../../apiconnector";
// import CommonVariables from "../../../variables";
// import AllRoutes from "../../../Routing/AllRoutes";
// import { useNavigate } from "react-router";
// import $ from "jquery";
// import Notifications from "../../../Notifications/Messages.js";
import { ErrorBoundary } from "react-error-boundary";
import { fallbackRender, materialThemes } from '../../../API/commonFunctions';
import { useNavigate } from "react-router";
// import {
//   generateAnswer,
//   getChatHistory,
//   createSession,
//   getSessions,
//   changeCurrentSession,
//   getFileNames,
//   deleteSession,
//   renameSession,
//   formatTimestamp,
//   getFileNameFromPath
// } from "../../../API/apiCalls";
import { 
  ThemeProvider, 
  createTheme 
  } from '@mui/material/styles';
import { CssBaseline } from "@mui/material";
import { motion } from "framer-motion";
import { baseApiCall } from "../../../API/apiCalls";
import CommonVariables from "../../../variables";
import Notifications from "../../../Notifications/Messages";
import CommonSpinner from "../../../Layout/CommonSpinner";
import CommonLoading from "../../../Layout/CommonLoading";
import Sessions from "../Chat/sessions";

/**
 *
 * This component renders the home page of internal dashboard
 *
 */

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

function MainScreen(props) 
{

  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();

  const createNewSession = async(chatSessionName)=>{
    setLoading(true);
    const result = await baseApiCall(CommonVariables.urls.createSessionUrl,{sessionName:chatSessionName, id:localStorage.getItem("userId")});
    if (!result?.isFailure && result?.isFailure === false && result.data) {
      Notifications.ShowSuccessMessage(result.message);
      props.setLoadSession(!props.loadSession);
      await askQuestion(chatSessionName,result?.data.id)
    };
    setLoading(false);
  }
  const askQuestion = async (question,sessionId)=>{
    const result = await baseApiCall(CommonVariables.urls.chatUrl,{sessionId:sessionId,question:question, userId:localStorage.getItem("userId")});
    if (!result?.isFailure && result?.isFailure === false && result.data) {
      navigate('/dashboard/chat/'+sessionId);
    };
  }
  return (
    <ErrorBoundary
      fallbackRender={fallbackRender}
      onReset={(details) => {
          // Reset the state of your app so the error doesn't happen again
      }}
    >
      <ThemeProvider theme={$(".lower-div").attr("data-bs-theme") === "light"? lightTheme : darkTheme}>
        <CssBaseline />
        <motion.div 
          animate="final" 
          className="for-transition text-center grids p-4 h-100 overflow-auto"
          sx={{ transition: "none !important" }}
        >
          <div
            className="for-transition w-100 card border h-100 border-1 border-color-css rounded-3 shadow"
            style={{}}
          >{ loading? <CommonLoading/> :
            <div
              id="div-to-scroll"
              className="d-flex flex-column col-sm div-to-scroll overflow-x-hidden align-items-center"
            >
              <h3 className="welcome-text text-center mt-3 p-2 mb-1 border-0 border-black rounded-3 text-body-secondary">
                Welcome to askHR!
              </h3>
              <h6 className="welcome-text text-center mt-1 p-2 border-0 border-black rounded-3 text-body-secondary">
                Start chatting with your HR by diving back in to a previous session, and start where you left off or by creating a new session!
              </h6>
              <h5 className="text-center mt-1 p-2 border-0 border-black rounded-3 text-body-secondary mt-5 pt-5">
                You can start by asking ..
              </h5>
              {/* <h5 className="text-center mt-1 p-2 mb-5 border-0 border-black rounded-3 text-body-secondary">
                
              </h5> */}
              <div className="d-flex col-8 align-items-center justify-content-between flex-wrap mt-3">
                <div type="submit" onClick={()=>createNewSession("Can you summarize the document for me?")} className="d-flex col-5 bg-dark p-3 m-2 chat-main-page rounded-2 text-light align-items-center justify-content-center">
                  <p>Can you summarize the document for me?</p>
                </div>
                <div type="submit" onClick={()=>createNewSession("What is there in page 31 of the document?")} className="d-flex col-5 p-3 m-2 rounded-2 chat-main-page bg-dark text-light align-items-center justify-content-center">
                  <p>What is there in page 31 of the document?</p>
                </div> 
                <div type="submit" onClick={()=>createNewSession("What is this document about?")} className="d-flex col-5 p-3 m-2 rounded-2 chat-main-page bg-dark text-light align-items-center justify-content-center">
                  <p>What is this document about?</p>
                </div>
                <div type="submit" onClick={()=>createNewSession("What is the name of this document?")} className="d-flex col-5 p-3 m-2 rounded-2 chat-main-page bg-dark text-light align-items-center justify-content-center">
                  <p>What is the name of this document?</p>
                </div>           
              </div>          
            </div>}
          </div>
        </motion.div>
      </ThemeProvider>
    </ErrorBoundary>
  );
}
export default MainScreen;
