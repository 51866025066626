import './spinner.css'

const CommonSpinner = () => {
  return (
    // <div className='spinner' aria-label='spinner-icon'>
    //   <div className='spinner-circle'></div>
    // </div>
    
    <div style={{top:"7rem"}} className='loader d-flex justify-content-center position-relative bg-black'>
        <div className="spinner-border text-body-secondary position-absolute" style={{width: "3rem", height: "3rem",top:"50%"}} role="status">
  <span className="visually-hidden">Loading...</span>
</div>
</div>
  )
}

export default CommonSpinner;