import React ,{useState} from 'react';
import $ from 'jquery';
import Sidebar from './sidebar';
import { Routes, Route, useLocation } from 'react-router-dom';
import MainScreen from './mainScreen';
import ChatComp from '../Chat/chatComp';
import Organisations from '../organisation/organisations';
import Files from '../Files/files';
import Users from '../User/users';
import CombinedPage from '../User/CombinedPage'
import  "../../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min"
// import { useSelector } from 'react-redux';
// import { checkAuthentication } from '../../../API/apiCalls';
// import Notifications from '../../../Notifications/Messages';
import Profile from '../profile/profile';
import { AnimatePresence } from "framer-motion";
import UserGroups from '../Roles/userGroups';
import UserGroupFiles from '../Roles/userGroupFiles';
import UserGroupUsers from '../Roles/userGroupUsers';
import EditOrganisation from '../organisation/editOrganisation';
import EditOrganisationAdmin from '../organisation/EditOrganisationAdmin';
import Database from '../Database/database';    
import { ErrorBoundary } from "react-error-boundary";
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { fallbackRender, materialThemes } from '../../../API/commonFunctions';
// import CommonVariables from '../../../variables';
// import { getSubDomain } from '../../../domains';
// import { useParams } from 'react-router-dom/dist';
import ErrorPage from '../ErrorPage';
// import { HandleSubdomain } from '../../../domains';
// import CommonLoading from '../../../Layout/CommonLoading';
import Navbar from '../Navbar/Navbar';
import Tiers from '../tiers/tiers';
function Home(props) {
  // const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem('token')? true:false;
  const [editOrganisation,setEditOrganisation] = useState();
  const [editTiers,setEditTiers] = useState();
  const [theme, setTheme] = useState(true);
  const [loadSession, setLoadSession] = useState(false);
  const [leftNavToggle, setLeftNavToggle] = useState(false);
  const [editOrganisationAdmin,setEditOrganisationAdmin] = useState();
  const isSuperAdmin = localStorage.getItem('userRole').toLocaleLowerCase()==='superadmin'? true:false;
  const isOrgAdmin = localStorage.getItem('userRole').toLocaleLowerCase()==='tenantadmin'? true:false;
  const isOrgUser = localStorage.getItem('userRole').toLocaleLowerCase()==='tenantuser'? true:false;
  const location = useLocation();
  // const [loading, setLoading] = useState(false);
  // const subDomain = getSubDomain(window.location.hostname);
  // console.log(props.theme);
  const themeChoosen = theme? "dark": "light";
  function sideMenuToggle(){
    setLeftNavToggle(!leftNavToggle);
  }

  return (
    <>
      {isAuthenticated &&
        // <ThemeProvider theme={$(".lower-div").attr("data-bs-theme") === "light"? materialThemes.lightTheme : materialThemes.darkTheme}>
        <ThemeProvider theme={theme? materialThemes.lightTheme : materialThemes.darkTheme}>
          <CssBaseline />
          <div className='for-transition w-100 d-flex lower-div mb-0 position-relative' data-bs-theme={!theme?"dark":"light"}>
            <Sidebar setTheme={setTheme} theme={theme} leftNavToggle = {leftNavToggle} sideMenuToggle = {sideMenuToggle} loadSession={loadSession} setLoadSession={setLoadSession}/>
            <div className='w-100'>
              <Navbar setTheme={setTheme} leftNavToggle={leftNavToggle} sideMenuToggle = {sideMenuToggle}/>
              <div className={`for-transition w-100 ${theme?"main-background":"main-background-dark"}  overflow-auto h-100 bg-primary-subtle`}>
                <AnimatePresence>
                  <ErrorBoundary
                    fallbackRender={fallbackRender}
                    onReset={(details) => {
                      setEditOrganisation({})
                      // Reset the state of your app so the error doesn't happen again
                    }}
                  >
                    {/* {loading? <CommonLoading /> : */}
                      <Routes>
                      {isOrgUser && 
                        <>
                          <Route exact path='/dashboard' element={<MainScreen onLoaderRaise={props.onLoaderRaise} loadSession={loadSession} setLoadSession={setLoadSession}  />} />
                          <Route exact path='/dashboard/chat/:sessionId' element={<ChatComp onLoaderRaise={props.onLoaderRaise} theme={theme} currentTheme={themeChoosen}/>} />
                        </>
                      }
                      <Route exact path='/dashboard/profile' element={<Profile onLoaderRaise={props.onLoaderRaise} />} />
                      {isSuperAdmin &&
                        <>
                          <Route exact path='/dashboard/organisations' element={<Organisations onLoaderRaise={props.onLoaderRaise} setDataToPassToEdit={setEditOrganisation} theme={theme} />} />
                          <Route exact path='/dashboard/tiers' element={<Tiers onLoaderRaise={props.onLoaderRaise} setDataToPassToEdit={setEditTiers} theme={theme} />} />
                          <Route exact path='/dashboard/organisations/edit/:organisationId' element={<EditOrganisation onLoaderRaise={props.onLoaderRaise} editOrganisation={editOrganisation} setDataToPassToEditAdmin={setEditOrganisationAdmin} theme={theme} />} />
                          <Route exact path='/dashboard/organisations/edit/admin/:adminId' element={<EditOrganisationAdmin onLoaderRaise={props.onLoaderRaise} editOrganisationAdmin={editOrganisationAdmin}/>} />
                        </>
                      }
                      {isOrgAdmin && 
                        <>
                          <Route exact path='/dashboard/users' element={<Users onLoaderRaise={props.onLoaderRaise} />} />
                          <Route exact path='/dashboard/groups' element={<UserGroups onLoaderRaise={props.onLoaderRaise}  />} />
                          <Route exact path='/dashboard/group/users/:userGroupId' element={<UserGroupUsers onLoaderRaise={props.onLoaderRaise} />} />
                          <Route exact path='/dashboard/group/files/:userGroupId' element={<UserGroupFiles onLoaderRaise={props.onLoaderRaise} />} />
                          <Route exact path='/dashboard/files' element={<Files onLoaderRaise={props.onLoaderRaise} />} />
                          <Route exact path='/dashboard/database' element={<Database onLoaderRaise={props.onLoaderRaise} />} />
                          <Route exact path='/dashboard/combinedpage' element={<CombinedPage onLoaderRaise={props.onLoaderRaise} />} />
                        </>
                      }
                      <Route exact path='*' element={<ErrorPage onLoaderRaise={props.onLoaderRaise}/>} />
                      {/* <Route exact path='/dashboard/profile/*' element={<Profile onLoaderRaise={props.onLoaderRaise} />} /> */}
                      </Routes>
                    {/* } */}
                  </ErrorBoundary>
                </AnimatePresence>
              </div>
            </div>            
          </div>
        </ThemeProvider>
      }
    </>
  )
}

export default Home;