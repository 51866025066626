import React, { 
    useState,
    useEffect
 } from 'react';
import { motion,
    //  AnimatePresence 
    } from "framer-motion";
import { baseApiCall } from '../../../API/apiCalls';
import CommonVariables from '../../../variables';
// import Commonloader from '../../../Layout/Commonloader';
import CommonLoading from '../../../Layout/CommonLoading';

function Profile(props) {
    const [loading, setLoading] = useState();
    const [userData, setUserData] = useState();
    // const [inEdit, setInEdit] = useState(false);
    const routeVariants = {
        initial: {
            transform:"scale(0.3)",
            opacity:0
        },
        final: {
            transform:"scale(1)",
            opacity:1
        }
    }
    // const handleEdit = () => {
    //     setInEdit(true);
    // }
    const getUserById = async()=>{
        setLoading(true);
        const result = await baseApiCall(CommonVariables.urls.getUserByIdUrl,{id: localStorage?.getItem('userId')});
        if(!result?.isFailure && result?.isFailure === false){
            console.log(result?.data);
            setUserData(result?.data);
            setLoading(false);
        }
        setLoading(false);
    }
    useEffect(()=>{
        getUserById();
    },[]);
    return (
        <motion.div
            sx={{ transition: "none !important" }}
            animate="final"
            className="for-transition p-4 grids text-secondary overflow-auto h-100"
        >
            <div
                className="for-transition p-5 w-100 card border h-100 border-1 border-color-css rounded-3 shadow "
                style={{}}
            >     
                {loading? <CommonLoading /> :
                    <div className='d-flex w-100 justify-content-between'>
                        <div className='d-flex flex-column gap-1 align-items-start'>
                            <h4 className="text-body-secondary px-2 my-3 d-inline fw-bold">User Profile</h4>
                            <div className='d-flex align-items-center'>
                                <span className='material-symbols-outlined mx-1 text-body-secondary'>business</span>
                                <label className='text-body-secondary fs-6 fw-bold p-2'>{userData?.tenantDetails?.name}</label>            
                            </div>
                            <div className='d-flex align-items-center'>
                                <span className="material-symbols-outlined mx-1 text-body-secondary">person</span>
                                <label className='text-body-secondary fs-6 fw-semibold p-2'>{userData?.firstName + " " + userData?.lastName}</label>
                            </div>
                            <div className='d-flex align-items-center'>
                                <span className="material-symbols-outlined mx-1 text-body-secondary">mail</span>
                                <label role="button" className='text-body-secondary fs-6 fw-semibold p-2 text-decoration-underline'><a href="mailto:organisation@company.com" className='text-body-secondary'>{userData?.userEmail}</a> </label>
                            </div>
                        </div>
                        {/* <div className=''>
                            <button className="btn btn-primary m-md-0 m-1" onClick={handleEdit}><span className="material-symbols-outlined text-light fs-6 text-decoration-underline pe-1">edit</span>Edit</button>
                        </div> */}
                    </div>
                }   
            </div>
        </motion.div>
    );
}

export default Profile;