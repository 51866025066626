import * as React from "react";
import Notifications from "../../../Notifications/Messages";
import $ from 'jquery';
import { useState } from "react";
import Box from "@mui/material/Box";
import { Button, Divider, Grid, Paper, styled } from "@mui/material";
import {
  ThemeProvider,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {
  motion,
} from "framer-motion";
import { useEffect } from "react";
import {
  baseApiCall,
} from "../../../API/apiCalls";
import CommonVariables from "../../../variables";
import { fallbackRender, materialThemes } from "../../../API/commonFunctions";
import { ErrorBoundary } from "react-error-boundary";
import "../tiers/tiers.css";
import CommonLoading from "../../../Layout/CommonLoading";
import CheckIcon from '@mui/icons-material/Check';
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import LicenceModal from "./licenceModal/licenceModal";
import { Delete, Scale } from "@mui/icons-material";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';


export default function License(props) {
  console.log(props);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedLicenseId, setSelectedLicenseId] = useState();
  const [tenantId, setTenantId] = useState(props.tenantId);
  const [startDate, setStartDate] = useState(new Date().toISOString());
  const [endDate, setEndDate] = useState(new Date().toISOString());
  const [liecenseId, setLicenseId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [inDelete, setInDelete] = useState(false);
  const [inAdd, setInAdd] = useState(false);
  const [inUpdate, setInUpdate] = useState(false);
  const [loadPage, setLoadPage] = useState(false);
  const [usedMessage,setUsedMessage] = useState("");
  const [assignedUserCount,setAssignedUserCount] = useState("");

  const requestData = {
    tenantID: tenantId,
    startDate: startDate,
    endDate: endDate,
    tier: {
      id: selectedLicenseId,
    }
  }

  useEffect(() => {
    getLicense();
    loadLicenses();
  }, [loadPage]);

  const loadLicenses = async () => {
    const result = await baseApiCall(CommonVariables.urls.getAllTiersUrl, {});
    if (!result?.isFailure && result?.isFailure === false && result?.data) {
      setRows(result?.data);
      // console.log(rows);
    }
  };

  const selectLicense = async () => {
    const result = await baseApiCall(CommonVariables.urls.assignLicenseUrl, requestData);
    if (!result?.isFailure && result?.isFailure === false) {
      setLicenseId(selectedLicenseId);
      Notifications.ShowSuccessMessage(result?.message);
    }
  }


  const getLicense = async () => {
    setLoading(true);
    const result = await baseApiCall(CommonVariables.urls.getLicenseUrl, requestData);
    if (!result?.isFailure && result?.isFailure === false && result?.data) {
      setLicenseId(result?.data?.tier?.id);
      setStartDate(result?.data?.startDate);
      setEndDate(result?.data?.endDate);
      setUsedMessage(result?.data?.used_Message_Count);
      setAssignedUserCount(result?.data?.used_Users_Count);
    }
    handleClose();
  }


  const ValidationUpdateLicence = (data) => {
    setOpenModal(true);
    setSelectedLicenseId(data.id);
    if (liecenseId) {
      setInUpdate(true);
    }
    else {
      setInAdd(true);
    }

  }

  const ValidationDeleteLicence = (data) => {
    setSelectedLicenseId(liecenseId);
    setInDelete(true);
    setOpenModal(true);

  }

  const updateLicense = async () => {
    //console.log(data);

    const result = await baseApiCall(CommonVariables.urls.updateLicenseUrl, requestData);
    if (!result?.isFailure && result?.isFailure === false) {
      setLicenseId(selectedLicenseId);
      Notifications.ShowSuccessMessage(result?.message);
    }
  }

  const deleteLicense = async () => {
    const result = await baseApiCall(CommonVariables.urls.deleteLicenseUrl, { tenantID: tenantId });
    if (!result?.isFailure && result?.isFailure === false) {
      Notifications.ShowSuccessMessage(result?.message);
      setLicenseId(null);
      setLoadPage(!loadPage);
    }
  }


  const handleClose = () => {
    setInAdd(false);
    setInDelete(false);
    setInUpdate(false);
    setOpenModal(false);
    setLoading(false);
    setSelectedLicenseId("");
  }

  return (
    <ErrorBoundary
      fallbackRender={fallbackRender}
      onReset={() => {
      }}
    >
      <ThemeProvider theme={materialThemes.lightTheme}>
        <CssBaseline />
        
        <motion.div
          sx={{ transition: "none !important" }}
          animate="final"
          className="for-transition p-4 grids h-100"
        >{loading ? <CommonLoading /> : 
          <div className="">
          <Button  color="error" className="float-end me-4 top-0 left-0" variant="contained" onClick={()=>ValidationDeleteLicence()} startIcon={<Delete/>}>Remove Licence</Button>
          {openModal && <>
            <LicenceModal
              setOpenModal={setOpenModal}
              openModal={openModal}
              startDate={startDate}
              endDate={endDate}
              inDelete={inDelete}
              inAdd={inAdd}
              inUpdate={inUpdate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              updateLicense={updateLicense}
              deleteLicense={deleteLicense}
              selectLicense={selectLicense}
              handleClose={handleClose}
            />
          </>
          }
          <div className="">
          <Grid container spacing={4}>
            <Box
              sx={{
                p: 4,
                width: "100%",
                height: "80%",
                borderRadius: 4,
                bgcolor: 'transparent',
                display: 'grid',
                gridTemplateColumns: { md: '1fr 1fr 1fr' },
                gap: 4,
              }}
              className="pt-0"
            >
              {rows.map((value) => {
                return <div onClick={() => ValidationUpdateLicence(value)} ><Paper elevation={3} sx={{
                  width: "90%",
                  maxWidth:"22rem",
                  height: "450px",
                  margin: 5,
                  paddingTop: 2,
                  cursor:"pointer",
                  alignItems: "center",
                  textAlign: 'center',
                  borderRadius: 5,
                  bgcolor: value.id === liecenseId ? "" : ""
                }} className={`${value.id === liecenseId ? "selectedCardStyle bg-success text-white " : props.theme?"main-background cardStyle text-light border-primary border border-3":"main-background-dark cardStyle text-light border-primary border border-3"}  position-relative`} >
                 { value.id === liecenseId && <CheckOutlinedIcon
                 className="bg-success fs-3 rounded-4"
                  sx={{
                    position:"absolute",
                    top:"-8px",
                    transform:"translate(-34%)",
                    border:"2px solid #fff"
                  }}
                  />}
                  <div className="pt-3">
                    <div className="fs-4 fw-bold">{value?.name}</div>
                    <div>{"Maximum Users :" + value?.max_User_Count}</div>
                    <div>{"Maximum Message Count :" + value?.max_Message_Count}</div>
                  </div>
                  <Divider className="mt-1" variant="middle" />
                  <div className="d-flex justify-content-center">
                    <Box sx={{
                      width: "95%",
                      height: "300px",
                      borderRadius: 5,
                      textAlign: "left",
                      marginTop: 1,
                      padding: 2,
                      paddingTop: 3,
                      paddingBottom: 3,
                      bgcolor: "#ffff",
                      // border: "3.5px solid #A7BDF8"
                    }}
                    className={`${value.id === liecenseId ? "selectedCardStyle text-dark" : "cardStyle text-dark"}`}
                    >
                      {
                      value.id === liecenseId ?
                       <ul>
                       <li>{"Total Messaged Used : "+usedMessage}</li>
                       <li>{"Assigned Users : "+assignedUserCount}</li>
                       </ul>:
                      <ul> 
                        <li>isActive'is assigned a value but never used   </li>
                        <li>loading' is assigned a value but never used </li>
                        <li>'Item' is assigned a value but never used</li>
                        <li>data 11</li>
                      </ul>
                    }
                    </Box></div>
                </Paper></div>
              })}

            </Box>
          </Grid>
          </div>
        </div>}
        </motion.div>


      </ThemeProvider>
    </ErrorBoundary>
  );
}
