import React from 'react';
import "./modal.css";
import { useState } from 'react';

const DocModal = (props) => {
    // console.log(props.data);
  const [contentModal, setContentModal] = useState(false);
  const [content, setContent] = useState();
  const showDetails = (content) => {
    console.log(content);
    setContentModal(true);
    setContent(content);
  }
  return (
    <>
        <div className="modal-container"
            onClick={(e) => {
            if(e.target.className === "modal-container") props.closeModal();
            }}
        >
            <div className="Docmodal rounded-3">
                <div className='doc-header bg-secondary'>
                    <h4 className="text-dark">Source Document</h4>
                </div>
                <div className='doc-body bg-dark-subtle'>
                    {props.data &&
                        props.data.map((doc, index) => {
                            return (
                                <div key={index} className="d-flex p-2">
                                    <p className="p-0 m-0 text-primary text-decoration-underline doc-content-link" onClick={()=>showDetails(doc.content)}>{'Page No: ' + doc.page + ', ' + doc.source.split('\\').pop()}</p>    
                                </div>
                            )
                        })
                    }
                </div>
                <div className='d-flex justify-content-end doc-footer bg-secondary'>
                    <button
                        type="button"
                        className="btn btn-dark"
                        onClick={() => props.closeModal()}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
        {contentModal &&
            <div className='content d-flex flex-column justify-content-between'>
                <div className='doc-data-content'>
                    {content}
                </div>
                <div className='d-flex justify-content-end align-items-end'>
                <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => setContentModal(false)}
                >
                    Close
                </button>

                </div>
                
            </div>
        }
    </>
  )
}

export default DocModal