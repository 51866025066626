import Routing from "./Routing";
// import DashboardRouting from "./Routing/DashboardRouting";

let subDomainParts =  `${localStorage.getItem('url')}` && `${localStorage.getItem('url')}`.split(".");
let subDomain = subDomainParts?.slice(0,-2).join("");

export const APP = [
    {
        subdomain: "",
        app: Routing,
        main: true,
    },
    {
        subdomain: subDomain,
        app: Routing,
        main: false,
    }
];