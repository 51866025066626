import * as React from "react";
import $ from "jquery";
import "./../Home/Home.css";
import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { blue, blueGrey, cyan, green, grey, indigo, red, teal } from '@mui/material/colors';
import Zoom from "@mui/material/Zoom";
import Tooltip from "@mui/material/Tooltip";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Notifications from "../../../Notifications/Messages";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { IconButton } from "@mui/material";
import "../css/dataGrid.css";
import { Link } from "react-router-dom";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { baseApiCall } from "../../../API/apiCalls";

/*******************  FOR MODAL   ****************************/
import CommonVariables from "../../../variables";
import CrudModal from "../Modals/crudModal";
import CommonLoading from "../../../Layout/CommonLoading";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});
const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
});

export default function Groups(props) {
  // const [userGroup, setUserGroup] = useState();
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});

  /************************* STATES TO MANAGE MODAL ***************************/
  const [modalInOpen, setModalInOpen] = useState(false);
  const [inEdit, setInEdit] = useState(false);
  const [inAdd, setInAdd] = useState(false);
  const [inDelete, setInDelete] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [isOrganisation, setIsOrganisation] = useState(false);
  const [isUserGroup, setIsUserGroup] = useState(true);
  const [currentRowId, setCurrentRowId] = useState();
  const [selectedUserGroup, setSelectedUserGroup] = useState();

  const [groupName, setGroupName] = useState();
  const [isActive, setIsActive] = useState();
  const [loading, setLoading] = useState(false);
  // const [modalLoading, setModalLoading] = useState(false);
  const [tenantUsers, setTenantUsers] = useState();
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [tenantFiles, setTenantFiles] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFileIds, setSelectedFileIds] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);

  useEffect(() => {
    loadUserGroups();
  }, []);

  const loadUserGroups = async () => {
    setLoading(true);
    const result = await baseApiCall(
      CommonVariables.urls.getAllUserGroupsUrl,
      {}
    );
    if (!result?.isFailure && result?.isFailure === false && result.data) {
      // setUserGroups(result.data);
      setRows(result.data);
      setLoading(false);
    }
    handleClose();
    setLoading(false);
  };

  const handleEditClick = (row) => async () => {
    setCurrentRowId(row.id);
    setGroupName(row.groupName);
    setIsActive(row.isActive);
    setModalInOpen(true);
    setInEdit(true);
  };

  const handleDeleteClick = (row) => async () => {
    setCurrentRowId(row.id);
    setModalInOpen(true);
    setInDelete(true);
  };

  const handleGroupName = (row) => {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      // Check if the key contains '_name'
      if (key && key.includes("_GroupName")) {
        localStorage.removeItem(key);
      }
    }
    localStorage.setItem(row.id + "_GroupName", row.groupName);
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const CustomCheckBox = ({ value }) => (
    <Tooltip title={value?"Yes":"No"}>
      <IconButton style={{ color: value ? 'green' : 'red' }}>
        {value ? <CheckOutlinedIcon  /> : <CloseOutlinedIcon  />}
      </IconButton>
    </Tooltip>
  );

  const columns = [
    {
      field: "isActive",
      type: "boolean",
      headerName: "Is Active",
      width: 80,
      renderCell: (params) => <CustomCheckBox value={params.row.isActive} />,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Manage",
      width: 250,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <>
          <Tooltip title="Edit" arrow  TransitionComponent={Zoom} placement="top" className=' me-3 text-secondary'>
            <GridActionsCellItem
              icon={<EditIcon sx={{fill:blueGrey[600]}} />}
              label="Edit"
              className="ms-5 me-3 opacity-75"
              onClick={handleEditClick(row)}
               sx={{backgroundColor:teal[50]}} 
            />
          </Tooltip>
          <Tooltip title="Delete" arrow  TransitionComponent={Zoom} placement="top" className='mx-5'>       
            <GridActionsCellItem
              icon={<DeleteIcon sx={{fill:red[800]}} />}
              className="mx-5 opacity-75"
              onClick={handleDeleteClick(row)}
              sx={{backgroundColor:teal[50]}}
            />
          </Tooltip>
          </>
        ];
      },
    },
    { field: "groupName", headerName: "Name", width: 250, editable: false },
    {
      field: "createdDate",
      headerName: "Created On",
      type: "date",
      width: 200,
      valueGetter: ({ value }) => value && new Date(value),
      align: "left",
      headerAlign: "left",
      editable: false,
    },
    {
      field: "manageUsers",
      headerName: "Manage Users",
      type: "actions",
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <ThemeProvider theme={$(".lower-div").attr("data-bs-theme") === "light"? lightTheme : darkTheme}>
          <Tooltip title="Details" arrow  TransitionComponent={Zoom} placement="top" className=' me-3 text-secondary'>
            <Link to={`/dashboard/group/users/${row.id}`}>  
              <GridActionsCellItem
              icon={<OpenInNewIcon sx={{fill:cyan[800]}} />}
              label="Details"
              className="ms-5 me-3 opacity-75"
              onClick={setSelectedUserGroup(row)}
              sx={{backgroundColor:teal[50]}}
              />
            </Link>
          </Tooltip>
          </ThemeProvider>
        ];
      },
      width: 200,
      editable: false,
    },
    {
      field: "manageFiles",
      headerName: "Manage Files",
      type: "actions",
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <Tooltip
            title="Details"
            arrow
            TransitionComponent={Zoom}
            placement="top"
            className=" me-3 text-secondary"
          >
            <Link
              to={{
                pathname: `/dashboard/group/files/${row.id}`,
                state: {from: "/dashboard/group" }, // Pass the previous page path
              }}
            >
              <GridActionsCellItem
                icon={<OpenInNewIcon sx={{ fill: cyan[800] }} />}
                label="Details"
                className="ms-5 me-3 opacity-75"
                onClick={handleGroupName(row)}
                sx={{backgroundColor:teal[50]}}
              />
            </Link>
          </Tooltip>,
        ];
      },
      width: 200,
      editable: false,
    },
  ];

  const addUserGroup = async (data) => {
    setLoading(true);
    const result = await baseApiCall(
      CommonVariables.urls.addUserGroupUrl,
      data
    );
    if (!result?.isFailure && result?.isFailure === false) {
      setLoading(false);
      Notifications.ShowSuccessMessage(result.message);
      loadUserGroups();
      handleClose();
    }
    setLoading(false);
  };

  const updateUserGroup = async (data) => {
    setLoading(true);
    const result = await baseApiCall(
      CommonVariables.urls.updateUserGroupUrl,
      data
    );
    if (!result?.isFailure && result?.isFailure === false) {
      setLoading(false);
      Notifications.ShowSuccessMessage(result.message);
      loadUserGroups();
      handleClose();
    }
    setLoading(false);
  };

  const assignUserToUserGroup = async (data) => {
    setLoading(true);
    const result = await baseApiCall(
      CommonVariables.urls.assignUsersToUserGroupUrl,
      data
    );
    if (!result?.isFailure && result?.isFailure === false) {
      setLoading(false);
      Notifications.ShowSuccessMessage(result.message);
      loadUserGroups();
      handleClose();
    }
    setLoading(false);
  };

  const deleteUserGroup = async (data) => {
    setLoading(true);
    const result = await baseApiCall(
      CommonVariables.urls.deleteUserGroupUrl,
      data
    );
    if (!result?.isFailure && result?.isFailure === false) {
      setLoading(false);
      loadUserGroups();
      // setRows(rows.filter((row) => row.id !== data.id));
      Notifications.ShowSuccessMessage(result.message);
      handleClose();
    }
    setLoading(false);
  };

  const handleClose = () => {
    setModalInOpen(false);
    setGroupName("");
    setIsActive(false);
    setInAdd(false);
    setInEdit(false);
    setInDelete(false);
    setSelectedFiles([]);
    setSelectedUsers([]);
  };

  const handleInAdd = () => {
    setInAdd(true);
    setModalInOpen(true);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  return (
    <motion.div
      sx={{ transition: "none !important" }}
      animate="final"
      className="for-transition p-4 grids overflow-auto h-100"
    >
      <div
        className="for-transition w-100 card border h-100 border-1 border-color-css rounded-4 shadow overflow-hidden"
      >
        { loading ? <CommonLoading /> :
          <>         
            <div className="fs-3 py-3 px-3 mb-2 fw-semibold main-text text-center rounded-bottom-0 card border-0 border-bottom bg-body-tertiary">
              Manage User Groups
            </div>
            <Button
              // color="primary"
              variant="contained"
              style={{ minWidth: "9rem", maxWidth: "12rem" }}
              className="p-2 m-3 ms-4 mb-0 button-color"
              startIcon={<AddIcon />}
              onClick={handleInAdd}
            >
              Add record
            </Button>
            <Box
              sx={{
                minHeight: 520,
                height: "70vh",
                margin: "",
                fontWeight: "bold",
                padding: "26px",
                paddingTop: "16px",
                transition: "none !important",
                "& .actions": {
                  color: "text.secondary",
                },
                "& .textPrimary": {
                  color: "text.primary",
                },
              }}
            >
              <DataGrid
                className="text-body-secondary rounded-4 overflow-hidden"
                rows={rows}
                style={{ transition: "none !important" }}
                sx={{
                  transition: "none !important",
                  maxHeight: "68vh",
                  minHeight: "55vh",
                }}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                slotProps={{
                  toolbar: { setRows, setRowModesModel },
                }}
                disableRowSelectionOnClick
                // GridFooter
              />
            </Box>
            {modalInOpen && (
              <ThemeProvider
                theme={
                  $(".lower-div").attr("data-bs-theme") === "light"
                    ? lightTheme
                    : darkTheme
                }
              >
                <CssBaseline />
                <CrudModal
                  modalInOpen={modalInOpen}
                  currentRowId={currentRowId}
                  inEdit={inEdit}
                  isUser={isUser}
                  isOrganisation={isOrganisation}
                  isUserGroup={isUserGroup}
                  inAdd={inAdd}
                  inDelete={inDelete}
                  handleClose={handleClose}
                  addUserGroup={addUserGroup}
                  updateUserGroup={updateUserGroup}
                  assignUserToUserGroup={assignUserToUserGroup}
                  deleteUserGroup={deleteUserGroup}
                  groupName={groupName}
                  setGroupName={setGroupName}
                  isActive={isActive}
                  setIsActive={setIsActive}
                  tenantUsers={tenantUsers}
                  selectedUsers={selectedUsers}
                  tenantFiles={tenantFiles}
                  selectedFiles={selectedFiles}
                  selectedFileIds={selectedFileIds}
                  selectedUserIds={selectedUserIds}
                />
              </ThemeProvider>
            )}
          </>
        }
      </div>
    </motion.div>
  );
}
