import React ,{useEffect} from "react";
import $ from 'jquery'
import { loadChat , formatTimestamp, generateAnswer} from "../../../API/apiCalls";
import {
   motion,
  //  AnimatePresence 
  } from "framer-motion";
import { useParams } from "react-router-dom/dist";
import { useState } from "react";
import CommonLoading from "../../../Layout/CommonLoading";
import DocModal from "./docModal";
// import bookGif from '../../../images/icons8-book-white.gif';
import bookGif from '../../../images/book-page-flip-unscreen.gif';

// import { materialThemes } from '../../../API/commonFunctions';
import { CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme } from '@mui/material/styles';
// import { TypeAnimation } from 'react-type-animation';s

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

function ChatComp(props) {
  const { sessionId } = useParams();
  const [chatData, setChatData] = useState();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState();
  let [datesArray, setDatesArray] = useState([]);
  const [chatLoadTimer, setChatLoadTimer] = useState(5000);
  let previousDateChat = '';

  const loadPreviousChat = async () => {
    setLoading(true);
    let formattedDate = '';
    let previousDate = '';
    const result = await loadChat(sessionId);
    if (result) {
      setLoading(false);
      setChatData(result?.data);
      let dates = result?.data?.sessionHistory.map((chat)=>{
          const date = new Date(chat.timestamp);
          formattedDate = date.toLocaleDateString('en-US', {
            weekday: 'short', // Abbreviated weekday (like 'Fri')
            year: 'numeric', // Numeric representation of the year (like '2024')
            month: 'short', // Abbreviated month (like 'May')
            day: 'numeric', // Day of the month (like '03')
          });
          if(formattedDate !== previousDate){
            previousDate = formattedDate;
            return formattedDate;
          } else{
            return null;
          }
      })
      // console.log(dates);
      setDatesArray(dates);
    }
    console.log(chatData.sessionHistory.length); 
    if(chatData?.sessionHistory?.length > 15){
      setChatLoadTimer(15000);
    } else if(chatData?.sessionHistory?.length > 20) {
      setChatLoadTimer(20000);
    }
    //console.log(chatLoadTimer); 
    setLoading(false);
    // loadPreviousChat();
  };

  const scrollSmoothlyToBottom = () => {
    const element = $("#div-to-scroll");
    element.animate({
        scrollTop: element.prop("scrollHeight")
    }, 500);
  }
  // function scrollToBottom() {
  //   setInterval(function () {
  //     var divToScroll = $("#div-to-scroll");
  //     divToScroll[0].scrollTop = divToScroll[0].scrollHeight;
  //   }, 10000);
  // }

  function handleEnterClick(press) {
    var keycode = press.keyCode ? press.keyCode : press.which;
    if (
      (keycode === 13 || keycode === undefined) &&
      $(".input-field").val().length  !== 0 && !press.shiftKey
    ) {
      press.preventDefault();
      sendMessage($(".input-field").val());
      $(".input-field").val("");
      $(".hideButton").hide();
      $(".input-field").focus();
    } 
  }

  const loadingBookImage = `<img src=${bookGif} alt='book icon' class='ml-2 bg-transparent'  width='120' height='120' style={{"background-color":"transparent","margin-left":"100px"}} />`

  async function sendMessage(question) {
    let formattedDate = '';
    var time = formatTimestamp();
    const date = new Date();
    formattedDate = date.toLocaleDateString('en-US', {
      weekday: 'short', // Abbreviated weekday (like 'Fri')
      year: 'numeric', // Numeric representation of the year (like '2024')
      month: 'short', // Abbreviated month (like 'May')
      day: 'numeric', // Day of the month (like '03')
    });
    if(formattedDate !== datesArray.filter(dateItem => dateItem !== null).pop() && formattedDate !== previousDateChat){
      var dateDiv = $("<div class='date-div d-flex align-items-center justify-content-center text-body-secondary pb-3'>");
      dateDiv.prepend(
        "<span class='bg-light-subtle shadow rounded rounded p-1 m-1 opacity-75'>" +
        formattedDate +
        "</ span>"
      );
      $(".messages-div").append(dateDiv);
      previousDateChat = formattedDate;
    }
    var askMessageDiv = $("<div class='ask-message-space pb-3 col-sm-12'>");
    question = $(".input-field").val();
    // askMessageDiv.addClass("ask-message-space col-sm-12");
    askMessageDiv.prepend(
      "<li class='each-ask-message p-2 px-md-3 pb-1 mb-0 border-0 rounded-2 list-unstyled'>" +
        question +
        "<p class='time-span time-span-q mb-0'>" +
          time +
        "</p>" +
      "</li>"
    );
    $(".messages-div").append(askMessageDiv);
    $(".welcome-text").hide();
    var responseMessageDiv = $("<div class='d-flex response-message-space pb-3 col-sm-12 '>");
    // responseMessageDiv.addClass("response-message-space col-sm-12");
    // if(props.currentTheme === "light"){
    //   responseMessageDiv.append(
    //     "<img src='/static/media/icons8-book.aefc07ff303017bd90b4.gif' alt='book icon' class='animated-book-icon ml-2' width='40' height='50' style='background-color:transparent;'  margin-left:100px'/>"
    //   );
    // } else {
      responseMessageDiv.append(
        `<img src=${bookGif} alt='book icon' class='ml-2 bg-transparent'  width='120' height='120' style={{"background-color":"transparent","margin-left":"100px"}} />`
        // "<img src='/static/media/icons8-book-white.6454108848558f4f5c5f.gif' alt='book icon' class='animated-book-icon ml-2 text-black'  width='40' height='50' style='background-color:transparent;'  margin-left:100px'/>"
        // loadingBookImage
        // "<img src='../../../docs/icons8-book (3).gif' alt='book icon' class='ml-2 text-black'  width='40' height='50' style='background-color:transparent;'  margin-left:100px'/>"
      );
    // }
   
    $(".messages-div").append(responseMessageDiv);
    // console.log( $("#div-to-scroll").scrollTop($("#div-to-scroll")[0]));
    // $(".div-to-scroll").scrollTop = $(".div-to-scroll").scrollHeight
    // $("#div-to-scroll").scrollTop($("#div-to-scroll")[0].scrollHeight);
    var objDiv = document.getElementById("div-to-scroll");
    objDiv.scrollTop = objDiv.scrollHeight;
    
    var response = await generateAnswer(question, sessionId);
    generateResponse(response, time);
    responseMessageDiv.remove();
  }

  function generateResponse(response, time) {
    
    var responseMessageDiv = $("<div class='d-flex response-message-space pb-3 col-sm-12'>");
    $(".hideButton").show();
    if (response.data.answer && response.data.sourceDocs) {
      responseMessageDiv.prepend(
        "<li class='d-flex flex-column flex-wrap each-response-message p-2 px-md-3 border-0 rounded-4 list-unstyled'>"
        +
          response.data.answer +
          "<p class='time-span pt-1 mb-0 d-flex justify-content-between'>" +
            time +
            "<span class='material-symbols-outlined source-span' title='See source document'> info </span>"+
          "</p>" +
        "</li>"
      );
    } else {
      responseMessageDiv.prepend(
        "<li class='each-response-message d-flex flex-column flex-wrap p-2 px-md-3 border-0 rounded-4 list-unstyled'>" +
          response +
          "<p class='time-span pt-1 mb-0'>" +
            time +
          "</p>" +
        "</li>"
      );
    }

    scrollSmoothlyToBottom();
    $(".messages-div").append(responseMessageDiv);
    // setTimeout(function () {
    //   scrollToBottom();
    // }, 1000);
    // $(".div-to-scroll").scrollTop($(".div-to-scroll")[0].scrollHeight);
    // $("#div-to-scroll").scrollTop($("#div-to-scroll")[0].scrollHeight);
    var objDiv = document.getElementById("div-to-scroll");
    objDiv.scrollTop = objDiv.scrollHeight;

    if(response.data.answer && response.data.sourceDocs) {
      // console.log(response.data.sourceDocs);
      $(".source-span").click("click", showSourceDoc);
      var sourceDocumentArray = response.data.sourceDocs.map( (doc) => doc.source);
      var pageNos = response?.data?.sourceDocs.map((doc)=>doc.page);
      var content = response?.data?.sourceDocs.map((doc)=>doc.content);
      // console.log(sourceDocumentArray);
      //   "Document(page_content="
      // );
      $(".source-doc-body").empty();
      for (
        let eachSourceAns = 1;
        eachSourceAns < sourceDocumentArray.length;
        eachSourceAns++
      ) {
        const currentSourceAns = eachSourceAns;
        var sourcePara = $("<div>");
        sourcePara.addClass("d-flex p-2");
        sourcePara.prepend("<p class='p-0 m-0 text-primary text-decoration-underline doc-content'>" + 'Page No: ' + pageNos[eachSourceAns] + ', ' + sourceDocumentArray[eachSourceAns].split("\\").pop() + '</p>');
        $(".source-doc-body").append(sourcePara);
        $(".doc-content").click("click", function() {
          $(".source-doc-content-modal").addClass("doc-content-visible d-flex flex-column justify-content-between");
          $(".source-doc-content-modal").show();
          // setDocData(content[eachSourceAns]);
          // var contentPara = $("<div class='doc-content-data'>");
          // contentPara.prepend(content[eachSourceAns]);
          // if(currentSourceAns===eachSourceAns)
          $(".doc-content-data").append(content[currentSourceAns]);
        });
      } 
    }
  }

  const handleModalOpen = (data) => {
    setModalOpen(true);
    setModalData(data);
  }

  function showSourceDoc() {
    $(".source-doc-modal").addClass("visible");
    $(".source-doc-modal").show();
    // setSourceDocModalOpen(true);
  }
  function scrolldiv() {
    setTimeout(function () {
      const divToScroll = $("#div-to-scroll");
      divToScroll.animate({
        scrollTop: divToScroll.prop("scrollHeight")
    }, 500);
      // divToScroll[0].scrollTop = divToScroll[0].scrollHeight;
    }, chatLoadTimer);
  }
useEffect(() => {
    loadPreviousChat()
    scrolldiv();
    // resize_ob.observe(document.getElementById("div-to-scroll"));
  }, []);
  useEffect(() => {
    loadPreviousChat()
    scrolldiv();
    // resize_ob.observe(document.getElementById("div-to-scroll"));
  }, [window.location.pathname]);
  // useEffect(() => {
  //   setCompletedTyping(false);
  
  //   let i = 0;
  //   const stringResponse = chatHistory[chatHistory.length - 1].content
  
  //   const intervalId = setInterval(() => {
  //     setDisplayResponse(stringResponse.slice(0, i));
  
  //     i++;
  
  //     if (i > stringResponse.length) {
  //       clearInterval(intervalId);
  //       setCompletedTyping(true);
  //     }
  //   }, 20);
  
  //   return () => clearInterval(intervalId);
  // }, [chatHistory]);
  return (
    <>
      <ThemeProvider theme={$(".lower-div").attr("data-bs-theme") === "light"? lightTheme : darkTheme}>
        <CssBaseline />
        <motion.div  
          animate="final"
          className="grids p-4 h-100 overflow-auto for-transition">
            {/* <div className="text-center text-body-secondary fw-bold">
              <h3>{chatData?.sessionName}</h3>
            </div> */}
            <div
              className={`for-transition w-100 card border h-100 border-1 border-color-css rounded-3 ${props?.theme?"bg-body-secondary":"bg-black"} `}
              style={{}}
            >
              {loading? <CommonLoading loading={loading}/> :
                <>
                  <div 
                    id="div-to-scroll"
                    className="messages-div overflow-y-auto flex-grow-1"
                  >
                    {chatData?.sessionHistory &&
                      chatData?.sessionHistory?.map((eachChat,index) => {
                        return (
                          <div key={index}>
                            <div className="date-div d-flex align-items-center justify-content-center text-body-secondary pb-3">
                              {datesArray[index] !== null && <span className="bg-light-subtle shadow rounded p-1 m-1 opacity-75">{datesArray[index]}</span>} 
                            </div>
                            <div className="ask-message-space pb-3 col-sm-12">
                              <li className="each-ask-message text-start p-2 px-md-3 mb-0 pb-1 border-0 rounded-4 list-unstyled">
                                {eachChat.question}
                                <p className="time-span time-span-q mb-0 ms-auto  d-flex flex-row-reverse">
                                  {formatTimestamp(eachChat.timestamp)}
                                </p>
                              </li>
                            </div>
                            <div className="d-flex response-message-space align-items-start justify-content-start pb-3 col-sm-12">
                              <li className="d-flex flex-column flex-wrap each-response-message p-2 px-md-3 pb-1 border-0 rounded-4 list-unstyled">
                                {eachChat.answer}
                                <p className="time-span pt-1 mb-0 pb-0 d-flex justify-content-between" >
                                  {formatTimestamp(eachChat.timestamp)}
                                  <span 
                                    className='material-symbols-outlined source-span'
                                    aria-label="Response Details"
                                    title='See source document'
                                    onClick={()=>{
                                      handleModalOpen(eachChat.sourceDocs);
                                      $(".source-doc-modal").hide();
                                    }}
                                  > 
                                    info
                                  </span>
                                </p>                     
                              </li>
                            </div>
                          </div>
                        );
                    })}
                  </div>
                  <div className="d-flex pt-0 p-sm-0 p-md-2 mt-0 mb-5 mb-lg-4 for-transition" style={{height:"3rem",minHeight:"60px"}}>
                    <div className={`${$(".lower-div").attr("data-bs-theme") === "light"? `shadow-lg`: `text-box-dark`} text-box w-75 mx-auto position-relative pt-0 rounded-4 border border-0 border-secondary bg-body-secondary mb-auto`}>
                      <textarea
                        className="rounded-4 input-field form-control border-3 border-primary-subtle position-relative w-100 py-2 fs-6 lh-sm"
                        rows="10"
                        cols="40"
                        aria-label="Enter Query"
                        placeholder="Enter your query"
                        onKeyDown={(press) => handleEnterClick(press)}
                        name="sendButton"
                        type="text"
                      >  
                      </textarea>
                      <span
                        aria-label="Ask Query"
                        className="material-symbols-outlined hideButton send-span h-100 position-absolute fs-3 fw-normal text-secondary rounded-4"
                        onClick={(clk) => handleEnterClick(clk)}
                      >
                        send
                      </span>
                    </div>
                  </div> 
                  <div className="source-doc-modal"
                      onClick={(e) => {
                      if(e.target.className === "source-doc-modal") $(".source-doc-modal").hide();
                      }}
                  >
                    <div className="Docmodal-container">
                        <div className='source-doc-header'>
                            <h4 className="text-dark-subtle">Source Document Details</h4>
                        </div>
                        <div className='source-doc-body'>
                        </div>
                        <div className='d-flex justify-content-end source-doc-footer'>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => $(".source-doc-modal").hide()}
                            >
                              Close
                            </button>
                        </div>
                    </div>
                  </div> 
                  <div className='source-doc-content-modal'>
                      <div className="doc-content-data">

                      </div>
                      <div className='d-flex justify-content-end align-items-end'>
                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => {
                              $(".source-doc-content-modal").hide();
                              $(".doc-content-data").empty();
                              $(".source-doc-content-modal").removeClass("doc-content-visible d-flex flex-column justify-content-between");
                            }}
                        >
                            Close
                        </button>
                      </div>      
                  </div> 
                  {modalOpen && 
                    <DocModal 
                      closeModal={()=>setModalOpen(false)}
                      data={modalData}
                    />
                  }
                </>
              } 
            </div>
        </motion.div>
      </ThemeProvider>
    </>
  );
}

export default ChatComp;
