import React, { useCallback, useEffect, useState } from 'react';
// import $ from 'jquery';
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from '@mui/material/TextField';
// import editOrganisationModal from "./crudModal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
// import Commonloader from '../../../Layout/Commonloader';

/********************** IMPORTS FOR DROPDOWN **********************/
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { baseApiCall } from '../../../API/apiCalls';
import CommonVariables from '../../../variables';

import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CommonLoading from '../../../Layout/CommonLoading';
import { handleModalDropdown, handleModalDropdownForFiles } from './modalFunctions';
import { IconButton, Input, InputAdornment, Tooltip } from '@mui/material';
import { Edit, Propane, Visibility, VisibilityOff } from '@mui/icons-material';
import Notifications from '../../../Notifications/Messages';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function CrudModal(props) {
  const {
    isOrganisation,
    inAdd,
    inEdit,
    inCreateAdmin,
    isUser,
    isChatSession,
    isUserGroup,
    isUserGroupFile,
    isUserGroupUser,
    inDelete,
    isFile,
    isTier
  } = props;

  let modalHeading;
  let buttonText;
  if (inAdd) {
     if(inCreateAdmin && isOrganisation) {
      modalHeading = "Add Admin"; // Default to "Add Organisation" if no specific add condition matches
    } else if(isUser){
      modalHeading = "Add User"
    } else if (isChatSession) {
      modalHeading = "Add Session";
    } else if (isUserGroup) {
      modalHeading = "Add User Group";
    } else if (isUserGroupFile) {
      modalHeading = "Add Files To Group";
    } else if (isUserGroupUser) {
      modalHeading = "Add Users to Group";
    } else if(inCreateAdmin) {
      modalHeading = "Add Organisation"; // Default to "Add Organisation" if no specific add condition matches
    } else if(isTier) {
      modalHeading = "Create New Tier"; // Default to "Add Organisation" if no specific add condition matches
    }
    buttonText = "Add";
  } else if (inEdit) {
    if (isChatSession) {
      modalHeading = "Edit Session";
    } else if (isUserGroup) {
      modalHeading = "Edit User Group";
    } else if(isTier) {
      modalHeading = "Edit Tier"; // Default to "Add Organisation" if no specific add condition matches
    } else {
      modalHeading = "Edit Organisation"; // Default to "Edit Organisation" if no specific edit condition matches
    }
    buttonText = "Update"
  } else if (inDelete) {
    modalHeading = "Confirm Delete";
    buttonText = "Delete" // Default to "Confirm Delete" for all delete operations
  }
  else {
    modalHeading = ""; // Default case if none of the conditions match
    buttonText = "Submit"
  }
  
/********************** STATES FOR MODALS OPENED FROM USER TAB **********************/
const [userGroups,setUserGroups] = useState([])
const [userGroupNames,setUserGroupNames] = useState([]) 
const [userGroupIdsToSend,setUserGroupIdsToSend] = useState([]) 
const [userGroupsToSend,setUserGroupsToSend] = useState(props?.selectedUserGroups)

const [loading, setLoading] = useState(false);

const [selectedOrganisaton,setSelectedOrganisaton] = useState()
const [adminEmail,setAdminEmail] = useState()
const [firstName,setFirstName] = useState()
const [lastName,setLastName] = useState();
const [showPassword,setShowPassword] = useState(false);
const [isPasswordCorrect,setIsPasswordCorrect] = useState(true);
const [editPassword,setEditPassword] = useState(true);

const [documentIdsToSend, setDocumentIdsToSend] = useState(props?.selectedFileIds);
const[userIdsToSend, setUserIdsToSend] = useState(props?.selectedUserIds);

  useEffect(() => {
    if(props?.inEdit){
      setEditPassword(false);
    }
    setLoading(true);
    if(props.isUser){
      // getAllUserGroups();
      setLoading(false);
    } 
    else if(props.isUserGroup){
      setLoading(false);
    }
    if(props.inEdit && props.isUser){
      console.log("mmodal mounted")
      setUserGroupIdsToSend(props.selectedUserGroups)
    }
    setLoading(false);
  }, []);
  
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  // const getAllUserGroups = async ()=>{
  //   setLoading(true);
  //     const result = await baseApiCall(CommonVariables.urls.getAllUserGroupsUrl,{});
  //     if(!result?.isFailure && result?.isFailure === false && result.data){
  //       setLoading(false);
  //       setUserGroups(result.data)
  //       formSelectedUserGroupsArray(result.data)
  //   }
  //   setLoading(false);
  // }

/********************** FUNCTIONS TO GET USERGROUP NAMEs ASSIGNED TO A USER FROM IDs OF USERGROUPS  **********************/      
    const  formSelectedUserGroupsArray = (userGroupsArray)=>{
      var selectedUserGroups = [];
      props.selectedUserGroups.forEach((eachSelectedGroup)=>{
         let selectedUserGroup;
         selectedUserGroup =  userGroupsArray?.filter((userGroup)=> userGroup.id === eachSelectedGroup)
         selectedUserGroups.push(selectedUserGroup)
       })
       let userGroupsNames = selectedUserGroups.map((eachGroup)=>eachGroup[0].groupName);
       setUserGroupNames(userGroupsNames)
    }
    // console.log(userGroupNames);

/********************** FUNCTIONS FOR USERGROUPS DROPDOWN **********************/
  const handleUserGroupChange = (event) => {
    const {
      target: { value },
    } = event;
    setUserGroupNames(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    var userGroupIdsArray;
    var forUserGroupIds = [];


/********************** FUNCTIONS TO FORM ARRAY OF USERGROUP IDs FROM USERGROUPNAMEs ASSIGNED TO USER **********************/
    value.forEach((groupName)=>{
    let forUserGroupId
       forUserGroupId = userGroups?.filter((userGroup)=>userGroup.groupName === groupName)
       forUserGroupIds.push(forUserGroupId)
    })
    userGroupIdsArray = forUserGroupIds.map((each)=>each[0].id)
    setUserGroupsToSend(userGroupIdsArray)
  };

  const handleFiles = (event, value) => {
    // console.log(event.target.renderOption.selected);
    const selectedFileIds = value.map(selectedFile => selectedFile.id);
    // console.log("Selected file IDs:", selectedFileIds);
    setDocumentIdsToSend(selectedFileIds);

  } 

  const handleUsers = (event, value) => {
    const selectedUserIds = value.map(selectedUser => selectedUser.id);
    setUserIdsToSend(selectedUserIds);
  }
  
  const handleUserGroup = (event, value) => {
    const selectedUserGroupIds = value.map(selectedUserGroup => selectedUserGroup.id);
    setUserGroupIdsToSend([...userGroupIdsToSend ,  ...selectedUserGroupIds]);
  }

  const handleDropdownSelection = useCallback(
    (option) => {
      return userGroupIdsToSend.some((item)=>item === option.id)
    },
    [userGroupIdsToSend],
  )
  


  const handleOrganisationChange = (event) => {
    setSelectedOrganisaton(props?.rows?.filter((organisation)=>organisation.tenantName === event.target.value))
  };

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 420,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
      };      

      const validatePassword = (event)=>{
        event.preventDefault();
        var regex=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,20}$/;
        if(event.target.value && !event.target.value.match(regex)){
              setIsPasswordCorrect(false);
           }
        else{
          setIsPasswordCorrect(true);
        }
        props.setPassword(event.target.value); 
      }

      const handleFormSubmit = (event)=>{
        event.preventDefault();
        if(inDelete){
          if(isOrganisation){
            props.deleteOrganisation({id:props.currentRowId})
            return;
          }
          if(isUserGroup){
            props.deleteUserGroup({id:props.currentRowId})
          }
          if(isUser){
            props.deleteUser({id:props.currentRowId})
            return;
          }
          if(isChatSession){
            props.deleteSession({id:props.currentRowId});
            return;
          } 
          if(isUserGroupUser){
            props.handleDeleteUserFromUserGroup();
            return;
          } 
          if(isUserGroupFile){
            props.handleDeleteFiles();
            return;
          } 
          if(isFile){
            props.handleDeleteFileFromTenant()
            return
          }
          if(isTier){
            props.deleteTier({id:props.currentRowId});
            return
          }
        }
        if(inEdit){
          if(isOrganisation){
            props.updateOrganisation({id:props.currentRowId,tenantName:props.orgName,isActive:props.isActive})
            return;
          }
          if(isUser){
            if(isPasswordCorrect){
            props.updateUser({id:props.currentRowId,firstName:props.firstName,lastName:props.lastName,isActive:props.isActive,userGroupIds:userGroupsToSend,password:props.password, userSessions: props.chatSessions,userGroupIds:userGroupIdsToSend})
            return;
            }
            Notifications.ShowErrorMessage("Please enter valid password");
          }
          if(isUserGroup){
            props.updateUserGroup({id:props.currentRowId,groupName:props.groupName,isActive:props.isActive, documentIds:documentIdsToSend });
            return;
          }
          if(isChatSession){
            props.updateSession({userId:localStorage.getItem("userId"), id:props.currentRowId, sessionName:props.sessionName});
            return;
          }
          if(isTier){
            //debugger;
            props.updateTier({userId:localStorage.getItem("userId"), id:props.currentRowId, name:props.tierName, max_Message_Count:props.maxTierMessageCall, max_User_Count:props.maxTierUsers });
            return;
          }
        }
        if(inAdd){
          if(isOrganisation){
            if(inCreateAdmin){
              props.createAdmin({tenantId:props.organisationById.id,userEmail:adminEmail,firstName:firstName,lastName:lastName,isActive:props.isActive});
              return;
            }
            props.addOrganisation({tenantName:props.orgName,isActive:props.isActive})
            return;
          }
          if(isUser){
            if(isPasswordCorrect){
              props.addUser({userEmail:props.email,firstName:props.firstName,lastName:props.lastName,isActive:props.isActive,password:props.password,userGroupIds:userGroupsToSend});
              return;
            }
            Notifications.ShowErrorMessage("Please enter valid password");
          }
          if(isUserGroup){
            props.addUserGroup({groupName:props.groupName})
          }
          if(isUserGroupUser){
            props.assignUserToUserGroup({userIds:userIdsToSend });
            return
          }
          if(isUserGroupFile){
            props.addFilesToUserGroup({id:props.userGroupId, documentIds:documentIdsToSend });
            return
          }
          if(isChatSession){
            props.addSession({sessionName:props.sessionName, id:localStorage.getItem("userId")});
            return
          }
          if(isTier){
            props.addTier({name:props.tierName, max_Message_Count:props.maxTierMessageCall, max_User_Count:props.maxTierUsers });
            return
          }

        }
      }
    return (
      <div>
        {loading? <CommonLoading/> : 
          <Modal
            open={props.modalInOpen}
            onClose={props.handleClose}
            keepMounted={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className='crud-modal'
          >
            <Box sx={style} className="rounded-3 border-color-css">
              <div className="d-flex justify-content-between">
                {/********************** MANAGING CONDITIONAL HEADING OF MODALS **********************/}
                <Typography className={`${props.inDelete ? 'text-danger' : 'main-text'}`} variant="h6" component="h2">
                  {modalHeading}
                </Typography>

                <Button onClick={props.handleClose} className="p-0"><span className="material-symbols-outlined text-danger fw-bolder">close</span></Button>
              </div>
              <form onSubmit={handleFormSubmit} className="vstack mt-4 h-full gap-3">
                {props.isOrganisation && !inCreateAdmin && (props.inAdd || props.inEdit) &&
                  <TextField autoFocus label="Organisaton Name" variant="standard" value={props.orgName} onChange={(event) => {props.setOrgName(event.target.value);}}  required />
                }
                {props.isOrganisation && inCreateAdmin &&
                  <>
                    <TextField autoFocus type='email' label="Email" variant="standard" value={adminEmail} onChange={(event) => {setAdminEmail(event.target.value);}}  required />
                    <TextField  label="First Name" variant="standard" value={firstName} onChange={(event) => {setFirstName(event.target.value);}}  required />
                    <TextField  label="Last Name" variant="standard" value={lastName} onChange={(event) => {setLastName(event.target.value);}}  required  />
                  </>              
                }

                 {/********************** MANAGING TIERS **********************/}
                 {(props.inAdd||props.inEdit) && props.isTier &&
                 <>
                    <TextField  label="Tier Name" variant="standard" value={props.tierName} onChange={(event) => {props.setTierName(event.target.value);}}  required />
                    <TextField  label="Maximum Users" type="number" variant="standard" value={props.maxTierUsers} onChange={(event) => {props.setMaxTierUsers(event.target.value);}}  required  />
                    <TextField className='mb-3'  type="number" label="Maximum Message Call" variant="standard" value={props.maxTierMessageCall} onChange={(event) => {props.setMaxTierMessageCall(event.target.value);}}  required  />
                  </>              
                }

                {/********************** MANAGING CONDITIONAL USER FIELDS **********************/}
                {(props.inAdd || props.inEdit) && (props.isUser) &&
                  <>
                    {props.inEdit &&  
                    <div className='d-flex flex-column gap-2 mt-2'>
                      <Typography variant="body1" gutterBottom 
                      style={{cursor:"not-allowed"}}
                      className='flex-grow-1 border-1 border border-top-0 border-start-0 border-end-0 py-1'>
                      {props.email} 
                    </Typography>
                    </div>
                    }
                    {!props.inEdit &&   <TextField autoFocus type='email' label="Email" variant="standard" value={props.email} onChange={(event) => {props.setEmail(event.target.value);}}  required />}
                    <TextField  label="First Name" autoFocus={props.inEdit} variant="standard" value={props.firstName} onChange={(event) => {props.setFirstName(event.target.value);}}  required />
                    <TextField  label="Last Name" variant="standard" value={props.lastName} onChange={(event) => {props.setLastName(event.target.value);}}  required  />
                   {props.inEdit &&  <div className='mb-1 mt-2'>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo-users"
                        options={handleModalDropdown(props?.userGroups,props.selectedUserGroups.map((item)=> {return  {"id":item}}))}
                        disableCloseOnSelect
                        limitTags={1}
                        onChange={handleUserGroup}
                        getOptionLabel={(option) => option?.groupName}
                        renderOption={(prop, option, { selected  }) => (
                          <li {...prop}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              // checked={handleDropdownSelection(option)}
                              checked={selected}
                            />
                            {option?.groupName}
                          </li>
                        )}
                        style={{ width: 350 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Assign Groups" placeholder="Select Groups"/>
                        )}
                      />
                    </div> 
                      }
                  </>
                }

                {/********************** MANAGING USER GROUP FIELDS **********************/}
                {props.isUserGroup && (props.inAdd || props.inEdit) && !props.isUserGroupFile && !props.isUserGroupUser &&
                  <>
                    <TextField autoFocus label="Group Name" variant="standard" value={props.groupName} onChange={(event) => {props.setGroupName(event.target.value);}}  required />
                  </>
                }
                {props.isUserGroupUser && props.inAdd &&
                  <>
                    {/* <TextField autoFocus label="Group Name" variant="standard" value={props.groupName} onChange={(event) => {props.setGroupName(event.target.value);}}  required /> */}
                    <div className='mb-1 mt-2'>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo-users"
                        options={handleModalDropdown(props?.tenantUsers,props?.rows)}
                        disableCloseOnSelect
                        limitTags={1}
                        onChange={handleUsers}
                        getOptionLabel={(option) => option?.userName}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option?.firstName}
                          </li>
                        )}
                        style={{ width: 350 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Assign Users" placeholder="Select Users"/>
                        )}
                      />
                    </div>                                   
                  </>
                }
                {props.isUserGroupFile && props.inAdd &&
                  <>
                    <div className='mb-1 mt-2'>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo-files"
                        options={handleModalDropdownForFiles(props?.tenantFiles,props?.rows)}
                        disableCloseOnSelect
                        limitTags={1}
                        onChange={handleFiles}
                        getOptionLabel={(option) => option?.name}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option?.name}
                          </li>
                        )}
                        style={{ width: 350 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Assign Files" placeholder="Select Files"/>
                        )}
                      />
                    </div>                                   
                  </>
                }

                {/********************** MANAGING CHAT SESSION FIELDS **********************/}
                { props.isChatSession && (props.inAdd || props.inEdit) &&
                  <TextField autoFocus label="Session Name" variant="standard" value={props.sessionName} onChange={(event) => {props.setSessionName(event.target.value);}}  required />
                }
                {(props.inAdd || props.inEdit || props.inCreateAdmin) && (!props.isChatSession) && (!props.isUserGroupUser) && (!isUserGroupFile) && (!props.isTier) &&
                  <div className="form-check form-switch ps-0 mb-3">
                    <div className='d-flex justify-content-start gap-5 align-items-center'>
                      <Typography className=' me-5 align-middle mt-2 fw-normal' style={{color:"#888"}}>Is Active </Typography>
                      <input className="is-active form-check-input border-2 border-primary-subtle fs-4" type="checkbox" onChange={() => {props.setIsActive(!props.isActive);}} checked={props.isActive} role="switch"  />
                    </div>
                  </div>
                }
                
                {/********************** MANAGING DELETE FIELDS **********************/}
                {props.inDelete &&
                  <>
                    <span className="material-symbols-outlined text-danger text-center" style={{fontSize:"5rem"}}>error</span>
                    <label className='text-danger text-center fw-semibold'>Are You sure you want to Delete</label>
                  </>
                }

                <Button  variant="outlined" className={`${props.inDelete ? 'bg-danger text-light border-0' : 'button-color border-0 text-light'}`} type="submit">{buttonText}</Button>
              </form>
            </Box>
          </Modal>
        }
      </div> 
    );
}

export default CrudModal;