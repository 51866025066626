import React from "react";
import {Link} from 'react-router-dom';
// import $ from 'jquery';
import { MakeLinkActive } from "../../API/commonFunctions";
import Sessions from "./Chat/sessions";
function SidebarLink(props){
  return(
    <>
      <div className="each-session py-0 d-flex lh-lg rounded-5 mb-1">
        <Link to={`/dashboard/`+ props.linkTo} className={props.active? `left-nav-active-link text-decoration-none text-capitalize left-nav-button fw-semibold p-2 fs-5 text-left w-100 border-0 d-flex align-self-center lh-1`:`text-decoration-none text-capitalize left-nav-button fw-semibold p-2 fs-5 text-left w-100 border-0 d-flex align-self-center lh-1`} onClick={(event)=>(MakeLinkActive(event,props.linkTo,"left-nav-button"))}>
          <span className="material-symbols-outlined px-2 fw-normal" aria-hidden="true">{props.icon}</span>
            {props.linkTo}
          <span className={props.active?`d-block material-symbols-outlined chevron-right-arrow text-end flex-grow-1`:`d-none`}>{props.linkTo !== "chat"?"chevron_right":"expand_more"}</span>
        </Link>
      </div>
      {(props.linkTo === "chat") &&
        <div>
          <Sessions />
        </div>
      }
    </>
  )
}

export {SidebarLink} 