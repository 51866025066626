import React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Zoom from "@mui/material/Zoom";
import Tooltip from "@mui/material/Tooltip";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Notifications from "../../../Notifications/Messages";
import "../css/dataGrid.css";
import {
  motion,
  //  AnimatePresence
} from "framer-motion";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { baseApiCall } from "../../../API/apiCalls";
import { useEffect } from "react";
import CommonVariables from "../../../variables";
import CrudModal from "../Modals/crudModal";
import CommonLoading from "../../../Layout/CommonLoading";
// import { useParams } from 'react-router';
import { useNavigate, useNavigation, useParams } from "react-router";
import $ from "jquery";
import { handleModalDropdown } from "../Modals/modalFunctions";
import { red, teal } from "@mui/material/colors";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});
const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
});

const UserGroupUsers = (props) => {
  let location = useLocation();
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [tenantUsers, setTenantUsers] = useState();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalInOpen, setModalInOpen] = useState(false);
  const [inDelete, setInDelete] = useState(false);
  const [inAdd, setInAdd] = useState(false);
  const [currentRowId, setCurrentRowId] = useState();
  const [isUser, setIsUser] = useState(false);
  const [isOrganisation, setIsOrganisation] = useState(false);
  const [isUserGroup, setIsUserGroup] = useState(false);
  const [isUserGroupUser, setIsUserGroupUser] = useState(true);
  const [selectedRows, setSelectedRows] = React.useState([]);
  let { userGroupId } = useParams();
  let navigate = useNavigate()
  let groupName = localStorage.getItem(userGroupId + "_GroupName");

  const selectedUsersInUserGroup = async (id) => {
    setLoading(true);
    const result = await baseApiCall(
      CommonVariables.urls.getAllUserGroupUsersUrl,
      { id: userGroupId }
    );
    if (
      !result?.isFailure &&
      result?.isFailure === false &&
      (result.data || result.data === null)
    ) {
      setSelectedUsers(result.data !== null ? result.data : []);
      if (result.data?.length > 0) {
        setRows(result.data);
        setSelectedUserIds(result.data.map((user) => user.id));
      }
      if (result?.data === null) {
        setRows([]);
      }
      setLoading(false);
      // console.log(selectedUserIds.filter(id=>{return id !== "6617866d633289478b97dc5c"}));
    }
    setLoading(false);
    // handleClose();
  };

  /********************** FUNCTION EXECUTABLE ON DELETE MODAL OPEN **********************/
  const handleDeleteClick =
    (row, isSingleRow = true) =>
    async () => {
      if (isSingleRow) {
        setCurrentRowId(row.id);
        setModalInOpen(true);
        setInDelete(true);
        setInAdd(false);
        return;
      }
      setCurrentRowId(undefined);
      setModalInOpen(true);
      setInDelete(true);
      setInAdd(false);
    };

  const assignUserToUserGroup = async (data) => {
    setLoading(true);
    data.userGroupId = userGroupId;
    const result = await baseApiCall(
      CommonVariables.urls.assignUsersToUserGroupUrl,
      data
    );
    if (!result?.isFailure && result?.isFailure === false) {
      selectedUsersInUserGroup();
      setLoading(false);
      Notifications.ShowSuccessMessage(result.message);
      //   loadUserGroups();
      handleClose();
    }
    setLoading(false);
  };

  const getAllTenantUsers = async () => {
    setLoading(true);
    const result = await baseApiCall(CommonVariables.urls.getAllUsersUrl, {
      tenantId: localStorage.getItem("tenantId"),
    });
    if (!result?.isFailure && result?.isFailure === false && result.data) {
      setTenantUsers(result.data);
      setLoading(false);
      // console.log(result.data);
    }
    handleClose();
    setLoading(false);
  };

  const handleBack = () => {
    navigate("/dashboard/groups"); // Go back to the previous page or a default page
  };

  const columns = [
    {
      field: "firstName",
      headerName: "First Name",
      width: 250,
      editable: false,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 250,
      editable: false,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Manage",
      width: 250,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <Tooltip
            title="Delete"
            arrow
            TransitionComponent={Zoom}
            placement="top"
            className="mx-5 text-secondary"
          >
            <GridActionsCellItem
              icon={<DeleteIcon sx={{fill:red[800]}} />}
              label="Delete"
              className="textPrimary text-body-secondary mx-5 opacity-75"
              onClick={handleDeleteClick(row)}
              sx={{backgroundColor:teal[50]}}
            />
          </Tooltip>,
        ];
      },
    },
  ];
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  /********************** FUNCTION EXECUTABLE ON DELETE USERS **********************/
  const handleDeleteUserFromUserGroup = async () => {
    setLoading(true);
    const pathString = location.pathname;
    const result = await baseApiCall(
      CommonVariables.urls.unassignUsersFromUserGroupUrl,
      {
        userGroupId: userGroupId,
        userIds: currentRowId
          ? [currentRowId]
          : selectedRows.map((item) => item.id),
      }
    );
    if (!result?.isFailure && result?.isFailure === false) {
      Notifications.ShowSuccessMessage(result.message);
      await selectedUsersInUserGroup();
      setLoading(false);
      setModalInOpen(false);
      setInDelete(false);
      setSelectedRows([]);
    } else {
      Notifications.ShowErrorMessage(result.message);
    }
    setLoading(false);
  };

  /*************************** FUNCTION EXECUTABLE ON MODAL CLOSE *************************/
  const handleClose = () => {
    setModalInOpen(false);
    // setFileName("");
    setInAdd(false);
    setInDelete(false);
  };

  /********************** FUNCTION EXECUTABLE WHILE ADDING NEW RECORD **********************/
  const handleInAdd = () => {
    // handleModalDropdown()
    setInAdd(true);
    setIsUserGroupUser(true);
    setModalInOpen(true);
  };
  useEffect(() => {
    selectedUsersInUserGroup();
    getAllTenantUsers();
  }, []);
  return (
    <motion.div
      sx={{ transition: "none !important" }}
      animate="final"
      className="for-transition p-4 grids overflow-auto h-100"
    >
      <div
        className="for-transition w-100 card border h-100 border-1 border-color-css rounded-4 shadow overflow-hidden"
        style={{}}
      >
        {loading ? (
          <CommonLoading />
        ) : (
          <>
            <div className="fs-3 py-3 px-3 mb-2 fw-semibold main-text text-center rounded-bottom-0 card border border-bottom bg-body-tertiary">
              <div style={{ display: "flex", alignItems: "center" }}>
                <ArrowBackIcon
                  style={{
                    cursor: "pointer",
                    margin: "0 1rem 0 2rem",
                    fontSize: "2rem",
                  }}
                  onClick={handleBack}
                />
                <div
                  style={{
                    flexGrow: 1,
                    textAlign: "center",
                    marginRight: "4rem",
                  }}
                >
                  {groupName} User Group Users
                </div>
              </div>
            </div>
            <div className="d-flex align-items-between justify-content-between">
              <Button
                variant="contained"
                className="p-2 m-3 ms-4 mb-0 button-color"
                startIcon={<AddIcon />}
                onClick={handleInAdd}
              >
                Add Users to Group
              </Button>
              {selectedRows.length > 0 && (
                <Button
                  color="error"
                  variant="contained"
                  style={{ minWidth: "7rem", maxWidth: "10rem" }}
                  className="p-2 m-3 mb-0"
                  onClick={handleDeleteClick(selectedRows, false)}
                >
                  Delete Users
                </Button>
              )}
            </div>

            <Box
              className=""
              sx={{
                minHeight: 520,
                height: "70vh",
                fontWeight: "bold",
                padding: "26px",
                paddingTop: "16px",
                transition: "none !important",
                "& .actions": {
                  color: "text.secondary",
                },
                "& .textPrimary": {
                  color: "text.primary",
                },
              }}
            >
              <DataGrid
                className="text-body-secondary rounded-4 overflow-hidden"
                rows={rows}
                style={{ transition: "none !important" }}
                sx={{
                  transition: "none !important",
                  maxHeight: "68vh",
                  minHeight: "55vh",
                }}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                slotProps={{
                  toolbar: { setRows, setRowModesModel },
                }}
                disableRowSelectionOnClick
                checkboxSelection
                onRowSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  const selectedRows = rows.filter((row) =>
                    selectedIDs.has(row.id)
                  );

                  setSelectedRows(selectedRows);
                }}
                // GridFooter
              />
            </Box>
            {loading ? (
              <CommonLoading />
            ) : (
              modalInOpen && (
                <ThemeProvider
                  theme={
                    $(".lower-div").attr("data-bs-theme") === "light"
                      ? lightTheme
                      : darkTheme
                  }
                >
                  <CssBaseline />
                  <CrudModal
                    modalInOpen={modalInOpen}
                    currentRowId={currentRowId}
                    rows={rows}
                    isUser={isUser}
                    isOrganisation={isOrganisation}
                    isUserGroup={isUserGroup}
                    isUserGroupUser={isUserGroupUser}
                    inAdd={inAdd}
                    inDelete={inDelete}
                    handleClose={handleClose}
                    selectedUsers={selectedUsers}
                    tenantUsers={tenantUsers}
                    selectedUserIds={selectedUserIds}
                    assignUserToUserGroup={assignUserToUserGroup}
                    handleDeleteUserFromUserGroup={
                      handleDeleteUserFromUserGroup
                    }
                    userGroupId={userGroupId}
                  />
                </ThemeProvider>
              )
            )}
          </>
        )}
      </div>
    </motion.div>
  );
};

export default UserGroupUsers;
