"use client";
import React, { useState, useEffect } from 'react';
import $ from "jquery";
import { 
    motion, 
    // AnimatePresence 
} from "framer-motion";
import { ErrorBoundary } from "react-error-boundary";
import TextField from '@mui/material/TextField';
import { baseApiCall } from '../../../API/apiCalls';
import CommonVariables from '../../../variables';
import Notifications from '../../../Notifications/Messages';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { fallbackRender, materialThemes } from '../../../API/commonFunctions';
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
// import Commonloader from '../../../Layout/Commonloader';
import CrudModal from '../Modals/crudModal';
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { useNavigate } from 'react-router-dom/dist';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { useParams } from 'react-router-dom/dist';
import CommonLoading from '../../../Layout/CommonLoading';


/*****************************************IMPORTS FOR MUI GRID**********************************************************/

function EditOrganisationAdmin(props) {
    const [organisationAdmin, setOrganisationAdmin] = useState();
    // const [organisationName, setOrganisationName] = useState(props.editOrganisation.tenant)
    const [inEdit, setInEdit] = useState(false);
    const [modalInOpen, setModalInOpen] = useState(false);
    const [inDelete, setInDelete] = useState(false);
    const [inCreateAdmin, setInCreateAdmin] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [inAdd, setInAdd] = useState(false);
    const [orgName, setOrgName] = useState();
    let navigate = useNavigate();
    let { adminId } = useParams();
      
    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
        },
    });
    const lightTheme = createTheme({
    palette: {
        mode: 'light',
    },
    });

    const routeVariants = {
        initial: {
            transform:"scale(0.3)",
            opacity:0
        },
        final: {
            transform:"scale(1)",
            opacity:1
        }
    };

    /********************** FUNCTION EXECUTABLE ON DELETE MODAL OPEN **********************/
    // const handleDeleteClick = (row) => async() => {
    //     setCurrentRowId(row.id);
    //     setModalInOpen(true);
    //     setInDelete(true);
    // };

    /***************************************************** FUNCTION EXECUTABLE ON GO BACK*********************/
    const handleBack = () => {
        window.history.back();
    };

    /********************** FUNCTION EXECUTABLE ON MODAL CLOSE **********************/
    const handleClose = () => {
        setModalInOpen(false);
        setOrgName("")
        setIsActive(false)
        setInAdd(false)
        setInEdit(false)
        setInDelete(false)
        setInCreateAdmin(false)
    };

    const getAdminById = async() => {
        setLoading(true);
        const result = await baseApiCall(CommonVariables.urls.getUserByIdUrl, {id: adminId});
        if (!result?.isFailure && result?.isFailure === false && result?.data) {
            console.log(result.data);
            setOrganisationAdmin(result?.data);
            setLoading(false);
        }
        setLoading(false);
    }

    const updateOrganisationAdminDetails = async () => {
        if(inEdit){
            setInEdit(false);
            setLoading(true);
            const result = await baseApiCall(CommonVariables.urls.updateUserUrl, {id:adminId,firstName:organisationAdmin?.firstName,lastName:organisationAdmin?.lastName,isActive:organisationAdmin?.isActive});
            if(!result?.isFailure && result?.isFailure === false) {
                Notifications.ShowSuccessMessage(result?.message);
                setLoading(false);
                return;
            }
            Notifications.ShowErrorMessage("Problem Updating Admin, Try Again");
            setOrganisationAdmin(props.editOrganisationAdmin);
            setLoading(false);
        }
        setInEdit(true);
    }

    /******************************************************FUNCTION EXECUTABLE ON CANCEL EDIT MODE*****************************************************/
    const handleCloseEditMode=()=>{
        setInEdit(false);
    }
    useEffect(()=>{
        getAdminById();
    },[])
    return (
        <ErrorBoundary
            fallbackRender={fallbackRender}
            onReset={(details) => {
                // Reset the state of your app so the error doesn't happen again
            }}
        >
            <ThemeProvider theme={materialThemes.lightTheme}>
                <CssBaseline />
                <motion.div 
                    animate="final" 
                    className="for-transition text-center grids p-4 h-100 overflow-auto"
                    sx={{ transition: "none !important" }}
                >
                    <div className='for-transition card w-100 h-100 border border-1 border-color-css rounded-3 shadow p-4'>
                        { loading? <CommonLoading /> :
                            <>
                                <h5 className="text-body-secondary p-2 d-inline fw-bold">Manage Organisation Admin Details</h5>
                                <div className='d-flex flex-column'>                                     
                                    <div className='d-flex gap-1 justify-content-end mb-2'>
                                        <Button 
                                            // color="primary"
                                            variant="contained"
                                            style={{minWidth:"8rem",maxWidth:"10rem"}}
                                            className="pt-1 pb-1 m-1 mb-0 button-color text-light my-auto"
                                            startIcon={inEdit?<DoneAllOutlinedIcon />:<EditIcon />}
                                            onClick={()=>{updateOrganisationAdminDetails()}}
                                        >
                                            {inEdit?"Update":"Edit"}
                                        </Button>
                                        {/* <Button 
                                            color="error"
                                            variant="contained"
                                            style={{minWidth:"1rem",maxWidth:"2rem"}}
                                            className="p-1 text-light"
                                            startIcon={<DeleteIcon style={{marginLeft: "11px"}}/>}
                                            // onClick={()=>{updateOrganisation()}}
                                        >
                                            {/* Delete 
                                        </Button> */}
                                    </div>
                                    <div className='d-flex flex-column flex-wrap gap-3'>                                  
                                        <div className='d-flex col-6'>
                                            <label className='text-body-secondary fs-6 fw-bold p-1 col-4 text-start'>First Name  </label>
                                            <label className='text-body-secondary fs-6 fw-bold p-1 col-md-1'>: </label>
                                            {inEdit ?  
                                                <TextField 
                                                    className='ms-2 p-1 text-body-secondary' 
                                                    autoFocus 
                                                    variant="standard" 
                                                    value={organisationAdmin?.firstName}  
                                                    onChange={(event) => {setOrganisationAdmin({...organisationAdmin,firstName:event.target.value});}} 
                                                    onPointerUpCancel ={console.log("asdfasd")} 
                                                    required /> :
                                                <label className='text-body-secondary fs-6 p-1'>{organisationAdmin?.firstName} </label>
                                            }
                                        </div>
                                        <div className='d-flex col-6'>
                                            <label className='text-body-secondary fs-6 fw-bold p-1 col-4 text-start'>Last Name  </label>
                                            <label className='text-body-secondary fs-6 fw-bold p-1 col-md-1'>: </label>
                                            {inEdit ?  
                                                <TextField 
                                                    className='ms-2 p-1 text-body-secondary' 
                                                    autoFocus 
                                                    variant="standard" 
                                                    value={organisationAdmin?.lastName}  
                                                    onChange={(event) => {setOrganisationAdmin({...organisationAdmin,lastName:event.target.value});}} 
                                                    onPointerUpCancel ={console.log("asdfasd")} 
                                                    required /> :
                                                <label className='text-body-secondary fs-6 p-1'>{organisationAdmin?.lastName} </label>
                                            }
                                        </div>
                                        <div className='d-flex col-6'>
                                            <label className='text-body-secondary fs-6 fw-bold p-1 col-4 text-start'>User Email  </label>
                                            <label className='text-body-secondary fs-6 fw-bold p-1 col-md-1'>: </label>
                                            <label className="text-body-secondary fs-6 p-1">{organisationAdmin?.userEmail}</label>
                                            
                                        </div>  
                                        <div className='d-flex col-6'>
                                            <label className='text-body-secondary fs-6 fw-bold p-1 col-4 text-start'>Is Active  </label>
                                            <label className='text-body-secondary fs-6 fw-bold p-1 col-md-1'>: </label>
                                            <div className="form-check form-switch ps-0 ms-5 ps-3" disabled>
                                                <input  disabled={!inEdit} bled className="is-active form-check-input border-2 border-primary-subtle fs-4" type="checkbox" onChange={() => {setOrganisationAdmin({...organisationAdmin,isActive:!organisationAdmin.isActive});}} checked={organisationAdmin?.isActive} role="switch"  />
                                            </div>
                                        </div>  
                                    </div> 
                                    {inEdit && 
                                    <div>
                                        <Button
                                            color="error"
                                            variant="contained"
                                            style={{minWidth:"8rem",maxWidth:"10rem"}}
                                            className="pt-1 pb-1 m-1 mb-0 text-light my-auto"
                                            onClick={handleCloseEditMode}        
                                            >
                                            Cancel
                                        </Button>
                                    </div>
                                    }
                                    <div className='text-end'>
                                        <Button
                                            title="Go back"
                                            variant="text"
                                            style={{minWidth:"8rem",maxWidth:"10rem"}}
                                            className=" text-body-secondary text-center p-1"
                                            startIcon={<ArrowBackIosNewOutlinedIcon />}
                                            onClick={handleBack}
                                        >
                                            Go back
                                        </Button>
                                    </div>                        
                                </div>
                            </>
                        }
                    </div>   
                </motion.div>
            </ThemeProvider>
        </ErrorBoundary>
    );
}

export default EditOrganisationAdmin;