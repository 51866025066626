// import React from 'react';
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { useEffect } from 'react';
import { baseApiCall } from '../../../API/apiCalls';
import CommonVariables from '../../../variables';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import CrudModal from '../Modals/crudModal';
import { MakeLinkActive } from '../../../API/commonFunctions';
import $ from 'jquery';
import Notifications from '../../../Notifications/Messages';
import CommonSpinner from '../../../Layout/CommonSpinner';
import { useNavigate } from 'react-router-dom/dist';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { AddCircleOutline } from '@mui/icons-material';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

function Sessions(props) {
  let [sessions, setSessions] = useState([]);
  let [loading, setLoading] = useState(false);
  let [sessionName, setSessionName] = useState();
  let [isChatSession, setIsChatSession] = useState(true);
  let [inDelete, setInDelete] = useState(false);
  let [modalInOpen, setModalInOpen] = useState(false);
  let [currentRowId, setCurrentRowId] = useState();
  let [inEdit, setInEdit] = useState(false);
  let [inAdd, setInAdd] = useState(false);
  let [sessionsLoading, setSessionsLoading] = useState(false);
  let [showMore, setShowMore] = useState(false);
  // let [isActive ,setIsActive] = useState();
  // let { sessionId } = useParams();
  let navigate = useNavigate();
  const sessionId = window.location.pathname.split("/").pop();
  const options = [
    'Delete',
    'Edit',
  ];
  
  const ITEM_HEIGHT = 48;
  
  // console.log(props.active);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionsMenuClose = () => {
    setAnchorEl(null);
  };
  useEffect(()=>{
    getSessions();
  },[props.loadSession]);

  const getSessions = async() => {
    setSessionsLoading(true);
    const result = await baseApiCall(CommonVariables.urls.getSessionsUrl,{id:localStorage.getItem("userId")});
    if (!result?.isFailure && result?.isFailure === false && result.data) {
      setSessions(result?.data);
      setSessionsLoading(false);
    };
    handleClose();
    setSessionsLoading(false);
  }

  const handleDeleteClick = (row) => {
    // console.log(row);
    setCurrentRowId(row.id);
    setModalInOpen(true);
    setInDelete(true);
    setAnchorEl(null);
  };

  const handleEditClick = (row) => {
    setCurrentRowId(row.id);
    setSessionName(row.sessionName);
    setModalInOpen(true);
    setInEdit(true);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setModalInOpen(false);
    setSessionName("");
    setInAdd(false);
    setInEdit(false);
    setInDelete(false);
  };

  const handleInAdd = () => {
    setInAdd(true);
    setModalInOpen(true);
  };

  const createNewSession = async(data)=>{
    setLoading(true);
    const result = await baseApiCall(CommonVariables.urls.createSessionUrl,data);
    if (!result?.isFailure && result?.isFailure === false && result.data) {
      setLoading(false);
      handleClose();
      Notifications.ShowSuccessMessage(result.message);
      getSessions();
    };
    // Notifications.ShowWarningMessage("Internal Error");
    handleClose();
    setLoading(false);
  }
  const updateSession = async(session)=>{
    setLoading(true);
    const result = await baseApiCall(CommonVariables.urls.renameSessionUrl,session);
    if (!result?.isFailure && result?.isFailure === false) {
      setLoading(false);
      handleClose();
      Notifications.ShowSuccessMessage(result.message);
      getSessions();
      // console.log(session.id);
      if(sessionId === session.id){
        navigate(`/dashboard/chat/${session.id}`);
      }
    };
    // Notifications.ShowWarningMessage("Internal Error");
    handleClose();
    setLoading(false);
  }
  const deleteSession = async(session)=>{
    setLoading(true);
    //debugger;
    // console.log(session);
    // console.log(sessionId);
    const result = await baseApiCall(CommonVariables.urls.deleteSessionUrl,session);
    if (!result?.isFailure && result?.isFailure === false) {
      setLoading(false);
      handleClose();
      getSessions();
      Notifications.ShowSuccessMessage(result.message);
      setSessions(sessions.filter((sessionData,_) => sessionData.id !== session.id));
      if(sessionId === session.id){
        // getSessions();
        navigate(`/dashboard`);
      }
    };
    // const handleActions = () => {
    //   setActions(true);
    // }
    // Notifications.ShowWarningMessage("Internal Error");
    handleClose();
    setLoading(false);
  }

  const handleShowSessions = () => {
    setShowMore(!showMore);
    $('.sessions-show-more').addClass('d-flex');
  }
  
  return (
    <>
      { sessionsLoading || loading? <CommonSpinner/> :
        <div className="d-flex flex-column ms-2 pt-0 rounded-2 me-2 position-relative" aria-label="">
          <Button className='text-white border-secondary rounded-3 px-3 mb-3 fs-8 text-capitalize button-color w-100' variant='contained' startIcon={<AddCircleOutline/>}  style={{backgroundColor:"rgb(0 0 0 / 59%)", width: 'fit-content'}}  onClick={handleInAdd}>
            New Session
          </Button>
          <div className='session-names d-flex flex-column rounded-2'>
            <p className='ps-2 pt-3 fw-semibold mb-0 pb-1 text-slate-900 position-sticky top-0 z-1'>Recent sessions</p>
            {sessions?.length > 0 ? sessions?.map((name, index)=>(
              <div className={`d-flex justify-content-between  left-nav-button "${props.active===name.id?"p-1 px-2 left-nav-active-link":"p-1 px-2  text-body-secondary single-session-link"}   ${index > 4?"d-none":"d-flex"}`}>
                <Link to = {`/dashboard/chat/${name.id}`} onClick={(event)=>{MakeLinkActive(event, `${name.id}`,"left-nav-button")}} className={`d-flex justify-content-start w-100 align-items-center align-items-center fw-semibold text-decoration-none`} >
                  <ChatBubbleOutlineOutlinedIcon style={{color: '#fff'}} className={`fs-8 pe-2`}/>
                  <span className='flex-grow-1 text-white'>{name.sessionName?.length > 10 ? name.sessionName?.slice(0,10) + '...' : name.sessionName}</span>
                </Link>
                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreVertIcon className="text-body-secondary"/>
                  </IconButton>
                </div>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleActionsMenuClose}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4,
                        width: '15ch',
                        backgroundColor: "rgb(250, 250, 250)",
                        boxShadow: "none",
                      },
                    }}
                  >
                    {options.map((option) => (
                      <MenuItem className="text-black" key={option} selected={option === 'Pyxis'} onClick={(e)=>{option==="Edit"?handleEditClick(name):handleDeleteClick(name)}}>
                        <span  className="material-symbols-outlined p-1 m-0">{option}</span>
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
             //  </div> 
            )): <p className='p-1 px-2 ps-4 no-session'>Create a session to start chatting!</p>}
            {sessions?.length > 5 && <div className='w-100'>
              <Button 
                className='d-flex align-items-start justify-content-start text-capitalize rounded-5 px-2 w-100 text-start text-body-secondary' 
                startIcon={!showMore?<ExpandMoreIcon /> : <ExpandLessIcon />}
                style={{color: '#212529BF', fontSize: '13px'}}
                onClick={handleShowSessions}
              >
                {!showMore?'Show more': 'Show less'}
              </Button>
            </div>}
            {sessions?.length > 0 ? sessions?.map((name, index)=>(
              index>4?
            (<div className={`${(!showMore)?"d-none":"d-block"}`}>
            <div className={`d-flex justify-content-between  left-nav-button "${props.active===name.id?"p-1 px-2 left-nav-active-link":"p-1 px-2  text-body-secondary single-session-link"}`}>
            <Link to = {`/dashboard/chat/${name.id}`} onClick={(event)=>{MakeLinkActive(event, `${name.id}`,"left-nav-button")}} className={`d-flex justify-content-start w-100 align-items-center align-items-center fw-semibold text-decoration-none`} >
            <ChatBubbleOutlineOutlinedIcon style={{color: '#fff'}} className={`fs-8 pe-2`}/>
            <span className='flex-grow-1 text-white'>{name.sessionName?.length > 10 ? name.sessionName.slice(0,10) + '...' : name.sessionName}</span>
                  </Link>
                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreVertIcon className="text-body-secondary"/>
                  </IconButton>
                </div>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleActionsMenuClose}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4,
                        width: '15ch',
                        backgroundColor: "rgb(79, 79, 79)",
                        boxShadow: "none",
                      },
                    }}
                  >
                    {options.map((option) => (
                      <MenuItem className="text-white" key={option} selected={option === 'Pyxis'} onClick={(e)=>{option==="Edit"?handleEditClick(name):handleDeleteClick(name)}}>
                        <span  className="material-symbols-outlined p-1 m-0">{option}</span>
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
            </div>
            ):<></>)):""}
          </div>
        </div>
      } 
      { !loading && modalInOpen && (
        <ThemeProvider theme={$(".lower-div").attr("data-bs-theme") === "light"? lightTheme : darkTheme}>
          <CssBaseline />
          <CrudModal
            modalInOpen={modalInOpen}
            currentRowId={currentRowId}
            inEdit={inEdit}
            isChatSession={isChatSession}
            inAdd={inAdd}
            inDelete={inDelete}
            handleClose={handleClose}
            addSession = {createNewSession}
            updateSession = {updateSession}
            deleteSession = {deleteSession} 
            sessionName={sessionName}
            setSessionName={setSessionName}
          />
        </ThemeProvider>
      )}
    </>
  );
}

export default Sessions;