const handleModalDropdown  =  (allRecords=[],selectedRecords=[])=>{
    const dataToReturn = allRecords.filter((record)=> !selectedRecords.some((item)=>item.id === record.id))
    return dataToReturn
}

const handleModalDropdownForFiles  =  (allRecords=[],selectedRecords=[])=>{
    const dataToReturn = allRecords.filter((record)=> !selectedRecords.some((item)=>item.id === record.id)  && record.userGroupId === '')
    return dataToReturn
}

const handleRowsOnDelete = (allRecords=[],selectedRecords=[])=>{
    const dataToReturn = allRecords.filter((record)=>!selectedRecords.some((item)=>item === record.id))
    return dataToReturn
}

export{ handleModalDropdown ,handleRowsOnDelete, handleModalDropdownForFiles}