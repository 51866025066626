import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import AddIcon from '@mui/icons-material/Add';
import { TreeView, TreeItem } from '@mui/lab';
import { Button, IconButton, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { baseApiCall } from "../../../API/apiCalls";
import CommonVariables from "../../../variables";
import CommonLoading from "../../../Layout/CommonLoading";

const initialTreeData = [
  {
    id: 1,
    name: 'Parent Node 1',
    children: [
      { id: 2, name: 'Child Node 1.1' },
      { id: 3, name: 'Child Node 1.2' },
    ],
  },
  {
    id: 4,
    name: 'Parent Node 2',
    children: [
      { id: 5, name: 'Child Node 2.1' },
      { id: 6, name: 'Child Node 2.2' },
    ],
  },
];

// Recursive TreeNode component
const TreeNode = ({ node, onDelete, onEdit }) => {
  const renderTree = (nodes, parentId = null) => (
    <TreeItem
      key={nodes.id}
      nodeId={String(nodes.id)}
      label={
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            //justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {/* Left section: Node label and delete button */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <span>{nodes.name}</span>

            {/* Delete button beside the node */}
            <IconButton
              onClick={() => onDelete(nodes.id, parentId)}
              size='small'
              color='secondary'
              aria-label='delete'
            >
              <DisabledByDefaultOutlinedIcon sx={{ color: 'red' }} />
            </IconButton>
          </Box>

          {/* Right section: Other buttons like edit */}
          <Box
            sx={{
              position: 'absolute',
              right: 0,
              display: 'flex',
              alignItems: 'top',
              gap: '10px',
            }}
          >
            {nodes.children && (
              <>
                <Button
                  variant='contained'
                  style={{ minWidth: '9rem', maxWidth: '15rem' }}
                  className='button-color items-center'
                  startIcon={<AddOutlinedIcon />}
                  sx={{
                    minWidth: '6rem',
                    padding: '4px 8px',
                    fontSize: '0.75rem',
                    maxHeight: '2rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  
                >
                  Add Files
                </Button>
                <IconButton
                  onClick={() => onEdit(nodes.id)}
                  size='small'
                  color='primary'
                  aria-label='edit'
                >
                  <GroupAddOutlinedIcon
                    sx={{
                      color:
                        'linear-gradient(90deg, rgb(241, 172, 236) 0%, rgb(202, 173, 240) 34%, rgb(171, 220, 237) 62%, rgb(148, 207, 231) 89%)',
                    }}
                  />
                </IconButton>
              </>
            )}
          </Box>
        </Box>
      }
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((child) => renderTree(child, nodes.id))
        : null}
    </TreeItem>
  );

  return renderTree(node);
};

export default function CombinedPage() {
  const [treeData, setTreeData] = useState([]);
  const [loading, setLoading] = useState(false);

  // Function to handle node deletion
  const handleDelete = (id, parentId) => {
    // const deleteNode = (nodes) =>
    //   nodes
    //     .filter((node) => node.id !== id)
    //     .map((node) => ({
    //       ...node,
    //       children: node.children ? deleteNode(node.children) : [],
    //     }));

    // setTreeData(deleteNode(treeData));

    console.log('childid: '+id);
    console.log('parentId: '+parentId);
  };

  // Function to handle editing (this is just a placeholder)
  const handleEdit = (id) => {
    alert(`Edit user with id ${id}`);
  };

  const loadUserGroups = async () => {
    setLoading(true);
    const result = await baseApiCall(
      CommonVariables.urls.getAllUserGroupsUrl,
      {}
    );
    if (!result?.isFailure && result?.isFailure === false && result.data) {
      const finalData = result.data.map((group) => ({
        id: group.id,
        name: group.groupName,
        children: group.documentsDetails.map((doc) => ({
          id: doc.id,
          name: doc.name
        }))
      }));
      
      setTreeData(finalData);
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() =>{
    loadUserGroups();
  }, []);

  return (
    <motion.div
      sx={{ transition: 'none !important' }}
      animate='final'
      className='for-transition p-4 grids text-secondary overflow-auto h-100'
    >
      <div
        className='for-transition w-100 card border h-100 border-1 border-color-css rounded-4 shadow  overflow-hidden'
        style={{}}
      >
        <div className='fs-3 py-3 px-3 mb-2 fw-semibold main-text text-center rounded-bottom-0 card border border-bottom bg-body-tertiary'>
          Secuirty Groups
        </div>
        <Button
          // color="primary"
          variant='contained'
          style={{ minWidth: '9rem', maxWidth: '15rem' }}
          className='p-2 m-3 ms-4 mb-0 button-color'
          startIcon={<AddIcon />}
          //onClick={handleInAdd}
        >
          Add Secuirty Group
        </Button>
        {loading ? (
          <CommonLoading />
        ) : (
          <>
            <Box
              className='mt-5 ml-5'
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                minHeight: 520,
                height: '70vh',
                //margin: '',
                fontWeight: 'bold',
                padding: '26px',
                paddingTop: '16px',
                transition: 'none !important',
                //backgroundColor: '#f9f9f9',
                borderRadius: '8px', // Adding a slight border-radius for smooth corners
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Adding a subtle shadow for depth
                '& .actions': {
                  color: 'text.secondary',
                },
                '& .textPrimary': {
                  color: 'text.primary',
                },
              }}
            >
              <div style={{ marginBottom: '10px' }}>
                <label className='fw-semibold text-lg text-left'>
                  Secuirty Groups
                </label>
              </div>
              <div>
                <TreeView
                  aria-label='rich object'
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{
                    flexGrow: 1,
                    overflowY: 'auto',
                    padding: '8px', // Adding some padding to make it look more structured
                    border: '1px solid #e0e0e0', // Optional border for separation
                    borderRadius: '4px',
                    height: '100%', // Ensure it takes full available space
                  }}
                >
                  {treeData?.map((node) => (
                    <TreeNode
                      key={node.id}
                      node={node}
                      onDelete={handleDelete}
                      onEdit={handleEdit}
                    />
                  ))}
                </TreeView>
              </div>

              <div>{/* Right side PARt */}</div>
            </Box>
          </>
        )}
      </div>
    </motion.div>
  );
}
