import React from 'react';
import RingLoader from "react-spinners/RingLoader";

const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "#c26bf8",
    // zIndex: "999999999999",
};
const CommonLoading = (props) => {
  let color ="#c26bf8";
  return (
    <div className='sweet-loading d-flex align-items-center justify-content-center h-100 w-100 bg-body-secondary rounded-3'> 
    <div className='loader d-flex justify-content-center position-absolute bg-black'>
        <div className="spinner-border text-secondary position-absolute" style={{width: "3rem", height: "3rem",top:"50%"}} role="status">
  <span className="visually-hidden">Loading...</span>
</div>
</div>
</div>
  )
}

export default CommonLoading;